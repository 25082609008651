import { TinyColor } from '@ctrl/tinycolor';
import { CSSProperties, StyleDeclaration, StyleDeclarationMap, StyleSheet } from 'aphrodite';
import * as Colors from './colors';

export type Style = CSSProperties | StyleDeclarationMap;

export const EnhancedStyleSheet = StyleSheet.extend([
	{
		selectorHandler: (selector, baseSelector, generateSubtreeStyles) => {
			if (!!selector && selector.length > 2 && selector.substring(0, 2) === '&.') {
				const usesBaseAgain = selector.charAt(2) === '&';
				const sel = `${usesBaseAgain ? baseSelector : ''}${selector.substring(usesBaseAgain ? 3 : 2)}`;
				const result = generateSubtreeStyles(`${usesBaseAgain ? baseSelector : ''}${sel}`);
				return result;
			}
			return null;
		},
	},
]);

export const EnhancedCss = EnhancedStyleSheet.css;

export const BreakPoints = {
	forMaxHeight: (maxHeight: number) => `@media only screen (max-height: ${maxHeight}px)`,
	forMaxWidth: (maxWidth: number) => `@media only screen and (max-width: ${maxWidth}px)`,
	forMinHeight: (minHeight: number) => `@media only screen and (min-height: ${minHeight}px)`,
	forMinWidth: (minWidth: number) => `@media only screen and (min-width: ${minWidth}px)`,
	phoneCompactHeight: '@media only screen and (max-Height: 420px)',
	phoneCompactWidth: '@media only screen and (max-width: 420px)',
	tabletCompactHeight: '@media only screen and (max-height: 768px)',
	tabletCompactWidth: '@media only screen and (max-width: 768px)',
	tabletHeight: '@media only screen and (max-height: 1024px)',
	tabletWidth: '@media only screen and (max-width: 1024px)',
};

export const Layouts = {
	horizontalStack: (itemSpacing: number | string = 10): CSSProperties => {
		return {
			alignItems: 'center',
			display: 'flex',
			...CssSelectors.allChildren({
				':not(:first-child)': {
					marginLeft: itemSpacing,
				},
			}),
		};
	},
	verticalStack: (itemSpacing: number | string = 10): CSSProperties => {
		return {
			...CssSelectors.allChildren({
				':not(:first-child)': {
					marginTop: itemSpacing,
				},
			}),
		};
	},
};

export const BrowserMediaQueries = {
	IE11: '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)',
};

export const Fonts = {
	lexend: 'Lexend, OpenSans, sans-serif',
	sora: 'Sora, OpenSans, sans-serif',
};

// TODO: Update with new font families
export const FontFamilies = {
	/** FontWeight: 700 */
	bold: 'OpenSans-Bold, OpenSans, sans-serif',
	/** FontWeight: 600 */
	// boldItalic: 'OpenSans-BoldItalic, OpenSans, sans-serif',
	conqueror: 'Conqueror, OpenSans, sans-serif',
	/** FontWeight: 400 */
	// italic: 'OpenSans-Italic, OpenSans, sans-serif',
	/** FontWeight: 300 */
	light: 'OpenSans-Light, OpenSans, sans-serif',
	/** FontWeight: 400 */
	regular: 'OpenSans-Regular, OpenSans, sans-serif',
	/** FontWeight: 600 */
	semiBold: 'OpenSans-SemiBold, OpenSans, sans-serif',
};

export const ctaButtonProps = (baseColor: string, hoverColor?: string, activeColor?: string): CSSProperties => {
	const tinyColor = !hoverColor || !activeColor ? new TinyColor(baseColor) : null;
	return {
		':active': {
			background: activeColor || tinyColor?.darken(4)?.toHexString(),
		},
		':hover': {
			background: hoverColor || tinyColor?.lighten(2)?.toHexString(),
		},
		transition: 'all .5s',
		alignItems: 'center',
		background: baseColor,
		border: 'none',
		borderRadius: 8,
		boxShadow: '0 1px 2px 0 rgba(140,140,140,0.50)',
		color: Colors.white,
		display: 'flex',
		fontSize: 14,
		fontFamily: Fonts.lexend,
		justifyContent: 'center',
		minWidth: 90,
		padding: '10px 16px',
		textAlign: 'center',
	};
};

export const ctaButtonReverseProps = (
	backgroundColor: string,
	borderColor: string,
	hoverColor?: string,
	activeColor?: string
): CSSProperties => {
	return {
		...ctaButtonProps(backgroundColor, hoverColor, activeColor),
		background: 'transparent',
		border: `1px solid ${borderColor}`,
		boxShadow: '0 1px 2px 0 #f9f9f9',
		color: borderColor,
	};
};

export const ctaButtonBase = ctaButtonProps(Colors.brandPrimary, Colors.brandPrimaryHover, Colors.brandPrimaryActive);

export const textFieldPadding = 10;

const sanitizeFilter = (filter?: string) => {
	let mFilter = filter ? filter : '*';
	const firstChar = mFilter.charAt(0);
	if (firstChar === ':') {
		// prefix with wildcard
		mFilter = `*${mFilter}`;
	}
	return mFilter;
};

export const CssSelectors = {
	allChildren: (style: CSSProperties, filter?: string) => {
		const result: StyleDeclaration = {};
		if (!style) {
			return result;
		}

		const mFilter = sanitizeFilter(filter);
		return CssSelectors.allDescendants(style, `> ${mFilter}`);
	},
	allDescendants: (style: CSSProperties, filter?: string) => {
		const result: StyleDeclaration = {};
		if (!style) {
			return result;
		}

		const mFilter = sanitizeFilter(filter);
		(result as any)[`:nth-child(1n) ${mFilter}`] = style;
		return result;
	},
};

export const requiredStyle: CSSProperties = {
	color: Colors.urgentDate,
};

export const cardStyleBorderRadius = 8;

export const cardStyle: CSSProperties = {
	background: '#fff',
	border: '1px solid #F2F2F2',
	borderRadius: cardStyleBorderRadius,
	boxShadow: '0 0 6px 0 rgba(139,139,139,0.10)',
	overflow: 'hidden',
};

export const truncateTextStyle: CSSProperties = {
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
};

export const absoluteCenterStyle: CSSProperties = {
	left: '50%',
	position: 'absolute',
	top: '50%',
	transform: 'translate(-50%, -50%)',
};

export const DefaultBorder = `1px solid ${Colors.borderColor}`;

const mentionButton: Style = {
	...truncateTextStyle,
	background: 'transparent',
	backgroundColor: Colors.mention,
	padding: '0 2px',
};

export const CtaButtons = {
	ctaButton: {
		...ctaButtonBase,
	},
	ctaButtonDestructive: {
		...ctaButtonBase,
		':active': {
			border: `1px solid ${Colors.destructiveActive}`,
			color: Colors.destructiveHover,
		},
		':hover': {
			border: `1px solid ${Colors.destructiveHover}`,
			color: Colors.destructiveHover,
		},
		transition: 'all .5s',
		background: '#fff',
		border: `1px solid ${Colors.destructive}`,
		boxShadow: '0 1px 2px 0 #f9f9f9',
		color: Colors.destructive,
	},
	ctaButtonDestructiveSmall: {
		...ctaButtonBase,
		':active': {
			background: Colors.destructiveHoverLight,
			border: `1px solid ${Colors.destructiveActive}`,
			color: Colors.destructiveHover,
		},
		':hover': {
			background: Colors.destructiveHoverLight,
			border: `1px solid ${Colors.destructiveHover}`,
			color: Colors.destructiveHover,
		},
		transition: 'all .5s',
		background: '#fff',
		border: `1px solid ${Colors.destructive}`,
		boxShadow: '0 1px 2px 0 #f9f9f9',
		color: Colors.destructive,
		minWidth: 60,
		padding: '4px 14px',
	},
	ctaButtonDestructiveSolid: {
		...ctaButtonProps(Colors.destructive, Colors.destructiveHover, Colors.destructiveActive),
		boxShadow: '0 1px 2px 0 #f9f9f9',
		color: '#fff',
	},
	ctaButtonFullWidth: {
		width: '100%',
	},
	ctaButtonReverse: {
		...ctaButtonBase,
		':active': {
			background: '#E6ECF3',
		},
		':hover': {
			background: '#EAF7FA',
			border: `1px solid ${Colors.brandPrimaryHover}`,
		},
		transition: 'all .5s',
		background: '#fff',
		border: `1px solid ${Colors.brandPrimary}`,
		boxShadow: '0 1px 2px 0 #f9f9f9',
		color: Colors.brandPrimary,
	},
	ctaButtonReverseSmall: {
		...ctaButtonBase,
		':active': {
			background: '#E6ECF3',
		},
		':hover': {
			background: '#EAF7FA',
			border: `1px solid ${Colors.brandPrimaryHover}`,
		},
		transition: 'all .5s',
		background: '#fff',
		border: `1px solid ${Colors.brandPrimary}`,
		boxShadow: '0 1px 2px 0 #f9f9f9',
		color: Colors.brandPrimary,
		minWidth: 60,
		padding: '4px 14px',
	},
	ctaButtonSecondary: {
		...ctaButtonProps(Colors.brandSecondary, Colors.brandSecondaryHover, Colors.brandSecondaryActive),
	},
	ctaButtonSmall: {
		...ctaButtonBase,
		minWidth: 60,
		padding: '4px 14px',
	},
	ctaSuccessButton: {
		...ctaButtonProps(Colors.success),
	},
} as const;

const textFieldBase: Style = {
	'-webkit-appearance': 'none',
	background: '#F9F9F9',
	border: `1px solid ${Colors.inputBorderColor}`,
	borderRadius: 6,
	boxSizing: 'border-box',
	color: Colors.titles,
	fontSize: 14,
	outline: 'none',
	padding: textFieldPadding,
	...CssSelectors.allDescendants(
		{
			'::placeholder': {
				color: Colors.navigation,
			},
			':focus': {
				outline: 'none',
			},
			background: 'transparent',
			border: 'none',
			color: Colors.titles,
			flexGrow: 1,
			fontSize: '14px',
			padding: 0,
		},
		'input'
	),
};

const outlineButtonContainer: Style = {
	alignItems: 'center',
	backgroundColor: 'white',
	borderColor: Colors.brandPrimary,
	borderRadius: 8,
	borderStyle: 'solid',
	borderWidth: 1,
	boxShadow: '0px 1px 2px rgba(140, 140, 140, 0.2)',
	display: 'flex',
	fontSize: 14,
	fontWeight: 600,
	justifyContent: 'center',
	minHeight: 28,
	minWidth: 70,
	padding: '0px 6px',
};

const outlineButton: Style = {
	alignItems: 'center',
	color: Colors.brandPrimary,
	display: 'flex',
	flex: 1,
	fontSize: 14,
	fontWeight: 600,
	height: '100%',
	justifyContent: 'center',
};

const backgroundColorAnimation = {
	'0%': {
		backgroundColor: '#00AAE8',
	},
	'100%': {
		backgroundColor: '#D7EBF7',
	},
};

const shimmerAnimation = {
	'0%': {
		transform: 'translateX(-100%)',
	},
	'100%': {
		transform: 'translateX(200%)',
	},
};

const reverseBackgroundColorAnimation = {
	'0%': {
		backgroundColor: '#D7EBF7',
	},
	'100%': {
		backgroundColor: '#00AAE8',
	},
};

export const baseStyles = {
	absolute: {
		position: 'absolute',
	},
	absoluteCenter: {
		left: '50%',
		position: 'absolute',
		top: '50%',
		transform: 'translate(-50%, -50%)',
	},
	aspectSquare: {
		aspectRatio: '1 / 1',
	},
	basis80: {
		flexBasis: '20rem' /* 320px */,
	},
	bgAliceBlue: {
		backgroundColor: Colors.mention,
	},
	bgBackground: {
		backgroundColor: Colors.background,
	},
	bgBlack: {
		backgroundColor: '#000',
	},
	bgBloodOrange: {
		backgroundColor: Colors.destructive,
	},
	bgBrandPrimary: {
		backgroundColor: Colors.brandPrimary,
	},
	bgBrilliantAzure: {
		backgroundColor: Colors.brandSecondary,
	},
	bgGhostWhite: {
		color: Colors.background,
	},
	bgGreen: {
		backgroundColor: Colors.success,
	},
	bgGray50: {
		backgroundColor: '#f9fafb',
	},
	bgGray200: {
		backgroundColor: '#e5e7eb',
	},
	bgGray400: {
		backgroundColor: '#9ca3af',
	},
	bgHoneyDew: {
		backgroundColor: '#f1fce7',
	},
	bgOrange: {
		backgroundColor: '#EB8638',
	},
	bgOrangeTransparant: {
		backgroundColor: '#FFF4EB',
	},
	bgOverlayBackground: {
		backgroundColor: 'rgba(255, 255, 255, 0.8)',
	},
	bgPink: {
		backgroundColor: 'rgb(224, 114, 190)',
	},
	bgBrightBlue: {
		backgroundColor: Colors.brandPrimaryHover,
	},
	bgTagBackground: {
		backgroundColor: '#d7ebf7',
	},
	bgVividCerulian: {
		backgroundColor: Colors.alternateTitleColor,
	},
	bgWhite: {
		background: '#fff',
	},
	bgTransparent: {
		background: 'transparent',
	},
	block: {
		display: 'block',
	},
	border: {
		borderWidth: 1,
	},
	border0: {
		borderWidth: 0,
	},
	borderB: {
		borderBottomWidth: 1,
	},
	borderCollapse: {
		borderCollapse: 'collapse',
	},
	borderAlternateTitle: {
		borderColor: Colors.alternateTitleColor,
	},
	borderAntiFlashWhite: {
		borderColor: Colors.antiFlashWhite,
	},
	borderBlue100: {
		borderColor: '#dbeafe',
	},
	borderBrilliantAzure: {
		borderColor: Colors.brandSecondary,
	},
	borderGray100: {
		borderColor: '#f3f4f6',
	},
	borderGray200: {
		borderColor: '#e5e7eb',
	},
	borderGray300: {
		borderColor: '#d1d5db',
	},
	borderMentionDark: {
		borderColor: Colors.mentionDark,
	},
	borderNameCircles: {
		borderColor: Colors.nameCircles,
	},
	borderYellowGreen: {
		borderColor: '#89cf47',
	},
	borderDashed: {
		borderStyle: 'dashed',
	},
	borderSolid: {
		borderStyle: 'solid',
	},
	borderSpacing0: {
		borderSpacing: '0px 0px',
	},
	borderT: {
		borderTopWidth: 1,
	},
	borderX: {
		borderLeftWidth: 1,
		borderRightWidth: 1,
	},
	borderY: {
		borderTopWidth: 1,
		borderBottomWidth: 1,
	},
	bottom0: {
		bottom: 0,
	},
	bottom2: {
		bottom: '0.5rem',
	},
	boxBorder: {
		boxSizing: 'border-box',
	},
	brandLink: {
		':hover': {
			color: Colors.brandPrimaryHover,
			textDecoration: 'underline',
		},
		alignItems: 'center',
		color: Colors.brandPrimary,
		cursor: 'pointer',
		display: 'inline-flex',
		fontSize: 14,
		textDecoration: 'none',
	},
	brandSecondaryLink: {
		':hover': {
			color: Colors.brandSecondaryHover,
			textDecoration: 'underline',
		},
		color: Colors.brandSecondary,
		textDecoration: 'none',
	},
	breadcrumbTitle: {
		color: Colors.brandPrimary,
		margin: 0,
	},
	breakWord: {
		overflowWrap: 'break-word',
		wordBreak: 'break-word',
		wordWrap: 'break-word',
	},
	breakAll: {
		wordBreak: 'break-all',
	},
	capitalize: {
		textTransform: 'capitalize',
	},
	cardWelcome: {
		alignItems: 'center',
		color: Colors.titles,
		display: 'flex',
		fontSize: '18px',
		...CssSelectors.allDescendants(
			{
				height: 35,
				marginRight: 10,
				width: 35,
			},
			'.user-avatar'
		),
	},
	cursorPointer: {
		cursor: 'pointer',
	},
	cursorDefault: {
		cursor: 'default',
	},
	defaultNotFirstChildLeftSpacing: {
		':not(:first-child)': {
			marginLeft: 10,
		},
	},
	disabled: {
		opacity: 0.5,
	},
	disableInteraction: {
		pointerEvents: 'none',
	},
	dropdownMenu: {
		background: '#fff',
		border: DefaultBorder,
		boxShadow: '0px 2px 4px 0px #e3e7e9',
		boxSizing: 'border-box',
		color: Colors.titles,
		fontSize: 14,
		padding: 5,
	},
	dropdownMenuItem: {
		':hover': {
			background: '#e3e7e9',
			cursor: 'pointer',
		},
		boxSizing: 'border-box',
		padding: '16px 20px',
	},
	dropdownMenuItemHighlighted: {
		background: '#e8f3f9',
	},
	dropShadowMd: {
		filter: 'drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))',
	},
	duration100: {
		transitionDuration: '100ms',
	},
	fixed: {
		position: 'fixed',
	},
	flex: {
		display: 'flex',
	},
	flex1: {
		flex: '1 1 0%',
	},
	flexBetween: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
	},
	flexCenter: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
	},
	flexCol: {
		flexDirection: 'column',
	},
	flexGrow: {
		flexGrow: 1,
	},
	flexRow: {
		flexDirection: 'row',
	},
	flexShrink0: {
		flexShrink: 0,
	},
	flexWrap: {
		flexWrap: 'wrap',
	},
	fontLexend: {
		fontFamily: 'Lexend, OpenSans, sans-serif',
	},
	fontLight: {
		fontFamily: FontFamilies.light,
	},
	fontBold: {
		fontWeight: 600,
	},
	boldOnHighDpi: {
		'@media (min-resolution: 2dppx)': {
			fontWeight: 600,
		},
	},
	fontNormal: {
		fontWeight: 400,
	},
	fontSora: {
		fontFamily: 'Sora, OpenSans, sans-serif',
	},
	fullWidth: {
		width: '100% !important',
	},
	gap1: {
		gap: '0.25rem',
	},
	gap2: {
		gap: '0.5rem',
	},
	gap3: {
		gap: '0.75rem',
	},
	gap4: {
		gap: '1rem',
	},
	gap5: {
		gap: '1.25rem',
	},
	gap8: {
		gap: '2rem',
	},
	ghostBackgroundAnimation: {
		animationDirection: 'alternate',
		animationDuration: '1000ms',
		animationFillMode: 'forwards',
		animationIterationCount: 'infinite',
		animationName: [backgroundColorAnimation] as any[],
		backgroundColor: 'rgba(255,255,255, 0.7)',
	},
	ghostReverseBackgroundAnimation: {
		animationDirection: 'alternate',
		animationDuration: '1000ms',
		animationFillMode: 'forwards',
		animationIterationCount: 'infinite',
		animationName: [reverseBackgroundColorAnimation] as any[],
		backgroundColor: 'rgba(0,0,0,0.5)',
	},
	grid: {
		display: 'grid',
	},
	grow0: {
		flexGrow: 0,
	},
	hAuto: {
		height: 'auto',
	},
	h2: {
		height: '0.5rem',
	},
	h4: {
		height: '1rem',
	},
	h5: {
		height: '1.25rem',
	},
	h6: {
		height: '1.5rem',
	},
	h7: {
		height: '1.75rem',
	},
	h8: {
		height: '2rem',
	},
	h10: {
		height: '2.5rem',
	},
	h12: {
		height: '3rem',
	},
	h15: {
		height: '3.75rem',
	},
	h16: {
		height: '4rem',
	},
	h24: {
		height: '6rem',
	},
	h28: {
		height: '7rem',
	},
	h36: {
		height: '9rem',
	},
	h80: {
		height: '20rem',
	},
	hFull: {
		height: '100%',
	},
	hidden: {
		display: 'none',
	},
	horizontalStack: {
		...Layouts.horizontalStack(),
	},
	inlineBlock: {
		display: 'inline-block',
	},
	inlineFlex: {
		display: 'inline-flex',
	},
	italic: {
		fontStyle: 'italic',
	},
	itemsBaseline: {
		alignItems: 'baseline',
	},
	itemsCenter: {
		alignItems: 'center',
	},
	itemsEnd: {
		alignItems: 'flex-end',
	},
	itemsStart: {
		alignItems: 'flex-start',
	},
	italicText: {
		fontStyle: 'italic',
	},
	justifyBetween: {
		justifyContent: 'space-between',
	},
	justifyEvenly: {
		justifyContent: 'space-evenly',
	},
	justifyCenter: {
		justifyContent: 'center',
	},
	justifyEnd: {
		justifyContent: 'flex-end',
	},
	justifyStart: {
		justifyContent: 'flex-start',
	},
	left0: {
		left: 0,
	},
	'left-1-2': {
		left: '50%',
	},
	link: {
		':hover': {
			color: '#4A4A4A',
			textDecoration: 'underline',
		},
		color: Colors.darkGrayFontColor,
		fontSize: 14,
	},
	linkDestructive: {
		':hover': {
			color: Colors.destructiveHover,
			textDecoration: 'underline',
		},
		color: Colors.destructive,
		fontSize: 14,
	},
	listDecimal: {
		listStyleType: 'decimal',
	},
	listNone: {
		listStyleType: 'none',
	},
	maxH40: {
		maxHeight: '10rem',
	},
	maxHFull: {
		maxHeight: '100%',
	},
	maxW52: {
		maxWidth: '13rem', // 208px
	},
	maxW80: {
		maxWidth: '20rem', // 320px
	},
	maxWFit: {
		maxWidth: 'fit-content',
	},
	maxWFull: {
		maxWidth: '100%',
	},
	maxWXs: {
		maxWidth: '20rem',
	},
	maxWSm: {
		maxWidth: '24rem',
	},
	maxWMd: {
		maxWidth: '28rem',
	},
	maxWXl: {
		maxWidth: '36rem',
	},
	maxW3Xl: {
		maxWidth: '48rem',
	},
	minH24: {
		minHeight: '6rem',
	},
	minHFull: {
		minHeight: '100%',
	},
	minH0: {
		minHeight: 0,
	},
	mAuto: {
		margin: 'auto',
	},
	'-ml2': {
		marginLeft: '-0.5rem',
	},
	'-ml4': {
		marginLeft: '-1rem',
	},
	'-ml6': {
		marginLeft: '-1.5rem',
	},
	'-mr5': {
		marginRight: '-1.25rem',
	},
	'-mx10': {
		marginLeft: '-2.5rem',
		marginRight: '-2.5rem',
	},
	m0: {
		margin: 0,
	},
	mb0: {
		marginBottom: 0,
	},
	mb1: {
		marginBottom: '0.25rem',
	},
	mb2: {
		marginBottom: '0.5rem',
	},
	mb3: {
		marginBottom: '0.75rem',
	},
	mb4: {
		marginBottom: '1rem',
	},
	mb5: {
		marginBottom: '1.25rem',
	},
	mb6: {
		marginBottom: '1.5rem',
	},
	mb8: {
		marginBottom: '2rem',
	},
	mb9: {
		marginBottom: '2.25rem',
	},
	mb12: {
		marginBottom: '3rem',
	},
	ml1: {
		marginLeft: '0.25rem',
	},
	ml2: {
		marginLeft: '0.5rem',
	},
	ml3: {
		marginLeft: '0.75rem',
	},
	ml4: {
		marginLeft: '1rem',
	},
	ml5: {
		marginLeft: '1.25rem',
	},
	ml6: {
		marginLeft: '1.5rem',
	},
	ml7: {
		marginLeft: '1.75rem',
	},
	ml8: {
		marginLeft: '2rem',
	},
	ml9: {
		marginLeft: '2.25rem',
	},
	ml48: {
		marginLeft: '12rem',
	},
	mr1: {
		marginRight: '0.25rem',
	},
	mr2: {
		marginRight: '0.5rem',
	},
	mr3: {
		marginRight: '0.75rem',
	},
	mr4: {
		marginRight: '1rem',
	},
	mr5: {
		marginRight: '1.25rem',
	},
	mentionButton,
	mentionButtonDark: {
		...mentionButton,
		backgroundColor: Colors.mentionDark,
	},
	minorActionLink: {
		':hover': {
			color: '#4A4A4A',
		},
		color: Colors.brandPrimaryActive,
		fontSize: 12,
	},
	mt1: {
		marginTop: '0.25rem',
	},
	mt2: {
		marginTop: '0.5rem',
	},
	mt3: {
		marginTop: '0.75rem',
	},
	mt4: {
		marginTop: '1rem',
	},
	mt5: {
		marginTop: '1.25rem',
	},
	mt8: {
		marginTop: '2rem',
	},
	mt24: {
		marginTop: '6rem',
	},
	my0: {
		marginTop: 0,
		marginBottom: 0,
	},
	mx0: {
		marginLeft: 0,
		marginRight: 0,
	},
	mx7: {
		marginLeft: '1.75rem',
		marginRight: '1.75rem',
	},
	mxAuto: {
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	noUnderline: {
		textDecoration: 'none',
	},
	objectCover: {
		objectFit: 'cover',
	},
	opacity0: {
		opacity: 0,
	},
	opacity40: {
		opacity: 0.4,
	},
	opacity100: {
		opacity: 1,
	},
	outlineButton,
	outlineButtonContainer,
	outlineButtonContainerDestructive: {
		...outlineButtonContainer,
		borderColor: Colors.destructive,
		color: Colors.destructive,
	},
	outlineButtonDestructive: {
		...outlineButton,
		color: Colors.destructive,
	},
	outlineNone: {
		outline: 'none',
	},
	overflowAuto: {
		overflow: 'auto',
	},
	overflowHidden: {
		overflow: 'hidden',
	},
	overflowVisible: {
		overflow: 'visible',
	},
	overflowXHidden: {
		overflowX: 'hidden',
	},
	overflowYAuto: {
		overflowY: 'auto',
	},
	overflowYVisible: {
		overflowY: 'visible',
	},
	p0: {
		padding: 0,
	},
	p1: {
		padding: '0.25rem',
	},
	p2: {
		padding: '0.5rem',
	},
	p3: {
		padding: '0.75rem',
	},
	p4: {
		padding: '1rem',
	},
	p5: {
		padding: '1.25rem',
	},
	p6: {
		padding: '1.5rem',
	},
	p7: {
		padding: '1.75rem',
	},
	pb1: {
		paddingBottom: '0.25rem',
	},
	pb2: {
		paddingBottom: '0.5rem',
	},
	pb3: {
		paddingBottom: '0.75rem',
	},
	pb4: {
		paddingBottom: '1rem',
	},
	pb5: {
		paddingBottom: '1.25rem',
	},
	pb7: {
		paddingBottom: '1.75rem',
	},
	pb8: {
		paddingBottom: '2rem',
	},
	pb9: {
		paddingBottom: '2.25rem',
	},
	pl1: {
		paddingLeft: '0.25rem',
	},
	pl2: {
		paddingLeft: '0.5rem',
	},
	pl3: {
		paddingLeft: '0.75rem',
	},
	pl4: {
		paddingLeft: '1rem',
	},
	pl5: {
		paddingLeft: '1.25rem',
	},
	pl6: {
		paddingLeft: '1.5rem',
	},
	pl12: {
		paddingLeft: '3rem',
	},
	pr1: {
		paddingRight: '0.25rem',
	},
	pr2: {
		paddingRight: '0.5rem',
	},
	pr4: {
		paddingRight: '1rem',
	},
	pr5: {
		paddingRight: '1.25rem',
	},
	pr6: {
		paddingRight: '1.5rem',
	},
	pr7: {
		paddingRight: '1.75rem',
	},
	pt0: {
		paddingTop: 0,
	},
	pt1: {
		paddingTop: '0.25rem',
	},
	pt2: {
		paddingTop: '0.5rem',
	},
	pt3: {
		paddingTop: '0.75rem',
	},
	pt4: {
		paddingTop: '1rem',
	},
	pt5: {
		paddingTop: '1.25rem',
	},
	pt6: {
		paddingTop: '1.5rem',
	},
	pt8: {
		paddingTop: '2rem',
	},
	pt16: {
		paddingTop: '4rem',
	},
	px0: {
		paddingLeft: 0,
		paddingRight: 0,
	},
	px1: {
		paddingLeft: '0.25rem',
		paddingRight: '0.25rem',
	},
	px2: {
		paddingLeft: '0.5rem',
		paddingRight: '0.5rem',
	},
	px3: {
		paddingLeft: '0.75rem',
		paddingRight: '0.75rem',
	},
	px4: {
		paddingLeft: '1rem',
		paddingRight: '1rem',
	},
	px5: {
		paddingLeft: '1.25rem',
		paddingRight: '1.25rem',
	},
	px6: {
		paddingLeft: '1.5rem',
		paddingRight: '1.5rem',
	},
	px7: {
		paddingLeft: '1.75rem',
		paddingRight: '1.75rem',
	},
	px8: {
		paddingLeft: '2rem',
		paddingRight: '2rem',
	},
	px11: {
		paddingLeft: '2.75rem',
		paddingRight: '2.75rem',
	},
	px12: {
		paddingLeft: '3rem',
		paddingRight: '3rem',
	},
	py4: {
		paddingBottom: '1rem',
		paddingTop: '1rem',
	},
	py0: {
		paddingTop: 0,
		paddingBottom: 0,
	},
	py1: {
		paddingBottom: '0.25rem',
		paddingTop: '0.25rem',
	},
	py2: {
		paddingBottom: '0.5rem',
		paddingTop: '0.5rem',
	},
	py3: {
		paddingBottom: '0.75rem',
		paddingTop: '0.75rem',
	},
	py5: {
		paddingBottom: '1.25rem',
		paddingTop: '1.25rem',
	},
	py6: {
		paddingBottom: '1.5rem',
		paddingTop: '1.5rem',
	},
	py7: {
		paddingBottom: '1.85rem',
		paddingTop: '1.85rem',
	},
	py8: {
		paddingBottom: '2rem',
		paddingTop: '2rem',
	},
	pointerEventsAuto: {
		pointerEvents: 'auto',
	},
	pointerEventsNone: {
		pointerEvents: 'none',
	},
	relative: {
		position: 'relative',
	},
	removeChildInputSpinButton: {
		...CssSelectors.allChildren(
			{
				'-webkit-appearance': 'none',
				margin: 0,
			},
			'input[type="number"]::-webkit-outer-spin-button'
		),
		...CssSelectors.allChildren(
			{
				'-webkit-appearance': 'none',
				margin: 0,
			},
			'input[type="number"]::-webkit-inner-spin-button'
		),
		...CssSelectors.allChildren(
			{
				'-moz-appearance': 'textfield',
			},
			'input[type="number"]'
		),
	},
	emailSubjectBadge: {
		background: '#FEF9E0',
		borderRadius: 8,
		padding: 4,
		display: 'inline-block',
	},
	required: {
		color: Colors.urgentDate,
	},
	resizeNone: {
		resize: 'none',
	},
	'-right-1': {
		right: '-0.25rem',
	},
	right0: {
		right: 0,
	},
	right1: {
		right: '0.25rem',
	},
	right2: {
		right: '0.5rem',
	},
	right4: {
		right: '1rem',
	},
	rotate90: {
		'--lev-rotate': '90deg',
	},
	rounded: {
		borderRadius: '0.25rem',
	},
	roundedFull: {
		borderRadius: '9999px',
	},
	roundedLg: {
		borderRadius: '0.5rem',
	},
	rounded2xl: {
		borderRadius: '1rem',
	},
	roundedBNone: {
		borderBottomLeftRadius: 0,
		borderBottomRightRadius: 0,
	},
	roundedTLg: {
		borderTopLeftRadius: '0.5rem',
		borderTopRightRadius: '0.5rem',
	},
	roundedTNone: {
		borderTopLeftRadius: 0,
		borderTopRightRadius: 0,
	},
	scale75: {
		'--lev-scale-x': '0.75',
		'--lev-scale-y': '0.75',
	},
	shadow: {
		boxShadow: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
	},
	shimmer: {
		':after': {
			animationDirection: 'normal',
			animationDuration: '1000ms',
			animationFillMode: 'forwards',
			animationIterationCount: 'infinite',
			animationName: [shimmerAnimation] as any[],
			backgroundImage:
				'linear-gradient(to right,rgba(255,255,255, 0) 33.3%, rgba(255,255,255, 0.8), rgba(255,255,255, 0) 66.6%)',
			borderRadius: 12,
			bottom: 0,
			content: "''",
			display: 'block',
			height: '100%',
			left: '-100%',
			position: 'absolute',
			right: '-100%',
			top: 0,
			width: '100%',
		},
		borderRadius: 12,
		bottom: 0,
		left: 0,
		overflowX: 'hidden',
		position: 'absolute',
		right: 0,
		top: 0,
	},
	shrink0: {
		flexShrink: 0,
	},
	tableColumnHeader: {
		color: Colors.header,
		fontSize: 11,
		letterSpacing: 1.1,
		textTransform: 'uppercase',
	},
	textCenter: {
		textAlign: 'center',
	},
	textField: textFieldBase,
	textFieldStandard: {
		...textFieldBase,
		minWidth: 300,
	},
	textLeft: {
		textAlign: 'left',
	},
	textRight: {
		textAlign: 'right',
	},
	textAlternateTitle: {
		color: Colors.alternateTitleColor,
	},
	textBlack: {
		color: '#000',
	},
	textDestructive: {
		color: '#D0021B',
	},
	textBrandPrimary: {
		color: Colors.brandPrimary,
	},
	textBrandSecondary: {
		color: Colors.brandSecondary,
	},
	textBrilliantAzure: {
		color: Colors.brandSecondary,
	},
	textTitles: {
		color: Colors.titles,
	},
	textGray400: {
		color: '#9ca3af',
	},
	textGray600: {
		color: '#374151',
	},
	textSuccess: {
		color: Colors.success,
	},
	textOrange: {
		color: '#EB8638',
	},
	textLabel: {
		color: Colors.label,
	},
	textNavigation: {
		color: Colors.navigation,
	},
	textHeader: {
		color: '#858585',
	},
	textDarkGrayFontColor: {
		color: Colors.darkGrayFontColor, // #767676
	},
	textVividCerulian: {
		color: Colors.alternateTitleColor,
	},
	textBrandPrimaryHover: {
		color: Colors.brandPrimaryHover,
	},
	textWarningDark: {
		color: Colors.warningDark,
	},
	textWhite: {
		color: '#fff',
	},
	textBase: {
		fontSize: '1rem',
		lineHeight: '1.5rem',
	},
	textLg: {
		fontSize: '1.125rem',
		lineHeight: '1.75rem',
	},
	textSm: {
		fontSize: '0.875rem',
		lineHeight: '1.25rem',
	},
	textXs: {
		fontSize: '0.75rem',
		lineHeight: '1rem',
	},
	textXl: {
		fontSize: '1.25rem',
		lineHeight: '1.75rem',
	},
	top0: {
		top: 0,
	},
	top4: {
		top: '1rem',
	},
	'top-1-2': {
		top: '50%',
	},
	transform: {
		'--lev-translate-x': '0',
		'--lev-translate-y': '0',
		'--lev-rotate': '0',
		'--lev-skew-x': '0',
		'--lev-skew-y': '0',
		'--lev-scale-x': '1',
		'--lev-scale-y': '1',
		transform:
			'translateX(var(--lev-translate-x)) translateY(var(--lev-translate-y)) rotate(var(--lev-rotate)) skewX(var(--lev-skew-x)) skewY(var(--lev-skew-y)) scaleX(var(--lev-scale-x)) scaleY(var(--lev-scale-y))',
	},
	transitionColors: {
		transitionProperty: 'background-color, border-color, color, fill, stroke',
		transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1);',
		transitionDuration: '150ms',
	},
	transitionOpacity: {
		transitionProperty: 'opacity',
		transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1);',
		transitionDuration: '150ms',
	},
	'-translate-x-1-2': {
		'--lev-translate-x': '-50%',
	},
	'-translate-y-1-2': {
		'--lev-translate-y': '-50%',
	},
	truncateText: truncateTextStyle,
	...CtaButtons,
	underline: {
		textDecoration: 'underline',
	},
	uppercase: {
		textTransform: 'uppercase',
	},
	verticalStack: {
		...Layouts.verticalStack(),
	},
	minW6: {
		minWidth: '1.5rem',
	},
	minW24: {
		minWidth: '6rem',
	},
	minW0: {
		minWidth: 0,
	},
	minWAuto: {
		minWidth: 'auto',
	},
	w2: {
		width: '0.5rem',
	},
	w4: {
		width: '1rem',
	},
	w5: {
		width: '1.25rem',
	},
	w6: {
		width: '1.5rem',
	},
	w8: {
		width: '2rem',
	},
	w10: {
		width: '2.5rem',
	},
	w12: {
		width: '3rem',
	},
	w16: {
		width: '4rem',
	},
	w20: {
		width: '5rem',
	},
	w24: {
		width: '6rem',
	},
	w28: {
		width: '7rem',
	},
	w32: {
		width: '8rem',
	},
	w36: {
		width: '9rem',
	},
	w40: {
		width: '10rem',
	},
	w44: {
		width: '11rem',
	},
	w48: {
		width: '12rem',
	},
	w60: {
		width: '15rem',
	},
	w64: {
		width: '16rem',
	},
	w80: {
		width: '20rem',
	},
	w96: {
		width: '24rem',
	},
	wAuto: {
		width: 'auto',
	},
	wFull: {
		width: '100%',
	},
	whiteSpacePreWrap: {
		whiteSpace: 'pre-wrap',
	},
	whiteSpaceNoWrap: {
		whiteSpace: 'nowrap',
	},
	z0: {
		zIndex: 0,
	},
	z5: {
		zIndex: 5,
	},
} as const;

const baseStyleSheet = StyleSheet.create(baseStyles);
const bs = baseStyleSheet;
export { baseStyleSheet, bs };
