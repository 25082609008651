import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	className?: string;
	type?: 'login' | 'large' | 'small' | 'extra-small' | 'tiny' | 'extra-tiny' | 'loginSecondary';
}

export class LoadingSpinner extends React.PureComponent<IProps> {
	public render() {
		const { type, className } = this.props;
		let spinnerStyle = styleSheet.loadingSmall;
		switch (type) {
			case 'large': {
				spinnerStyle = styleSheet.loadingLarge;
				break;
			}
			case 'login': {
				spinnerStyle = styleSheet.loadingLogin;
				break;
			}
			case 'loginSecondary': {
				spinnerStyle = styleSheet.loadingLoginSecondary;
				break;
			}
			case 'tiny': {
				spinnerStyle = styleSheet.loadingTiny;
				break;
			}
			case 'extra-tiny': {
				spinnerStyle = styleSheet.loadingExtraTiny;
				break;
			}
			case 'extra-small': {
				spinnerStyle = styleSheet.loadingExtraSmall;
				break;
			}
			default: {
				spinnerStyle = styleSheet.loadingSmall;
				break;
			}
		}
		return (
			<div className={`loading-spinner ${className || ''}`}>
				<div className={`loading-spinner-spinner ${css(spinnerStyle)}`} />
			</div>
		);
	}
}
