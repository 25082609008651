import { IDictionary, IImpersonationContext, IRawRichTextContentState, UserSessionContext } from '@ViewModels';
import escapeRegExp from 'lodash.escaperegexp';
import { v4 as uuid } from 'uuid';
import {
	IRichContentDocumentEditorPlaceholder,
	levPlaceholderPopoverCloseCommand,
	levPlaceholderPopoverCommand,
} from '.';

export enum TokenAttributes {
	DataPlaceholder = 'data-placeholder',
	DataPlaceholderValue = 'data-placeholder-value',
	DataId = 'data-id',
	ContentEditable = 'contenteditable',
}

export enum ContentEditableOptions {
	True = 'true',
	False = 'false',
}

export enum DataPlaceholderOptions {
	True = 'true',
	False = 'false',
}

export const FirstNamePlaceholder: IRichContentDocumentEditorPlaceholder = {
	additionalSymbols: ['{{firstName}}'],
	onClickCommand: levPlaceholderPopoverCommand,
	onCloseCommand: levPlaceholderPopoverCloseCommand,
	symbol: '{{first name}}',
	text: 'first name',
	value: 'contact.firstName',
};

export const LastNamePlaceholder: IRichContentDocumentEditorPlaceholder = {
	additionalSymbols: ['{{lastName}}'],
	onClickCommand: levPlaceholderPopoverCommand,
	onCloseCommand: levPlaceholderPopoverCloseCommand,
	symbol: '{{last name}}',
	text: 'last name',
	value: 'contact.lastName',
};

export const CompanyNamePlaceholder: IRichContentDocumentEditorPlaceholder = {
	additionalSymbols: ['{{companyName}}', '{{company}}'],
	onClickCommand: levPlaceholderPopoverCommand,
	onCloseCommand: levPlaceholderPopoverCloseCommand,
	symbol: '{{company name}}',
	text: 'company name',
	value: 'contact.companyName',
};

export const JobTitlePlaceholder: IRichContentDocumentEditorPlaceholder = {
	additionalSymbols: ['{{jobTitle}}'],
	symbol: '{{job title}}',
	text: 'job title',
	value: 'contact.jobTitle',
};

export const CityPlaceholder: IRichContentDocumentEditorPlaceholder = {
	onClickCommand: levPlaceholderPopoverCommand,
	onCloseCommand: levPlaceholderPopoverCloseCommand,
	symbol: '{{city}}',
	text: 'city',
	value: 'contact.address.city',
};

export const StatePlaceholder: IRichContentDocumentEditorPlaceholder = {
	onClickCommand: levPlaceholderPopoverCommand,
	onCloseCommand: levPlaceholderPopoverCloseCommand,
	symbol: '{{state}}',
	text: 'state',
	value: 'contact.address.stateProvince',
};

export const BioPlaceholder: IRichContentDocumentEditorPlaceholder = {
	additionalSymbols: ['{{custom}}'],
	onClickCommand: levPlaceholderPopoverCommand,
	onCloseCommand: levPlaceholderPopoverCloseCommand,
	symbol: '{{bio}}',
	text: 'bio',
	value: 'contact.bio',
};

export const SenderFirstNamePlaceholder: IRichContentDocumentEditorPlaceholder = {
	additionalSymbols: ['{{sender first name}}', '{{sender firstname}}'],
	onClickCommand: levPlaceholderPopoverCommand,
	onCloseCommand: levPlaceholderPopoverCloseCommand,
	symbol: '{{sender}}',
	text: 'sender first name',
	value: 'sender.firstName',
};

export const SenderFullNamePlaceholder: IRichContentDocumentEditorPlaceholder = {
	additionalSymbols: ['{{sender name}}'],
	onClickCommand: levPlaceholderPopoverCommand,
	onCloseCommand: levPlaceholderPopoverCloseCommand,
	symbol: '{{sender full name}}',
	text: 'sender full name',
	value: 'sender.fullName',
};

export const SenderPhonePlaceholder: IRichContentDocumentEditorPlaceholder = {
	additionalSymbols: ['{{sender phone number}}'],
	onClickCommand: levPlaceholderPopoverCommand,
	onCloseCommand: levPlaceholderPopoverCloseCommand,
	symbol: '{{sender phone}}',
	text: 'sender phone number',
	value: 'sender.phoneNumber',
};

export const SenderEmailPlaceholder: IRichContentDocumentEditorPlaceholder = {
	onClickCommand: levPlaceholderPopoverCommand,
	onCloseCommand: levPlaceholderPopoverCloseCommand,
	symbol: '{{sender email}}',
	text: 'sender email',
	value: 'sender.email',
};

export const UnsubscribeLinkPlaceholder: IRichContentDocumentEditorPlaceholder = {
	additionalSymbols: ['{{unsubscribeLink}}'],
	symbol: '{{unsubscribe link}}',
	text: 'click here',
	value: 'email.unsubscribeLink',
};

export const EmailUnscubscibePlaceholder: IRichContentDocumentEditorPlaceholder = {
	symbol: '{{unsubscribe}}',
	text: 'Unsubscribe Footer',
	value: 'email.unsubscribeFooter',
};
export const FullNamePlaceholder: IRichContentDocumentEditorPlaceholder = {
	additionalSymbols: ['{{fullName}}'],
	onClickCommand: levPlaceholderPopoverCommand,
	onCloseCommand: levPlaceholderPopoverCloseCommand,
	symbol: '{{full name}}',
	text: 'full name',
	value: 'contact.fullName',
};

export const MeetingHomepageLinkPlaceholder: IRichContentDocumentEditorPlaceholder = {
	additionalSymbols: ['{{meeting homepage link}}', '{{meetingHomepageLink}}', '{{meetingHomepage}}'],
	onClickCommand: levPlaceholderPopoverCommand,
	onCloseCommand: levPlaceholderPopoverCloseCommand,
	symbol: '{{meeting homepage}}',
	text: 'Meeting Homepage Link',
	value: 'meeting.homepageLink',
};

export const MeetingRescheduleLinkPlaceholder: IRichContentDocumentEditorPlaceholder = {
	additionalSymbols: ['{{meeting reschedule link}}', '{{reschedule link}}'],
	onClickCommand: levPlaceholderPopoverCommand,
	onCloseCommand: levPlaceholderPopoverCloseCommand,
	symbol: '{{meeting reschedule}}',
	text: 'Meeting Reschedule Link',
	value: 'meeting.rescheduleLink',
};

export const RenewalKeyFactPlaceholder: IRichContentDocumentEditorPlaceholder = {
	additionalSymbols: ['{{policies}}', '{{renewing policies}}'],
	symbol: '{{renewalKeyFact}}',
	text: 'Renewing Policies',
	value: 'keyfact.renewalKeyFact',
};

export const MeetingDatePlaceholder: IRichContentDocumentEditorPlaceholder = {
	additionalSymbols: ['{{meeting date}}'],
	symbol: '{{meetingDate}}',
	text: 'meeting date',
	value: 'meeting.meetingDate',
};

export const MeetingDayPlaceholder: IRichContentDocumentEditorPlaceholder = {
	additionalSymbols: ['{{meeting day}}'],
	symbol: '{{meetingDay}}',
	text: 'meeting day',
	value: 'meeting.meetingDay',
};

export const MeetingTimePlaceholder: IRichContentDocumentEditorPlaceholder = {
	additionalSymbols: ['{{meeting time}}'],
	symbol: '{{meetingTime}}',
	text: 'meeting time',
	value: 'meeting.meetingTime',
};

export const MeetingTimeZonePlaceholder: IRichContentDocumentEditorPlaceholder = {
	additionalSymbols: ['{{meeting timezone}}', '{{meeting time zone}}'],
	symbol: '{{meetingTimeZone}}',
	text: 'meeting timezone',
	value: 'meeting.meetingTimeZone',
};

export const MeetingVirtualLocation: IRichContentDocumentEditorPlaceholder = {
	additionalSymbols: ['{{virtual meeting link}}'],
	symbol: '{{virtualMeetingLink}}',
	text: 'virtual meeting link',
	value: 'meeting.virtualMeetingLink',
};

export const DefaultContactPlaceholders = [
	FirstNamePlaceholder,
	LastNamePlaceholder,
	CompanyNamePlaceholder,
	JobTitlePlaceholder,
	CityPlaceholder,
	StatePlaceholder,
	BioPlaceholder,
	FullNamePlaceholder,
	MeetingDatePlaceholder,
	MeetingDayPlaceholder,
	MeetingTimePlaceholder,
	MeetingVirtualLocation,
];

const DefaultEmailPlaceholders = [
	...DefaultContactPlaceholders,
	SenderFirstNamePlaceholder,
	SenderFullNamePlaceholder,
	SenderEmailPlaceholder,
	SenderPhonePlaceholder,
	RenewalKeyFactPlaceholder,
];

export const DefaultMeetingEmailPlaceholders = [
	MeetingDatePlaceholder,
	MeetingDayPlaceholder,
	MeetingTimePlaceholder,
	MeetingTimeZonePlaceholder,
];

export const GetDefaultEmailPlaceholders = (
	userSession: UserSessionContext,
	impersonationContext?: IImpersonationContext
): IRichContentDocumentEditorPlaceholder[] => {
	const placeholders = [...DefaultEmailPlaceholders];

	const account = impersonationContext?.account || userSession?.account;
	if (account?.features?.meetingScheduler?.enabled) {
		placeholders.push(MeetingHomepageLinkPlaceholder);
	}

	return placeholders;
};

/**
 * Helper method for organizing placeholders by their value
 *
 * @param types Placeholders
 */
const placeholdersByValue = (types: IRichContentDocumentEditorPlaceholder[]) => {
	return types.reduce<IDictionary<IRichContentDocumentEditorPlaceholder>>((dictionary, x) => {
		if (x.value) {
			dictionary[x.value] = x;
		}
		return dictionary;
	}, {});
};

const getSymbolsRegExpFormat = (symbols: string[]) => {
	return symbols.reduce<string>((regexpFormat, x, i, collection) => {
		const prefix = `${i === collection.length - 1 ? '(' : ''}`;
		const suffix = `${i === collection.length - 1 ? ')' : ''}`;
		return `${prefix}${regexpFormat}${i > 0 ? '|' : ''}${escapeRegExp(x)}${suffix}`;
	}, '');
};

export class Token {
	private html: Element;
	private type: IRichContentDocumentEditorPlaceholder;

	public static replaceImpostorPlaceholders = (
		content: string,
		placeholders: IRichContentDocumentEditorPlaceholder[]
	) => {
		let newContent = content;
		const placeholderTexts = placeholders.map(placeholder => placeholder.text).join('|');
		/*
		// Match styled impostor placeholder
		const placeholderImposterRegex =
		new RegExp(`<span[^>]*\\s+style=(["'])(?:(?!\\1).)*border\\s*:\\s*(?=[^;]*dashed)(?=[^;]*(?:#00AAE8|rgb\\(0,\\s*170,\\s*232\\)))
		(?=[^;]*1(?:\\.[0-9]+)?(?:p[tx]))(?:(?!\\1).)*\\1[^>]*>\\s*(${placeholderTexts})\\s*</\\s*span>`, 'igm'); */
		const placeholderImposterRegex = new RegExp(`<span[^>]*>\\s*(${placeholderTexts})\\s*</\\s*span>`, 'igm');
		for (;;) {
			const impostorMatch = placeholderImposterRegex.exec(content);
			if (impostorMatch == null) {
				break;
			}
			const impostorMatchedString = impostorMatch[0]; // <span style=\"border: dashed #00AAE8 1.0pt; padding: 1.0pt;\">first name</span>
			const placeholderText = impostorMatch[1]; // first name|last name|...
			const placeholder = placeholders.find(p => p.text === placeholderText);
			if (!placeholder) {
				continue;
			}
			const token = new Token(placeholder);
			newContent = newContent.replace(impostorMatchedString, token.toHTMLString());
		}
		newContent = newContent.replace(
			/\s?border:\s((?=[^;'"]*dashed)(?=[^;'"]*1(?:\.[0-9]+)?p[tx])(?=[^;'"]*#00aae8)[^;'"]*)/gim,
			''
		);
		return newContent;
	};

	public static getPlaceholderHtmlStringValue = (type: IRichContentDocumentEditorPlaceholder) => {
		return `<span ${TokenAttributes.ContentEditable}="false" ${TokenAttributes.DataId}="${uuid()}" ${
			TokenAttributes.DataPlaceholder
		}="true" ${TokenAttributes.DataPlaceholderValue}="${type.value}">${type.text}</span>`;
	};

	/**
	 * Try/catch this function to show an error if there is a broken token.
	 *
	 * @param content Raw HTML as a string
	 * @param type Placeholder type.. like FirstNamePlaceholder
	 */
	public static validateContent = (content: string, types: IRichContentDocumentEditorPlaceholder[]) => {
		const doc = new DOMParser().parseFromString(content, 'text/html');
		const spans = doc.querySelectorAll('span[data-placeholder]');
		spans.forEach(span => {
			const dataPlaceholderValue = span.getAttribute('data-placeholder-value');
			const matchingType = dataPlaceholderValue ? types.find(x => x.value === dataPlaceholderValue) : null;
			if (matchingType) {
				const token = new Token(matchingType, span.outerHTML);
				if (!token.isValid()) {
					throw new Error(
						`There appears to be an invalid token "${span?.innerHTML}" in this editor. Please remove it and type "${matchingType.symbol}" to automatically replace it with the correct one.`
					);
				}
			}
		});
	};

	/**
	 * Try/catch this function to show an error if there is a broken token.
	 *
	 * @param content Raw HTML as a string
	 * @param type Placeholder type.. like FirstNamePlaceholder
	 * @param replace Symbol to replace.. optional override
	 */
	public static replaceContent = (content: string, type: IRichContentDocumentEditorPlaceholder, replace?: string) => {
		let newContent = content;
		const addedTokens: Token[] = [];
		if (!content) {
			return ['', addedTokens] as const;
		}

		Token.validateContent(content, [type]);

		const stringToReplace = replace ?? getSymbolsRegExpFormat([type.symbol, ...(type.additionalSymbols || [])]);
		const replacementRegExp = new RegExp(stringToReplace, 'gim');
		// @ts-ignore
		let replacementMatch: RegExpExecArray = null;
		do {
			// @ts-ignore
			replacementMatch = replacementRegExp.exec(newContent);
			if (replacementMatch) {
				try {
					// replace symbol with actual token
					const token = new Token(type);
					newContent = newContent.replace(new RegExp(stringToReplace, 'im'), token.toHTMLString());
					addedTokens.push(token);
				} catch {
					// swallow this (shouldn't happen, but to prevent white screen just in case)
				}
			}
		} while (replacementMatch !== null);
		return [newContent, addedTokens] as const;
	};

	/**
	 * Try/catch this function to show an error if there is a broken token.
	 *
	 * @param content Raw HTML as a string
	 * @param type Placeholder type.. like FirstNamePlaceholder
	 * @param replace String to replace token with
	 */
	public static replaceToken = (content: string, type: IRichContentDocumentEditorPlaceholder, replace: string) => {
		let newContent = content;
		if (!content) {
			return '';
		}

		// Replace string token
		const replacementStringRegExp = new RegExp(type.symbol, 'gim');
		// @ts-ignore
		let replacementStringMatch: RegExpExecArray = null;
		do {
			// @ts-ignore
			replacementStringMatch = replacementStringRegExp.exec(newContent);
			if (replacementStringMatch) {
				try {
					// replace symbol with string
					newContent = newContent.replace(type.symbol, replace);
				} catch {
					// swallow this (shouldn't happen, but to prevent white screen just in case)
				}
			}
		} while (replacementStringMatch !== null);

		// Replace HTML token
		const token = new Token(type, newContent);
		if (token.isValid()) {
			const replacementHtmlRegExp = new RegExp(escapeRegExp(token.toHTMLString()), 'gim');
			// @ts-ignore
			let replacementHtmlMatch: RegExpExecArray = null;
			do {
				// @ts-ignore
				replacementHtmlMatch = replacementHtmlRegExp.exec(newContent);
				if (replacementHtmlMatch) {
					try {
						// replace symbol with string
						newContent = newContent.replace(token.toHTMLString(), replace);
					} catch {
						// swallow this (shouldn't happen, but to prevent white screen just in case)
					}
				}
			} while (replacementHtmlMatch !== null);
		}

		return newContent;
	};

	/**
	 * Will return valid token(s) in an html string Try/catch this function to show an error if there is a broken token.
	 *
	 * @param htmlStringValue Raw HTML as a string
	 * @param types Placeholder types to search for
	 */
	public static cleanHtmlString = (htmlStringValue: string, types: IRichContentDocumentEditorPlaceholder[]) => {
		const doc = new DOMParser().parseFromString(htmlStringValue, 'text/html');
		const valueToType = placeholdersByValue(types);
		doc.querySelectorAll('span[data-placeholder]').forEach(el => {
			const value = el.getAttribute(TokenAttributes.DataPlaceholderValue);
			// @ts-ignore
			const type = valueToType[value];
			if (type) {
				const token = new Token(type, el.outerHTML).makeValid(type).removeKnownInlineStyles();

				el.replaceWith(token.toHTMLElement());
			}
		});

		return doc.body?.innerHTML;
	};

	/**
	 * @param types Types to search for
	 * @param htmlStringValue Html to search over
	 * @returns Parsed tokens
	 */
	public static fromHtml = (types: IRichContentDocumentEditorPlaceholder[], htmlStringValue?: string) => {
		const tokens: Token[] = [];
		if (htmlStringValue) {
			const doc = new DOMParser().parseFromString(htmlStringValue, 'text/html');
			const valueToType = placeholdersByValue(types);
			doc.querySelectorAll('span[data-placeholder]').forEach(el => {
				const value = el.getAttribute(TokenAttributes.DataPlaceholderValue);
				// @ts-ignore
				const type = valueToType[value];
				if (type) {
					tokens.push(new Token(type, el.outerHTML));
				}
			});
		}

		return tokens;
	};

	public static isFirstNameTokenFormattedCorrectlyStrict = (content: IRawRichTextContentState): boolean => {
		const { document } = content;

		// @ts-ignore
		if (!content || !document.includes('first name')) {
			return true;
		}

		const correct = Token.isFirstNameTokenFormattedCorrectlyLoose(content);
		if (!correct) {
			return correct;
		}

		const token = new Token(FirstNamePlaceholder, document);
		return token.isValid();
	};

	public static isFirstNameTokenFormattedCorrectlyLoose = (content: IRawRichTextContentState): boolean => {
		const { document } = content;

		// @ts-ignore
		if (!content || !document.includes('first name')) {
			return true;
		}

		// Leave this in for now, but the goal would be to take it out eventually.
		const stringRegex = /{{first name}}/;
		// @ts-ignore
		if (stringRegex.test(document)) {
			return true;
		}

		// first name appears to be used in the middle of a sentence
		// @ts-ignore
		// @ts-ignore
		if (/first name\s/i.test(document) || /\sfirst name/i.test(document)) {
			return true;
		}

		// token is missing brackets
		if (
			// @ts-ignore
			/\s{{first name}[\s,]/.test(document) ||
			// @ts-ignore
			/\s{first name}}[\s,]/.test(document) ||
			// @ts-ignore
			/\s{first name}[\s,]/.test(document)
		) {
			return false;
		}

		return true;
	};

	constructor(type: IRichContentDocumentEditorPlaceholder, html?: string) {
		const value =
			html ??
			`<span contenteditable="false" data-id="${uuid()}" data-placeholder="true" data-placeholder-value="${
				type.value
			}">${type.text}</span>`;
		const doc = new DOMParser().parseFromString(value, 'text/html');
		const span = doc.querySelector('span[data-placeholder]');
		// @ts-ignore
		this.html = span;
		this.type = type;

		return this;
	}

	public contentEditable = (to: ContentEditableOptions) => {
		this.html.setAttribute(TokenAttributes.ContentEditable, to);
		return new Token(this.type, this.html.outerHTML);
	};

	public dataPlaceholder = (to: DataPlaceholderOptions) => {
		this.html.setAttribute(TokenAttributes.DataPlaceholder, to);
		return new Token(this.type, this.html.outerHTML);
	};

	public dataPlaceholderValue = (to: string) => {
		this.html.setAttribute(TokenAttributes.DataPlaceholderValue, to);
		return new Token(this.type, this.html.outerHTML);
	};

	public dataId = (to: string) => {
		this.html.setAttribute(TokenAttributes.DataId, to);
		return new Token(this.type, this.html.outerHTML);
	};

	public getAttribute = (attribute: string) => {
		return this.html.getAttribute(attribute);
	};

	public removeKnownInlineStyles = () => {
		const span = this.html as HTMLSpanElement;

		if (span.style?.background === 'rgb(243, 243, 243)' || span.style?.background.toLowerCase() === '#f3f3f3') {
			span.style.background = '';
		}

		if (span.style?.borderRadius === '3px') {
			span.style.borderRadius = '';
		}

		if (
			span.style?.border === '1px dashed rgb(0, 170, 232)' ||
			span.style?.border.toLowerCase() === '1px dashed #00aae8'
		) {
			span.style.border = '';
		}

		if (span.style?.boxSizing === 'border-box') {
			span.style.boxSizing = '';
		}

		if (span.style?.padding === '1px 5px') {
			span.style.padding = '';
		}

		return new Token(this.type, span.outerHTML);
	};

	public isValid = () => {
		const contentEditable = this.html?.getAttribute(TokenAttributes.ContentEditable) === ContentEditableOptions.False;
		const dataPlaceholder = this.html?.getAttribute(TokenAttributes.DataPlaceholder) === DataPlaceholderOptions.True;
		const dataPlaceholderValue = this.html?.getAttribute(TokenAttributes.DataPlaceholderValue) === this.type.value;

		return contentEditable && dataPlaceholder && dataPlaceholderValue;
	};

	public makeValid = (type: IRichContentDocumentEditorPlaceholder = this.type) => {
		return this.contentEditable(ContentEditableOptions.False)
			.dataId(this.getAttribute(TokenAttributes.DataId) || uuid())
			.dataPlaceholder(DataPlaceholderOptions.True)
			.dataPlaceholderValue(type.value);
	};

	public toHTMLString = () => {
		return this.html.outerHTML;
	};

	public toHTMLElement = () => {
		return this.html as HTMLSpanElement;
	};
}
