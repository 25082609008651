import { INameValue, ValueSelector } from '../ValueSelector';
import CountriesCollection from './countries.json';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';

export const Countries = CountriesCollection as INameValue[];

interface IProps {
	className?: string;
	onSelectionChanged?(selected: INameValue): void;
	selectedValue?: string;
}

export const CountrySelector: React.FC<IProps> = props => {
	const { className, ...restProps } = props;

	return (
		<ValueSelector
			className={`${css(styleSheet.container)} country-selector ${className || ''}`}
			options={Countries}
			{...restProps}
		/>
	);
};
