import * as React from 'react';

export const SvgIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
	const { className, children, width, height, viewBox, ...restProps } = props;
	return (
		<svg
			{...restProps}
			className={`svg-icon ${className || ''}`}
			height={height}
			viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
			width={width}
			xmlns='http://www.w3.org/2000/svg'
		>
			{children}
		</svg>
	);
};
