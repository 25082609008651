import * as StompJs from '@stomp/stompjs';
import { action, computed, observable, runInAction } from 'mobx';
import moment from 'moment';
import { AttachmentsToBeUploadedViewModel, ITelephonyConfiguration, ObservablePageCollectionController } from '.';
import { RemoteResourceEventsViewModel } from './RemoteResourceEventsViewModel';
import * as VmUtils from './Utils';
import * as ViewModels from './ViewModels';
import * as Api from './sdk';
import { UserSessionContext, UserViewModel, ViewModel } from './viewModels/index';

export interface IParentVMCallbacks {
	getAllUnreadMessages?: () => void;
	sortConversations?: () => void;
}

export enum Direction {
	Inbound = 'Inbound',
	Outbound = 'Outbound',
}

export enum TextStatus {
	Unknown = 'Unknown',
	Sending = 'Sending',
	Sent = 'Sent',
	Delivered = 'Delivered',
	Failed = 'Failed',
	Received = 'Received',
}

export interface ITextContact extends Api.IContact {
	selectedPhoneNumber: Api.IPhoneNumber;
}

export interface ITextRecipient {
	contact: Api.IPrincipal;
	number: Api.IPhoneNumberMetadata;
}

export interface IConversation extends Api.IBaseResourceModel {
	automatedSMSOptOutDate: string;
	earliestUnreadMessageDate: string;
	levitateNumber: Api.IPhoneNumberMetadata;
	inboundMessageCount: number;
	lastMessage: ITextMessage;
	outboundMessageCount: number;
	phoneNumberId: string;
	toNumbers: ITextRecipient[];
	unreadMessageCount: number;
}

export interface ITextMessage extends Api.IBaseResourceModel {
	bandwidthId: string;
	canRetry: boolean;
	conversationId: string;
	direction: Direction;
	errorMessage: string;
	media: Api.IFileAttachment[];
	owner: Api.IPhoneNumberMetadata;
	readDate: string;
	segmentCount: number;
	sentDate?: string;
	status: TextStatus;
	text: string;
}

export interface ITextMessagingRemoteResourceEvent {
	conversationId: string;
	id: string;
}

export type ITextMessageRemoteResourceEvent = ITextMessagingRemoteResourceEvent;

export interface ITextMessageCreatedRemoteResourceEvent extends ITextMessageRemoteResourceEvent {
	direction?: Direction;
}

export interface ITextMessageUpdatedRemoteResourceEvent extends ITextMessageRemoteResourceEvent {
	status: TextStatus;
}

class TextMessagingEventsViewModel<
	TUserSession extends UserSessionContext = UserSessionContext,
> extends RemoteResourceEventsViewModel<TUserSession, any> {
	private mOnTextMessageCreated: (events: Api.IRemoteEvent<ITextMessageCreatedRemoteResourceEvent>[]) => void;
	private mOnTextMessageUpdated: (events: Api.IRemoteEvent<ITextMessageUpdatedRemoteResourceEvent>[]) => void;
	private disconnectCb?: () => void = null;

	constructor(userSession: TUserSession, resource: any, eventLogger?: Api.IEventLoggingService) {
		super(userSession, resource, '#', eventLogger);
		this.mUserSession = userSession;
	}

	protected composeRoute() {
		return `User.${this.mUserSession?.user?.id}.TextMessage.#`;
	}

	public set onTextMessageCreated(callback: (
		events: Api.IRemoteEvent<ITextMessageCreatedRemoteResourceEvent>[]
	) => void) {
		this.mOnTextMessageCreated = callback;
	}

	public set onTextMessageUpdated(callback: (
		events: Api.IRemoteEvent<ITextMessageUpdatedRemoteResourceEvent>[]
	) => void) {
		this.mOnTextMessageUpdated = callback;
	}

	public connect = async (stompConfig?: StompJs.StompConfig, disconnectCb?: () => void) => {
		this.disconnectCb = disconnectCb;
		const remoteEvents = await this.mRemoteEvents.connect(stompConfig);
		this.onConnect();
		return remoteEvents;
	};

	public permananentlyDisconnect() {
		const promise = super.permananentlyDisconnect();
		this.mOnTextMessageCreated = null;
		this.mOnTextMessageUpdated = null;
		this.disconnectCb?.();
		console.log('%cTexting disconnected:', 'color: red;', new Date());
		return promise;
	}

	@action
	protected onMessage(events: Api.IRemoteEvent<any>[]) {
		if (this.mOnMessageCallback) {
			this.mOnMessageCallback(events);
		}

		if (this.mOnTextMessageCreated) {
			const createdEvents = events.filter(
				x => x.valueType === 'TextMessageCreate'
			) as Api.IRemoteEvent<ITextMessageCreatedRemoteResourceEvent>[];
			if (createdEvents.length > 0) {
				this.mOnTextMessageCreated(createdEvents);
			}
		}

		if (this.mOnTextMessageUpdated) {
			const updatedEvents = events.filter(
				x => x.valueType === 'TextMessageUpdate'
			) as Api.IRemoteEvent<ITextMessageUpdatedRemoteResourceEvent>[];
			if (updatedEvents.length > 0) {
				this.mOnTextMessageUpdated(updatedEvents);
			}
		}
	}
}
export type IConversationRemoteResourceEvent = ITextMessagingRemoteResourceEvent;

export type ITypingIndicator = Api.IDictionary<string>;

export interface IConversationUpdatedRemoteResourceEvent extends IConversationRemoteResourceEvent {
	status: TextStatus;
	typingIndicator: ITypingIndicator;
}

class ConversationEventsViewModel<
	TUserSession extends UserSessionContext = UserSessionContext,
> extends RemoteResourceEventsViewModel<TUserSession, any> {
	private mOnConversationUpdated: (events: Api.IRemoteEvent<IConversationUpdatedRemoteResourceEvent>[]) => void;
	protected disconnectCb?: () => void = null;

	constructor(userSession: TUserSession, resource: any, eventLogger?: Api.IEventLoggingService) {
		super(userSession, resource, '#', eventLogger);
	}

	protected composeRoute() {
		return `User.${this.mUserSession.user.id}.Conversation.#`;
	}

	public set onConversationUpdated(callback: (
		events: Api.IRemoteEvent<IConversationUpdatedRemoteResourceEvent>[]
	) => void) {
		this.mOnConversationUpdated = callback;
	}

	public connect = (stompConfig?: StompJs.StompConfig, disconnectCb?: () => void) => {
		this.disconnectCb = disconnectCb;
		return this.mRemoteEvents.connect(stompConfig)?.then(this.onConnect);
	};

	public permananentlyDisconnect() {
		const promise = super.permananentlyDisconnect();
		this.mOnConversationUpdated = null;
		this.disconnectCb?.();
		return promise;
	}

	@action
	protected onMessage(events: Api.IRemoteEvent<any>[]) {
		if (this.mOnMessageCallback) {
			this.mOnMessageCallback(events);
		}

		if (this.mOnConversationUpdated) {
			const updatedEvents = events.filter(
				x => x.valueType === 'ConversationUpdate'
			) as Api.IRemoteEvent<IConversationUpdatedRemoteResourceEvent>[];
			if (updatedEvents.length > 0) {
				this.mOnConversationUpdated(updatedEvents);
			}
		}
	}
}

export class TypingIndicatorEventsViewModel extends ConversationEventsViewModel {
	@observable.ref private mUsersTyping: UserViewModel[];
	private mAssignedUsers: UserViewModel[];
	private mLastUpdateEvent: Api.IRemoteEvent<IConversationUpdatedRemoteResourceEvent>;
	private phoneNumber: ITelephonyConfiguration;
	private mRefreshTimerHandle: any;

	constructor(userSession: UserSessionContext, phone: ITelephonyConfiguration, eventLogger?: Api.IEventLoggingService) {
		super(userSession, '#', eventLogger);
		this.phoneNumber = phone;
		this.mAssignedUsers = phone.assignedUserIds.map(x => {
			const userData: Api.IUser = { id: x };
			return new UserViewModel(userSession, userData);
		});
		this.mUsersTyping = [];
		this.onConversationUpdated = this.onConversationUpdatedHandler;
	}

	public loadUsers = () => {
		return this.mAssignedUsers?.length
			? Promise.all(this.mAssignedUsers.map(x => x.load()))
			: Promise.resolve<Api.IUser[]>([]);
	};

	public connect = async (stompConfig?: StompJs.StompConfig, disconnectCb?: () => void) => {
		this.disconnectCb = disconnectCb;
		await Promise.all([this.mRemoteEvents.connect(stompConfig).then(this.onConnect), this.loadUsers()]);
	};

	@computed
	public get usersTyping() {
		return this.mUsersTyping;
	}

	public getAssignedUsers() {
		return this.phoneNumber.assignedUserIds;
	}

	public onConversationUpdatedHandler = (events: Api.IRemoteEvent<IConversationUpdatedRemoteResourceEvent>[]) => {
		for (const event of events) {
			this.mLastUpdateEvent = event;
			this.checkForTypingChanges(event);
		}
	};

	public permananentlyDisconnect() {
		const promise = super.permananentlyDisconnect();
		this.clearTimer();
		return promise;
	}

	private checkForTypingChanges = (event = this.mLastUpdateEvent) => {
		this.mLastUpdateEvent = event;
		const oldMoment = moment().subtract(1, 'minute');
		const eventIndicators = event.value.typingIndicator || {};
		const idsForUsersCurrentlyTyping = Object.keys(eventIndicators).reduce((result, userId) => {
			const indicatorMoment = moment(eventIndicators[userId]);
			// check time and exclude current user
			if (indicatorMoment.isAfter(oldMoment) && userId !== this.mUserSession.user.id) {
				result.push(userId);
			}
			return result;
		}, [] as string[]);

		const usersIdsToFetch = idsForUsersCurrentlyTyping.filter(
			userId => !this.mAssignedUsers.some(user => user.id === userId)
		);
		// TODO: async fetch these users
		console.log('Need to fetch', usersIdsToFetch);

		// update collection
		this.mUsersTyping = idsForUsersCurrentlyTyping
			.map(userId => this.mAssignedUsers.find(x => x.id === userId))
			.filter(x => !!x);

		this.mRefreshTimerHandle = setTimeout(() => {
			this.checkForTypingChanges();
		}, 20000); // checks every 20 sec
	};

	private clearTimer = () => {
		if (this.mRefreshTimerHandle) {
			clearTimeout(this.mRefreshTimerHandle);
			this.mRefreshTimerHandle = null;
		}
	};
}

export class ConversationViewModel extends ViewModel {
	@observable private mConversation: IConversation;
	@observable
	private mTextMessagesPageCollectionController: ObservablePageCollectionController<ITextMessage, ITextMessage>;
	@observable private sending = false;
	@observable private fetching = false;
	@observable private mParentVMCallbacks: IParentVMCallbacks;

	public static defaultSort = (a: ITextMessage, b: ITextMessage) => {
		const dateA = new Date(a.creationDate);
		const dateB = new Date(b.creationDate);
		if (dateA < dateB) {
			return 1;
		} else if (dateB < dateA) {
			return -1;
		} else {
			return 0;
		}
	};

	constructor(userSession: UserSessionContext, conversation?: IConversation, parentVmCallbacks?: IParentVMCallbacks) {
		super(userSession);
		this.mConversation = conversation;
		this.mParentVMCallbacks = parentVmCallbacks;
		this.mTextMessagesPageCollectionController = new ObservablePageCollectionController<ITextMessage>({
			apiPath: `conversation/${this.id}/messages`,
			client: this.userSession.webServiceHelper,
			httpMethod: 'GET',
		});
	}

	@computed
	get isLoading() {
		return this.mTextMessagesPageCollectionController.isFetching;
	}

	@computed
	get id() {
		return this.mConversation?.id || '';
	}

	@computed
	get levitateNumber() {
		return this.mConversation.levitateNumber;
	}

	@computed
	get inboundMessageCount() {
		return this.mConversation.inboundMessageCount;
	}

	@computed
	get lastMessage() {
		return this.mConversation.lastMessage;
	}

	@computed
	get earliestUnreadMessageDate() {
		return this.mConversation.earliestUnreadMessageDate;
	}

	@computed
	get outboundMessageCount() {
		return this.mConversation.outboundMessageCount;
	}

	@computed
	get phoneNumberId() {
		return this.mConversation.phoneNumberId;
	}

	@computed
	get toNumbers() {
		return this.mConversation.toNumbers;
	}

	@computed
	get unreadMessageCount() {
		return this.mConversation.unreadMessageCount;
	}

	@computed
	get textMessages() {
		return this.mTextMessagesPageCollectionController.fetchResults;
	}

	@computed
	get isSending() {
		return this.sending;
	}

	@computed
	get isFetching() {
		return this.fetching;
	}

	@computed
	get allMessagesLoaded() {
		return this.mTextMessagesPageCollectionController.hasFetchedAllPages;
	}

	@computed
	public get automatedSMSOptOutDate() {
		return this.mConversation?.automatedSMSOptOutDate ? new Date(this.mConversation?.automatedSMSOptOutDate) : null;
	}

	public setConversation = (conversation: IConversation) => {
		this.mConversation = conversation;
	};

	@action
	public load = async () => {
		const conversation = await this.userSession.webServiceHelper.callWebServiceAsync<IConversation>(
			this.composeApiUrl({ urlPath: `conversation/${this.id}/` }),
			'GET'
		);
		if (conversation.success) {
			this.mConversation = conversation.value;
			this.loaded = true;
		}
	};

	@action
	public getMessage = async (id: string) => {
		this.busy = true;

		return Promise.all([
			this.userSession.webServiceHelper.callWebServiceAsync<ITextMessage>(
				this.composeApiUrl({ urlPath: `conversation/${this.id}/message/${id}` }),
				'GET'
			),
			this.userSession.webServiceHelper.callWebServiceAsync<IConversation>(
				this.composeApiUrl({ urlPath: `conversation/${this.id}` }),
				'GET'
			),
		]).then(([message, convo]) => {
			const errors: Api.IOperationResultNoValue[] = [];
			if (message.success) {
				const tm = this.mTextMessagesPageCollectionController.fetchResults.find(t => t.id === id);
				const index = this.mTextMessagesPageCollectionController.fetchResults.indexOf(tm);
				if (index > -1) {
					this.mTextMessagesPageCollectionController.fetchResults.setItemAtIndex(message.value, index);
				} else {
					this.mTextMessagesPageCollectionController.fetchResults.add(message.value);
				}

				this.sortMessages();
			} else {
				errors.push(Api.asApiError(message));
			}

			if (convo.success) {
				this.mConversation = convo.value;
			} else {
				errors.push(Api.asApiError(convo));
			}

			this.busy = false;

			if (errors.length) {
				throw Api.asApiError(errors[0]);
			}
		});
	};

	@action
	public getMessages = async () => {
		this.fetching = true;
		await this.mTextMessagesPageCollectionController.getNext();
		this.fetching = false;
		this.loaded = true;
	};

	@action
	public markAsRead = async () => {
		const result = await this.userSession.webServiceHelper.callWebServiceAsync<IConversation>(
			this.composeApiUrl({ urlPath: `Conversation/${this.id}/read` }),
			'PUT'
		);

		if (result.success) {
			this.mConversation = result.value;
		} else {
			throw Api.asApiError(result);
		}

		this.mParentVMCallbacks?.getAllUnreadMessages?.();
	};

	@action
	public sendMessage = async (msg: string, attachments: AttachmentsToBeUploadedViewModel<File>) => {
		this.sending = true;

		const data = new FormData();

		const value: { text?: string } = {};

		if (msg) {
			value.text = msg;
		}

		data.append('value', JSON.stringify(value));

		if (attachments.attachments.length) {
			attachments.attachments.forEach(attachment => {
				data.append('files', attachment);
			});
		}

		const result = await this.userSession.webServiceHelper.callWebServiceAsync<ITextMessage>(
			this.composeApiUrl({ urlPath: `conversation/${this.id}/send` }),
			'POST',
			data
		);

		if (result.success) {
			this.mTextMessagesPageCollectionController.fetchResults.add(result.value);
			this.sortMessages();
			this.mParentVMCallbacks?.sortConversations?.();
			this.sending = false;
		} else {
			this.sending = false;
			throw Api.asApiError(result);
		}
	};

	@action
	public updateRecipients = async (entity: ITextContact) => {
		this.busy = true;
		const regex = /\d+/g;
		this.mConversation.toNumbers = this.toNumbers.map(num => {
			let phoneNumberMatch: Api.IPhoneNumber;
			if (entity.selectedPhoneNumber) {
				const phoneNumber = entity.selectedPhoneNumber.metadata?.standard || entity.selectedPhoneNumber?.value;
				const parsed = phoneNumber.match(regex).join('');
				if (num.number.e164.includes(parsed)) {
					phoneNumberMatch = entity.selectedPhoneNumber;
				}
			} else {
				phoneNumberMatch = entity.phoneNumbers?.find(p => {
					const parsed = p.value.match(regex).join('');
					return num.number.e164.includes(parsed);
				});
			}

			if (phoneNumberMatch) {
				num.contact = entity;
			}

			return num;
		});

		const result = await this.userSession.webServiceHelper.callWebServiceAsync<IConversation>(
			this.composeApiUrl({ urlPath: `conversation/${this.id}/recipients` }),
			'PUT',
			this.toNumbers
		);

		if (result.success) {
			this.mConversation = result.value;
			this.busy = false;
		} else {
			this.busy = false;
			throw Api.asApiError(result);
		}
	};

	@action
	private sortMessages = () => {
		this.mTextMessagesPageCollectionController.fetchResults.sort(ConversationViewModel.defaultSort);
	};
}

export const TextMessagingMaxFileByteSize = 4500 * 1024; // 4.5MB

export class TextMessagingViewModel extends ViewModel {
	@observable.ref protected mEventsViewModel: TextMessagingEventsViewModel;
	@observable protected mPhoneNumberOrders: Api.ITelephonyConfiguration[];
	@observable
	protected mConversationsPageCollectionController: ObservablePageCollectionController<
		IConversation,
		ConversationViewModel
	>;
	@observable protected mLoadingConversations = false;
	@observable protected mConversationsLoaded = false;
	@observable protected mTotalUnreadMessages: number;

	public unreadOnly = false;

	constructor(userSession: UserSessionContext) {
		super(userSession);
	}

	@computed
	get phoneNumberOrder() {
		return this.mPhoneNumberOrders?.filter(phoneNumberOrder => {
			return (
				phoneNumberOrder.connectionState !== Api.ConnectionState.Disconnected &&
				phoneNumberOrder.connectionState !== Api.ConnectionState.Failed
			);
		})?.[0];
	}

	@computed
	get callForwardingMetadata() {
		return this.phoneNumberOrder?.callForwardingNumber?.phoneNumber;
	}

	@computed
	get conversationApiUrl() {
		return `conversation/phoneNumber/${this.id}`;
	}

	@computed
	get connectionState() {
		return this.phoneNumberOrder?.connectionState;
	}

	@computed
	get conversations() {
		return this.mConversationsPageCollectionController?.fetchResults;
	}

	@computed
	get creator() {
		return this.phoneNumberOrder?.creator;
	}

	@computed
	get id() {
		return this.phoneNumberOrder?.id;
	}

	@computed
	get loadingConversations() {
		return this.mLoadingConversations;
	}

	@computed
	get number() {
		return this.phoneNumberOrder?.number;
	}

	@computed
	get orderId() {
		return this.phoneNumberOrder?.orderId;
	}

	@computed
	get supportsGroupTexting() {
		return this.phoneNumberOrder?.supportsGroupTexting;
	}

	@computed
	get allConversationsLoaded() {
		return this.mConversationsPageCollectionController?.hasFetchedAllPages;
	}

	@computed
	get conversationsLoaded() {
		return this.mConversationsLoaded;
	}

	@computed
	get totalUnreadMessages() {
		return this.mTotalUnreadMessages || 0;
	}

	@computed
	get conversationCallbacks() {
		return {
			getAllUnreadMessages: this.getAllUnreadMessages,
			sortConversations: this.sortConversations,
		};
	}

	protected mTransformConversation = (conversation: IConversation) => {
		return new ConversationViewModel(this.userSession, conversation, this.conversationCallbacks);
	};

	public get textMessageEvents() {
		return this.mEventsViewModel;
	}

	@action
	public setUnreadOnly(enabled: boolean) {
		this.unreadOnly = enabled;
	}

	@action
	public archiveConversation = async (id: string) => {
		const result = await this.mUserSession.webServiceHelper.callWebServiceAsync<IConversation>(
			this.composeApiUrl({ urlPath: `conversation/${id}` }),
			'DELETE'
		);

		if (result.success) {
			const archived = this.mConversationsPageCollectionController?.fetchResults.getById(id);
			this.mConversationsPageCollectionController?.fetchResults.removeItems([archived]);
		} else {
			this.busy = false;
			throw Api.asApiError(result);
		}
	};

	@action
	public markAsSpam = async (id: string) => {
		if (!this.busy) {
			this.busy = true;
			const result = await this.userSession.webServiceHelper.callWebServiceAsync<IConversation>(
				this.composeApiUrl({ urlPath: `conversation/${id}/spam` }),
				'PUT',
				{}
			);

			if (result.success) {
				const archived = this.mConversationsPageCollectionController?.fetchResults.getById(id);
				this.mConversationsPageCollectionController?.fetchResults.removeItems([archived]);
				this.busy = false;
			} else {
				this.busy = false;
				throw Api.asApiError(result);
			}
		}
	};

	@action toggleUnsubscribeConversation = async (conversation: ConversationViewModel) => {
		if (!this.busy) {
			this.busy = true;
			const result = await this.userSession.webServiceHelper.callWebServiceAsync<IConversation>(
				this.composeApiUrl({ urlPath: `conversation/${conversation.id}/automatedSMSOptOut` }),
				conversation.automatedSMSOptOutDate ? 'DELETE' : 'PUT',
				null
			);

			if (result.success) {
				conversation.setConversation(result.value);
				this.busy = false;
			} else {
				this.busy = false;
				throw Api.asApiError(result);
			}
			return result.value;
		}
	};

	@action
	public updateTypingIndicator = (userId = this.mUserSession.user.id) => {
		return this.userSession.webServiceHelper.callWebServiceAsync<IConversation>(
			this.composeApiUrl({ urlPath: `conversation/${userId}/typing` }),
			'PUT',
			{}
		);
	};

	@action
	public createConversation = async (phoneNumbers: string[]) => {
		this.busy = true;
		const result = await this.mUserSession.webServiceHelper.callWebServiceAsync<IConversation>(
			this.composeApiUrl({ urlPath: `Conversation` }),
			'POST',
			{
				phoneNumberId: this.id,
				toPhoneNumbers: phoneNumbers,
			}
		);

		if (result.success) {
			let conversation = this.mConversationsPageCollectionController?.fetchResults.find(c => c.id === result.value.id);
			const index = this.mConversationsPageCollectionController?.fetchResults.indexOf(conversation);

			if (index > -1) {
				this.mConversationsPageCollectionController?.fetchResults.setItemAtIndex(conversation, index);
			} else {
				conversation = new ConversationViewModel(this.userSession, result.value, this.conversationCallbacks);
				this.mConversationsPageCollectionController?.fetchResults.unshift(conversation);
			}

			this.busy = false;
			return conversation;
		} else {
			this.busy = false;
			throw Api.asApiError(result);
		}
	};

	/**
	 * Checks the current conversations list for conversations match. Does not check server-side.
	 *
	 * @param id Conversation id
	 * @returns Boolean
	 */
	public hasConversation = (id: string) => {
		return !!this.mConversationsPageCollectionController?.fetchResults.find(x => x.id === id);
	};

	/**
	 * If the conversation does not exist in the vm's current list of conversations, it will attempt to resolve it from
	 * the server and add the result to it's conversations list before returning.
	 *
	 * @param id Conversation id
	 * @returns ConversationViewModel
	 */
	@action
	public getConversation = async (id: string) => {
		const existingConversation = this.mConversationsPageCollectionController?.fetchResults.find(x => x.id === id);
		if (existingConversation) {
			return existingConversation;
		}
		const result = await this.mUserSession.webServiceHelper.callWebServiceAsync<IConversation>(
			this.composeApiUrl({ urlPath: `conversation/${id}` }),
			'GET'
		);

		if (result.success) {
			const conversation = new ConversationViewModel(this.userSession, result.value, this.conversationCallbacks);
			this.mConversationsPageCollectionController?.fetchResults.unshift(conversation);
			return conversation;
		} else {
			throw Api.asApiError(result);
		}
	};

	/**
	 * Will only add those conversations that are not already part of the collection
	 */
	@action addConversations = (conversations: IConversation[]) => {
		if (conversations?.length) {
			const toAdd = conversations.filter(x => !this.hasConversation(x.id));
			const vms = toAdd.map(x => new ConversationViewModel(this.userSession, x, this.conversationCallbacks));
			if (vms?.length) {
				this.mConversationsPageCollectionController?.fetchResults.addAll(vms, 'unshift');
			}
		}
	};

	@action
	public getConversationByPhoneNumbers = async (phoneNumbers: Api.IPhoneNumber[]) => {
		const contactPhoneNumbers = phoneNumbers.map(n => n?.metadata?.standard ?? n?.value ?? null);
		if (contactPhoneNumbers?.length > 0) {
			const result = await this.mUserSession.webServiceHelper.callWebServiceAsync<IConversation>(
				this.composeApiUrl({ urlPath: `conversation/phoneNumber/${this.id}/conversation` }),
				'POST',
				{ contactPhoneNumbers }
			);
			if (result.success) {
				const convo = this.mConversationsPageCollectionController?.fetchResults.getById(result.value.id);
				if (convo) {
					return convo;
				} else {
					const conversation = new ConversationViewModel(this.userSession, result.value, this.conversationCallbacks);
					this.mConversationsPageCollectionController?.fetchResults.unshift(conversation);
					return conversation;
				}
			} else {
				// intentionally swallowing error
			}
		}
	};

	public removeConversationById = (id: string) => {
		const conversation = this.mConversationsPageCollectionController?.fetchResults.find(x => x.id === id);
		this.removeConversation(conversation);
	};

	public removeConversation = (conversation: ConversationViewModel) => {
		if (!conversation) {
			return null;
		}
		return this.mConversationsPageCollectionController?.fetchResults.removeItems([conversation]);
	};

	@action
	public getConversations = async () => {
		this.mLoadingConversations = true;
		await this.mConversationsPageCollectionController?.getNext({
			unreadOnly: this.unreadOnly,
		});
		this.mLoadingConversations = false;
		this.mConversationsLoaded = true;
	};

	@action
	public fullReset = () => {
		this.mEventsViewModel?.permananentlyDisconnect();
		this.mPhoneNumberOrders = [];
		this.reset();
	};

	@action
	public getAllUnreadMessages = async () => {
		const result = await this.mUserSession.webServiceHelper.callWebServiceAsync<number>(
			this.composeApiUrl({ urlPath: `phoneNumber/${this.id}/unread` }),
			'GET'
		);

		if (result.success) {
			this.mTotalUnreadMessages = result.value;
		} else {
			throw Api.asApiError(result);
		}
	};

	@action
	public getUserPhone = async () => {
		if (VmUtils.canViewTexting(this.userSession)) {
			this.loading = true;
			const result = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.ITelephonyConfiguration[]>(
				this.composeApiUrl({ urlPath: 'PhoneNumber/user' }),
				'GET'
			);

			if (result.success) {
				runInAction(() => {
					this.mPhoneNumberOrders = result.value;
					if (this.id) {
						this.getAllUnreadMessages();
					}
					this.loading = false;
					this.loaded = true;
				});
			} else {
				this.loading = false;
				throw Api.asApiError(result);
			}
		}
	};

	@action
	protected onTextMessageCreated = (events: Api.IRemoteEvent<ITextMessageCreatedRemoteResourceEvent>[]) => {
		for (const event of events) {
			const convo = this.mConversationsPageCollectionController?.fetchResults.find(
				c => c.id === event.value.conversationId
			);
			if (convo) {
				convo.getMessage(event.value.id);
			} else {
				this.getConversation(event.value.conversationId);
			}
		}
		this.sortConversations();
		this.getAllUnreadMessages();
	};

	@action
	protected onTextMessageUpdated = (events: Api.IRemoteEvent<ITextMessageUpdatedRemoteResourceEvent>[]) => {
		for (const event of events) {
			if (event.value.status === TextStatus.Delivered) {
				const convo = this.mConversationsPageCollectionController?.fetchResults.find(
					c => c.id === event.value.conversationId
				);
				if (convo) {
					convo.getMessage(event.value.id);
				} else {
					this.getConversation(event.value.conversationId);
				}
			}
		}
		this.sortConversations();
		this.getAllUnreadMessages();
	};

	protected connect = (eventLogger: Api.IEventLoggingService, stompConfig?: StompJs.StompConfig) => {
		this.mEventsViewModel = new TextMessagingEventsViewModel(this.mUserSession, {}, eventLogger);
		this.mEventsViewModel.onTextMessageCreated = this.onTextMessageCreated;
		this.mEventsViewModel.onTextMessageUpdated = this.onTextMessageUpdated;
		this.mEventsViewModel.connect(stompConfig);
	};

	@action
	public init = (eventLogger: Api.IEventLoggingService, stompConfig?: StompJs.StompConfig) => {
		if (VmUtils.canViewTexting(this.userSession)) {
			this.initConversations();
			this.getUserPhone();
			this.connect(eventLogger, stompConfig);
		}
	};

	@action
	public reset = () => {
		this.loaded = undefined;
		this.loading = undefined;
		this.busy = undefined;
		this.mLoadingConversations = false;
		this.mConversationsPageCollectionController?.reset();
		this.mConversationsPageCollectionController = null;
	};

	@action
	protected sortConversations = () => {
		this.mConversationsPageCollectionController?.fetchResults.sort((a, b) => {
			const dateA = new Date(a?.lastMessage?.creationDate);
			const dateB = new Date(b?.lastMessage?.creationDate);
			if (dateA < dateB) {
				return 1;
			} else if (dateB < dateA) {
				return -1;
			} else {
				return 0;
			}
		});
	};

	@action
	public onSetForwardingPhoneNumber = async (forwardingNumber: string, id: string) => {
		this.busy = true;
		const result = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.ITelephonyConfiguration>(
			this.composeApiUrl({ urlPath: `PhoneNumber/${id}/callForwardingNumber` }),
			'PUT',
			{
				number: forwardingNumber,
			}
		);

		if (result.success) {
			const phoneNumberOrder = this.mPhoneNumberOrders?.filter(x => x.id !== id);
			phoneNumberOrder.unshift(result.value);
			this.busy = false;
			return phoneNumberOrder;
		}
	};

	public normalizePhoneNumber = async (phoneNumber: string) => {
		const result = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.IPhoneNumberMetadata>(
			this.composeApiUrl({ urlPath: `phoneNumber/normalize/${phoneNumber}` }),
			'GET'
		);

		if (result.success) {
			return result.value;
		} else {
			throw Api.asApiError(result);
		}
	};

	protected initConversations = () => {
		this.mConversationsPageCollectionController = new ObservablePageCollectionController<
			IConversation,
			ConversationViewModel
		>({
			apiPath: () => this.conversationApiUrl,
			client: this.userSession.webServiceHelper,
			httpMethod: 'GET',
			transformer: this.mTransformConversation,
		});
	};
}

export class TextRecipientViewModel extends ViewModels.ContactViewModel {
	@observable protected mSelectedPhoneNumber: Api.IPhoneNumber;

	metadata?: Api.IPhoneNumberMetadata;
	value?: string;

	constructor(userSession: UserSessionContext, contact: Api.IContact) {
		super(userSession, contact);
		const textingCapablePhoneNumbers = this.textingCapablePhoneNumbers;
		if (textingCapablePhoneNumbers?.length === 1) {
			this.mSelectedPhoneNumber = textingCapablePhoneNumbers[0];
		}
	}

	@computed
	get selectedPhoneNumber() {
		return this.mSelectedPhoneNumber && this.entity.phoneNumbers.find(x => x.value === this.mSelectedPhoneNumber.value)
			? this.mSelectedPhoneNumber
			: null;
	}

	@computed
	get needsResolving() {
		const numbers = this.textingCapablePhoneNumbers;
		return (
			!numbers.length ||
			(numbers.length > 1 && !this.selectedPhoneNumber) ||
			(numbers.length === 1 && !numbers[0].metadata)
		);
	}

	@computed
	get textingCapablePhoneNumbers() {
		return VmUtils.getTextMessageCapablePhoneNumbers(this.entity?.phoneNumbers);
	}

	@action
	public setSelectedPhoneNumber = (num: Api.IPhoneNumber) => {
		this.mSelectedPhoneNumber = num;
	};

	public toJs = () => {
		return {
			...this.entity,
			selectedPhoneNumber: this.selectedPhoneNumber,
		};
	};
}
