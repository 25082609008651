import { GameType, IBaseApiModel, IBaseResourceModel, SlotMachineType } from '..';

export enum AchievementType {
	DemoBooking = 'DemoBooking',
	DailyDemoGoal = 'DailyDemoGoal',
	DailyPointsGoal = 'DailyPointsGoal',
	DailyDialGoal = 'DailyDialGoal',
	DailyConnectionGoal = 'DailyConnectionGoal',
}

export enum AchievementRewardType {
	GameToken = 'GameToken',
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAchievementReward {}

export interface IGameTokenAchievementReward extends IAchievementReward {
	additionalDetails?: {
		slotMachineType: SlotMachineType;
	};
	gameId: string;
	gameTokenId: string;
	gameType: GameType;
}

export interface IAchievementRewardConfiguration extends IBaseApiModel {
	rewardType: AchievementRewardType;
}

export interface IGameTokenRewardConfiguration extends IAchievementRewardConfiguration {
	gameId: string;
	gameType: GameType;
}

export interface IAchievementConfiguration<
	TRewardConfig extends IAchievementRewardConfiguration = IAchievementRewardConfiguration,
> extends IBaseResourceModel {
	achievementType: AchievementType;
	enabled: boolean;
	rewardConfiguration: TRewardConfig;
}

export interface IAchievement<
	TRewardConfig extends IAchievementRewardConfiguration = IAchievementRewardConfiguration,
	TAchievementConfig extends IAchievementConfiguration<TRewardConfig> = IAchievementConfiguration<TRewardConfig>,
> {
	achievementConfiguration: TAchievementConfig;
	description?: string;
	type: AchievementType;
}

export type AchievementModelTypes =
	| 'AchievementConfiguration'
	| 'AchievementRewardConfiguration'
	| 'Achievement'
	| 'GameTokenRewardConfiguration'
	| 'GameTokenAchievementReward'
	| 'AchievementReward';
