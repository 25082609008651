import * as StompJs from '@stomp/stompjs';
import { action, computed, observable } from 'mobx';
import { RemoteResourceEventsViewModel } from './RemoteResourceEventsViewModel';
import * as Api from './sdk';
import { UserSessionContext, ViewModel } from './viewModels/index';

export interface IPhoneNumberRemoteResourceEvent {
	creatorId?: string;
	id?: string;
}

class PhoneNumberEventsViewModel<
	TUserSession extends UserSessionContext = UserSessionContext,
> extends RemoteResourceEventsViewModel<TUserSession, IPhoneNumberRemoteResourceEvent> {
	// @ts-ignore
	private mOnPhoneNumberUpdated: (events: Api.IRemoteEvent<IPhoneNumberRemoteResourceEvent>[]) => void;

	constructor(
		userSession: TUserSession,
		resource: IPhoneNumberRemoteResourceEvent,
		eventLogger?: Api.IEventLoggingService
	) {
		super(userSession, resource, '#', eventLogger);
		this.mUserSession = userSession;
	}

	protected composeRoute() {
		// @ts-ignore
		return `User.${this.mUserSession.user.id}.PhoneNumber.#`;
	}

	public set onPhoneNumberUpdated(callback: (events: Api.IRemoteEvent<IPhoneNumberRemoteResourceEvent>[]) => void) {
		this.mOnPhoneNumberUpdated = callback;
	}

	public permananentlyDisconnect() {
		// @ts-ignore
		this.mOnPhoneNumberUpdated = null;
		return super.permananentlyDisconnect();
	}

	@action
	protected onMessage(events: Api.IRemoteEvent<IPhoneNumberRemoteResourceEvent>[]) {
		if (this.mOnMessageCallback) {
			this.mOnMessageCallback(events);
		}

		if (this.mOnPhoneNumberUpdated) {
			const emailEvents = events.filter(
				x => x.valueType === 'PhoneNumberUpdate'
			) as Api.IRemoteEvent<IPhoneNumberRemoteResourceEvent>[];
			if (emailEvents.length > 0) {
				this.mOnPhoneNumberUpdated(emailEvents);
			}
		}
	}
}
export class PhoneNumberViewModel extends ViewModel {
	// @ts-ignore
	@observable.ref private mEventsViewModel: PhoneNumberEventsViewModel;
	// @ts-ignore
	@observable.ref protected mPhoneNumberOrders: Api.ITelephonyConfiguration[];
	// @ts-ignore
	@observable.ref protected mPhoneNumberOrder: Api.ITelephonyConfiguration;
	// @ts-ignore
	@observable.ref protected mCallForwarding: Api.ITelephonyConfiguration[];
	// @ts-ignore
	@observable private mSearchedNumber: string;
	// @ts-ignore
	@observable.ref protected mSharedNumbers: Api.ITelephonyConfiguration[];

	@computed
	get phoneNumberOrder() {
		return this.mPhoneNumberOrders?.filter(phoneNumberOrder => {
			return (
				phoneNumberOrder.connectionState !== Api.ConnectionState.Disconnected &&
				phoneNumberOrder.connectionState !== Api.ConnectionState.Failed
			);
		})?.[0];
	}

	@computed
	get sharedNumber() {
		return this.mSharedNumbers?.filter(sharedNumber => {
			return (
				sharedNumber.connectionState !== Api.ConnectionState.Disconnected &&
				sharedNumber.connectionState !== Api.ConnectionState.Failed
			);
		})?.[0];
	}

	@computed
	get callForwardingMetadata() {
		return this.phoneNumberOrder?.callForwardingNumber?.phoneNumber;
	}

	@computed
	get phoneNumberId() {
		return this.phoneNumberOrder?.id;
	}

	@computed
	get connectionState() {
		return this.phoneNumberOrder?.connectionState;
	}

	@computed
	get creator() {
		return this.phoneNumberOrder?.creator;
	}

	@computed
	get id() {
		return this.phoneNumberOrder?.id;
	}

	@computed
	get number() {
		return this.phoneNumberOrder?.number;
	}

	@computed
	get orderId() {
		return this.phoneNumberOrder?.orderId;
	}

	@computed
	get searchedNumber() {
		return this.mSearchedNumber;
	}

	@computed
	get hasSearchedNumber() {
		return !!this.mSearchedNumber && this.mSearchedNumber !== '';
	}

	@computed
	get phoneNumberScope() {
		return this.phoneNumberOrder?.phoneNumberScope;
	}

	@computed
	get assignedUserIds() {
		return this.phoneNumberOrder?.assignedUserIds;
	}

	public disconnect = () => {
		this.mEventsViewModel.permananentlyDisconnect();
		this.reset();
	};

	@action
	public init = async (eventLogger: Api.IEventLoggingService, stompConfig?: StompJs.StompConfig) => {
		this.mEventsViewModel = new PhoneNumberEventsViewModel(this.mUserSession, {}, eventLogger);
		this.mEventsViewModel.onPhoneNumberUpdated = this.onPhoneNumberUpdated;
		await Promise.all([this.mEventsViewModel.connect(stompConfig), this.getSharedNumbers()]);
	};

	@action
	public orderPhoneNumber = async (scope: Api.PhoneNumberScope) => {
		this.busy = true;
		const result = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.ITelephonyConfiguration>(
			this.composeApiUrl({ queryParams: { scope }, urlPath: `PhoneNumber/${this.searchedNumber}` }),
			'POST'
		);

		if (result.success) {
			// @ts-ignore
			this.mPhoneNumberOrders = this.mPhoneNumberOrders
				? this.mPhoneNumberOrders.map(x => {
						// @ts-ignore
						return x.id === result.value.id ? result.value : x;
					})
				: [result.value];
			this.busy = false;
		} else {
			this.busy = false;
			throw Api.asApiError(result);
		}
	};

	@action
	public getSharedNumbers = async () => {
		this.busy = true;
		const result = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.ITelephonyConfiguration>(
			this.composeApiUrl({ urlPath: `PhoneNumber/shared` }),
			'GET'
		);

		if (result.success) {
			// @ts-ignore
			this.mSharedNumbers = this.mSharedNumbers?.map(x => {
				// @ts-ignore
				return x.id === result.value.id ? result.value : x;
			}) || [result.value];
			this.busy = false;
		} else {
			this.busy = false;
			if (result.systemCode !== 404) {
				throw Api.asApiError(result);
			}
		}
	};

	@action
	public searchForPhoneNumber = async (areaCode?: string) => {
		this.busy = true;
		this.mSearchedNumber = '';
		let ac = '';
		if (areaCode) {
			ac = `?areaCode=${areaCode}`;
		}

		const result = await this.mUserSession.webServiceHelper.callWebServiceAsync<string[]>(
			this.composeApiUrl({ urlPath: `PhoneNumber/search${ac}` }),
			'GET'
		);
		if (result.success) {
			// @ts-ignore
			this.mSearchedNumber = result.value[0];
			this.busy = false;
		} else {
			this.busy = false;
			throw Api.asApiError(result);
		}
	};

	@action
	private onPhoneNumberUpdated = async (events: Api.IRemoteEvent<IPhoneNumberRemoteResourceEvent>[]) => {
		for (const event of events) {
			const result = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.ITelephonyConfiguration>(
				// @ts-ignore
				this.composeApiUrl({ urlPath: `PhoneNumber/${event.value.id}` }),
				'GET'
			);

			if (result.success) {
				// @ts-ignore
				this.mPhoneNumberOrders = this.mPhoneNumberOrders
					? this.mPhoneNumberOrders.map(x => {
							// @ts-ignore
							return x.id === result.value.id ? result.value : x;
						})
					: [result.value];
			}
		}
	};

	@action
	public onSetForwardingPhoneNumber = async (forwardingNumber: string, id: string) => {
		this.busy = true;
		const result = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.ITelephonyConfiguration>(
			this.composeApiUrl({ urlPath: `PhoneNumber/${id}/callForwardingNumber` }),
			'PUT',
			{
				number: forwardingNumber,
			}
		);

		if (result.success) {
			// @ts-ignore
			this.mPhoneNumberOrder = result.value;
			this.busy = false;
			return result.value;
		} else {
			this.busy = false;
			throw Api.asApiError(result);
		}
	};

	public onSetPhoneNumberScope = async (scope: Api.PhoneNumberScope) => {
		this.phoneNumberOrder.phoneNumberScope = scope;
	};

	public onSetAssignedUser = async (id: string) => {
		// @ts-ignore
		this.assignedUserIds.push(id);
	};

	@action
	public addUserToSharedNumber = async (id: string) => {
		this.busy = true;
		const result = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.ITelephonyConfiguration>(
			this.composeApiUrl({ urlPath: `PhoneNumber/shared/${id}` }),
			'POST'
		);

		if (result.success) {
			// @ts-ignore
			this.mSharedNumbers = this.mSharedNumbers?.map(x => {
				// @ts-ignore
				return x.id === result.value.id ? result.value : x;
			}) || [result.value];
			this.busy = false;
		} else {
			this.busy = false;
			throw Api.asApiError(result);
		}
	};

	@action
	private reset = () => {
		// @ts-ignore
		this.mPhoneNumberOrders = null;
		// @ts-ignore
		this.mSharedNumbers = null;
		this.mEventsViewModel?.permananentlyDisconnect();
	};
}
