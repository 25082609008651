import { IBaseApiModel, IBaseApiTypeModel } from './';

export enum HouseholdTitle {
	Unknown = 'Unknown',
	Other = 'Other',
	Spouse = 'Spouse',
}

export interface IHousehold extends IBaseApiModel {
	name?: string;
	firstNames?: string;
}

export interface IHouseholdRelationship extends IBaseApiTypeModel {
	originalTitle?: string;
	title?: HouseholdTitle;
}

export interface RelationshipInfo {
	contactId: string;
	relationship: IHouseholdRelationship;
}

export interface CreateHouseholdRequest {
	relationships: RelationshipInfo[];
}
