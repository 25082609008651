import * as StompJs from '@stomp/stompjs';
import { computed, observable } from 'mobx';
import moment from 'moment';
import { v4 as uuidgen } from 'uuid';
import { VmUtils } from '.';
import { IBuildInfo } from '../models';
import BuildInfo from '../web/assets/build-info.json';
import * as Api from './sdk';
import { UserSessionContext } from './viewModels/index';

export interface IRemoteEventSubscriptionContext<T = any> {
	callback: (event: Api.IRemoteEvent<T> | Api.IRemoteEvent<T>[]) => void;
	deliveryOptions?: Api.IRemoteEventsDeliveryOptions;
	id: string;
	nextCallbackTimeoutHandle?: any;
	route: string;
	stompSubscription?: StompJs.StompSubscription;
	valueCache?: { type: string; value: T }[];
}

const instanceId = uuidgen().substring(0, 8);

export class RemoteEventsViewModel<TUserSession extends UserSessionContext = UserSessionContext> {
	// @ts-ignore
	@observable.ref private mConnectionPromise: Promise<void>;
	public eventLogger: Api.IEventLoggingService;
	// @ts-ignore
	private mStompClient: StompJs.Client;
	private mSubscriptions: IRemoteEventSubscriptionContext[];
	private mUserSession: TUserSession;
	private connectionUuid: string;
	private buildHash: string;
	private static DefaultReconnectDelay = 5000;
	private static LoggingCategory = 'RemoteEvents';

	constructor(userSession: TUserSession, eventLogger?: Api.IEventLoggingService) {
		// @ts-ignore
		this.eventLogger = eventLogger;
		this.mSubscriptions = [];
		this.mUserSession = userSession;
		const buildInfo = BuildInfo as IBuildInfo;
		this.buildHash = buildInfo.hash;
		this.connectionUuid = uuidgen().substring(0, 8);
	}

	public get userSession() {
		return this.mUserSession;
	}

	@computed
	public get connectionStatus() {
		return this.mStompClient.connected
			? Api.RemoteEventsConnectionStatus.Connected
			: Api.RemoteEventsConnectionStatus.Disconnected;
	}

	public connect = (stompConfig?: StompJs.StompConfig) => {
		if (!this.mConnectionPromise) {
			this.mConnectionPromise = new Promise<void>(resolveOnConnect => {
				this.mStompClient = new StompJs.Client();
				this.mStompClient.beforeConnect = () => {
					return new Promise(resolve => {
						// @ts-ignore
						this.configure().then((baseConfig: StompJs.StompConfig) => {
							const config: StompJs.StompConfig = {
								...baseConfig,
								...(stompConfig || {}),
								onConnect: () => {
									this.onConnect();
									// @ts-ignore
									this.mConnectionPromise = null;
									resolveOnConnect();
								},
							};
							if (!config.connectHeaders?.passcode) {
								this.logWebSocketError('AttemptedConnectWithoutPasscode');
								return;
							}
							this.mStompClient?.configure(config);
							resolve();
						});
					});
				};
				this.mStompClient.activate();
			});
		}
		return this.mConnectionPromise;
	};

	/** Note: After calling this, you cannot successfully re-connect. Create a new RemoteEventsViewModel if needed. */
	public permanentlyDisconnect = async () => {
		if (this.mStompClient) {
			try {
				this.mSubscriptions?.forEach(x => {
					x.stompSubscription?.unsubscribe();
					if (x.nextCallbackTimeoutHandle) {
						clearTimeout(x.nextCallbackTimeoutHandle);
						x.nextCallbackTimeoutHandle = null;
					}
					// @ts-ignore
					x.valueCache = null;
				});
				await this.mStompClient.deactivate();
			} catch (err) {
				//
			}
			this.mSubscriptions = [];
			// @ts-ignore
			this.mStompClient = null;
		}
	};

	public addEventHandler = <T = any>(
		route: string,
		callback: (e: Api.IRemoteEvent<T> | Api.IRemoteEvent<T>[]) => void,
		deliveryOptions?: Api.IRemoteEventsDeliveryOptions
	) => {
		const handlerId = uuidgen();
		const context: IRemoteEventSubscriptionContext<T> = {
			callback,
			deliveryOptions,
			id: handlerId,
			route,
		};
		const stompSubscription = this.subscribe(context);
		context.stompSubscription = stompSubscription;
		this.mSubscriptions.push(context);

		let disposed = false;
		return {
			dispose: () => {
				if (!disposed) {
					stompSubscription.unsubscribe();
					const index = this.mSubscriptions.findIndex(x => x.stompSubscription === stompSubscription);
					if (index >= 0) {
						this.mSubscriptions.splice(index, 1);
					}
					disposed = true;
				}
			},
			id: handlerId,
		};
	};

	private getAccessToken = async () => {
		// @ts-ignore
		const credential = await this.mUserSession.webServiceHelper.getCredentialStore().getCredential();
		if (
			!!credential?.access_token &&
			!!credential?.account_id &&
			credential.account_id === this.mUserSession.account?.id &&
			!!credential?.expires_utc &&
			moment(credential.expires_utc).isAfter(moment())
		) {
			return credential.access_token;
		}
		return null;
	};

	private configure = async () => {
		if (!this.mUserSession.user?.id) {
			return undefined;
		}
		const config: StompJs.StompConfig = {
			brokerURL: process.env.WS_URL,
			connectionTimeout: 30000,
			connectHeaders: {
				login: this.mUserSession.user.id,
				// @ts-ignore
				passcode: await this.getAccessToken(),
			},
			onDisconnect: VmUtils.Noop,
			onStompError: this.logWebSocketError('onStompError'),
			onUnhandledFrame: this.logWebSocketError('onUnhandledFrame'),
			onUnhandledMessage: this.logWebSocketError('onUnhandledMessage'),
			onUnhandledReceipt: this.logWebSocketError('onUnhandledReceipt'),
			onWebSocketClose: VmUtils.Noop,
			onWebSocketError: this.logWebSocketError('onWebSocketError'),
			reconnectDelay: RemoteEventsViewModel.DefaultReconnectDelay,
		};
		return config;
	};

	private logWebSocketError = (name: string) => (e?: Event | StompJs.IFrame | CloseEvent) => {
		if (!!e && !!this.eventLogger) {
			this.eventLogger.logEvent(
				{
					action: `Websocket-${name}-Error`,
					category: RemoteEventsViewModel.LoggingCategory,
				},
				{ event: e }
			);
		}
	};

	private subscribe = <T = any>(context: IRemoteEventSubscriptionContext<T>) => {
		// Order is important for the part components, the authorization check in the API expects the following structure:
		// The queue name MUST start with stomp
		// The significant parts must be separated by an _
		// The next part following stomp_ MUST be the user id.
		// @ts-ignore
		const queueSuffix = `${this.mUserSession.user.id}_${this.buildHash}_${instanceId}_${this.connectionUuid}`;
		return this.mStompClient.subscribe(
			`/topic/Account.${this.mUserSession.account.id}.${context.route}`,
			this.onMessage(context),
			{
				id: context.id,
				'x-queue-name': `stomp_${queueSuffix}`,
			}
		);
	};

	private onConnect = () => {
		// reconnect active subscriptions
		this.mSubscriptions.forEach(x => this.subscribe(x));
	};

	protected onMessage =
		<T = any>(context: IRemoteEventSubscriptionContext<T>) =>
		(message: StompJs.IFrame) => {
			if (!!message?.body && !!message.headers?.type) {
				const composeEvent = (e: { type: string; value: T }) => {
					const event: Api.IRemoteEvent<T> = {
						id: uuidgen(),
						target: {
							handlerId: context.id,
						},
						value: e.value,
						valueType: e.type,
					};
					return event;
				};
				// @ts-ignore
				if (context.deliveryOptions?.delay > 0) {
					const valueCache = context.deliveryOptions?.type === 'allSinceLastDelivery' ? context.valueCache || [] : [];
					valueCache.push({
						type: message.headers.type,
						value: JSON.parse(message.body),
					});
					context.valueCache = valueCache;

					if (!context.nextCallbackTimeoutHandle) {
						context.nextCallbackTimeoutHandle = setTimeout(() => {
							// @ts-ignore
							if (context.valueCache?.length > 0) {
								context.callback((context.valueCache || []).map(composeEvent));
							}
							// @ts-ignore
							context.valueCache = null;
							context.nextCallbackTimeoutHandle = null;
							// @ts-ignore
						}, context.deliveryOptions.delay);
					}
					return;
				}

				context.callback(
					composeEvent({
						type: message.headers.type,
						value: JSON.parse(message.body),
					})
				);
			}
		};
}
