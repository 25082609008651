import { computed, observable } from 'mobx';
import moment from 'moment';
import { isAdmin } from '../../sdk/Utils';
import * as Api from '../../sdk/models';
import { FilteredPageCollectionController, PagedViewModel } from '../Collections';
import { UserSessionContext, ViewModel } from '../index';

export abstract class SocialMediaViewModel extends ViewModel {
	@observable.ref protected mConnections: Api.ISocialMediaConnection[];
	// @ts-ignore
	@observable.ref protected mPageCollection: Api.ISocialMediaPageCollection;
	@observable.ref protected mModel?: Api.ISocialMediaPost;

	protected abstract getBasePath(): string;
	protected abstract getUserAccountPath(): string;
	public abstract getConnectionType(): Api.SocialMediaType;

	constructor(userSession: UserSessionContext) {
		super(userSession);
		// @ts-ignore
		this.mConnections = userSession?.user?.socialMediaConnectedAccounts?.filter(
			x => x.type === this.getConnectionType()
		);
	}

	@computed
	public get pages() {
		return this.mPageCollection?.pages;
	}

	@computed
	public get connection() {
		return this.mConnections?.length > 0 ? this.mConnections[0] : null;
	}

	@computed
	public get connections() {
		return this.mConnections;
	}

	@computed
	public get filterRequest() {
		return this.mModel?.filterRequest;
	}

	public async getAuthUrl() {
		if (this.busy) {
			return;
		}

		this.busy = true;
		const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<string>(
			this.composeApiUrl({ urlPath: `${this.getBasePath()}/oauth/authorizeUrl` }),
			'POST'
		);

		this.busy = false;

		if (!opResult.success) {
			throw opResult;
		}

		return opResult;
	}

	public disconnect = async (connection: Api.ISocialMediaConnection) => {
		if (this.busy) {
			return;
		}

		this.busy = true;
		const opResult = await this.userSession.webServiceHelper.callWebServiceAsync<Api.IUser>(
			this.composeApiUrl({ urlPath: `${this.getUserAccountPath()}` }),
			'DELETE',
			connection
		);
		this.busy = false;

		if (!opResult.success) {
			throw opResult;
		}

		// @ts-ignore
		// @ts-ignore
		if (this.userSession.user.id === opResult.value.id) {
			// @ts-ignore
			this.userSession.updateUser(opResult.value);
		}

		// @ts-ignore
		// @ts-ignore
		this.mConnections = opResult.value.socialMediaConnectedAccounts.filter(x => x.type === this.getConnectionType());

		return opResult;
	};

	public loadPages = async (connection?: Api.ISocialMediaConnection) => {
		if (this.busy) {
			return;
		}

		this.busy = true;
		const opResult = await this.userSession.webServiceHelper.callWebServiceAsync<Api.ISocialMediaPageCollection>(
			this.composeApiUrl({ urlPath: `${this.getBasePath()}/account` }),
			'POST',
			connection
		);
		this.busy = false;

		if (!opResult.success) {
			throw opResult;
		}

		// @ts-ignore
		this.mPageCollection = opResult.value;

		return opResult;
	};

	public setPostTargetPage = async (page: Api.ISocialMediaPage, connection: Api.ISocialMediaConnection) => {
		if (this.busy) {
			return;
		}

		const pageSelection: Api.ISocialMediaPageSelection = {
			pageId: page.id,
			pageName: page.name,
			userId: this.mPageCollection.accountId,
		};

		const request: Api.ISetPostTargetRequest = {
			connection,
			pageSelection,
		};

		this.busy = true;
		const opResult = await this.userSession.webServiceHelper.callWebServiceAsync<Api.IUser>(
			this.composeApiUrl({ urlPath: `${this.getUserAccountPath()}/postTargetPage` }),
			'PUT',
			request
		);
		this.busy = false;

		if (!opResult.success) {
			throw opResult;
		}

		// @ts-ignore
		// @ts-ignore
		if (this.userSession.user.id === opResult.value.id) {
			// @ts-ignore
			this.userSession.updateUser(opResult.value);
		}

		// @ts-ignore
		// @ts-ignore
		this.mConnections = opResult.value.socialMediaConnectedAccounts.filter(x => x.type === this.getConnectionType());

		return opResult;
	};

	public resetConnectionsToUsers(userSession: UserSessionContext, connection: Api.ISocialMediaConnection) {
		this.mConnections = userSession?.user?.socialMediaConnectedAccounts?.filter(
			x => x.type === this.getConnectionType() && x.postTargetId !== connection.postTargetId
		);
	}
}

export class FacebookViewModel extends SocialMediaViewModel {
	public getConnectionType() {
		return Api.SocialMediaType.Facebook;
	}

	protected getUserAccountPath(): string {
		return 'user/socialMedia/facebook';
	}

	protected getBasePath(): string {
		return 'social/facebook';
	}
}

export class InstagramViewModel extends SocialMediaViewModel {
	public getConnectionType() {
		return Api.SocialMediaType.Instagram;
	}

	protected getBasePath(): string {
		return 'social/instagram';
	}

	protected getUserAccountPath(): string {
		return 'user/socialMedia/instagram';
	}
}

export class LinkedInViewModel extends SocialMediaViewModel {
	public getConnectionType() {
		return Api.SocialMediaType.LinkedIn;
	}

	protected getBasePath(): string {
		return 'social/linkedin';
	}

	protected getUserAccountPath(): string {
		return 'user/socialMedia/linkedin';
	}
}

export class SocialMediaPostViewModel extends ViewModel {
	@observable.ref protected mSocialPost: Partial<Api.ISocialMediaPost>;

	public static schedulePost = async (
		userSession: UserSessionContext,
		postSocialMediaRequest: Api.ICreateSocialMediaPostRequest,
		suggestionId?: string,
		impersonationContext?: Api.IImpersonationContext
	) => {
		const opResult = await userSession.webServiceHelper.callWebServiceAsync<Api.ISocialMediaPost>(
			this.composeApiUrl({
				impersonationContext,
				queryParams: {
					contentCalendarSuggestionId: suggestionId,
					forUserId: postSocialMediaRequest?.forUserId,
				},
				urlPath: 'social/post',
			}),
			'POST',
			postSocialMediaRequest
		);

		if (!opResult.success) {
			throw opResult;
		}

		// @ts-ignore
		return new SocialMediaPostViewModel(userSession, opResult.value);
	};

	constructor(userSession: UserSessionContext, post: Partial<Api.ISocialMediaPost>) {
		super(userSession);
		this.mSocialPost = post;
	}

	@computed
	public get id() {
		return this.mSocialPost?.id;
	}

	@computed
	public get citations() {
		return this.mSocialPost?.citations;
	}

	@computed
	public get anonymousAccessLink() {
		return this.mSocialPost?.anonymousAccessLink;
	}

	@computed get approvalRequests() {
		return this.mSocialPost?.approvalRequests;
	}

	@computed get actor() {
		return this.mSocialPost?.actor;
	}

	@computed
	public get targets() {
		return this.mSocialPost?.targets;
	}

	@computed
	public get designatedTargets() {
		return this.mSocialPost?.designatedTargets;
	}

	@computed
	public get status() {
		return this.mSocialPost?.status;
	}

	@computed
	public get cancelledMoment() {
		return this.mSocialPost?.cancelledDate ? moment(this.mSocialPost.cancelledDate) : null;
	}

	@computed
	public get completedMoment() {
		return this.mSocialPost?.completedDate ? moment(this.mSocialPost.cancelledDate) : null;
	}

	@computed get creator() {
		return this.mSocialPost?.creator;
	}

	@computed
	public get dueDate() {
		return this.mSocialPost?.dueDate;
	}

	@computed
	public get lastModifiedDate() {
		return this.mSocialPost?.lastModifiedDate;
	}

	@computed
	public get attachments() {
		return this.mSocialPost?.attachments;
	}

	@computed
	public get content() {
		return this.mSocialPost?.content;
	}

	@computed
	public get dueMoment() {
		return this.mSocialPost?.dueDate ? moment(this.mSocialPost.dueDate) : null;
	}

	@computed
	public get hasEditableStatus() {
		return (
			this.mSocialPost?.status === Api.PostStatus.Scheduled ||
			this.mSocialPost.status === Api.PostStatus.Draft ||
			this.mSocialPost.status === Api.PostStatus.Pending
		);
	}

	@computed
	public get canEditAsAdmin() {
		return this.mSocialPost.status === Api.PostStatus.Pending && this.isAdmin;
	}

	/** Like this.hasEditableStatus but factors in creator */
	@computed
	public get canEdit() {
		// @ts-ignore
		const userIsAdmin = isAdmin(this.mUserSession.user);
		return (
			this.hasEditableStatus &&
			!this.mImpersonationContext &&
			(userIsAdmin || this.mUserSession?.user?.id === this.mSocialPost?.creator?.id)
		);
	}

	@computed
	public get name() {
		return this.mSocialPost?.name;
	}

	@computed
	public get sendWithCompliance() {
		return this.mSocialPost?.sendWithCompliance;
	}

	@computed
	public get templateReference() {
		return this.mSocialPost?.templateReference;
	}

	public toJs = () => {
		return this.mSocialPost as Api.ISocialMediaPost;
	};

	public load = async () => {
		if (this.busy) {
			return;
		}

		this.loading = true;
		const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.ISocialMediaPost>(
			this.composeApiUrl({ urlPath: `social/post/${this.id}` }),
			'GET'
		);
		this.loading = false;

		if (!opResult.success) {
			throw opResult;
		}

		// @ts-ignore
		this.mSocialPost = opResult.value;
		return opResult.value;
	};

	public update = async (postSocialMedia: Api.ISocialMediaPost) => {
		const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.ISocialMediaPost>(
			this.composeApiUrl({
				queryParams: {
					forUserId: postSocialMedia?.creator?.id,
				},
				urlPath: `social/post/${this.mSocialPost.id}`,
			}),
			'PUT',
			postSocialMedia
		);

		if (!opResult.success) {
			throw opResult;
		}

		// @ts-ignore
		this.mSocialPost = opResult.value;
		return opResult;
	};

	public cancel = async () => {
		const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.ISocialMediaPost>(
			this.composeApiUrl({ urlPath: `social/post/${this.id}` }),
			'DELETE'
		);
		if (!opResult.success) {
			throw opResult;
		}
		return opResult;
	};

	public approve = async () => {
		// @ts-ignore
		if (this.isBusy || this.mSocialPost.approvalRequests[0]?.response) {
			return;
		}

		this.busy = true;
		const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.ISocialMediaPost>(
			this.composeApiUrl({ urlPath: `social/post/${this.id}/approve` }),
			'POST'
		);
		this.busy = false;

		if (!opResult.success) {
			throw opResult;
		}
		return opResult;
	};

	public reject = async () => {
		// @ts-ignore
		if (this.isBusy || this.mSocialPost.approvalRequests[0]?.response) {
			return;
		}

		this.busy = true;
		const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.ISocialMediaPost>(
			this.composeApiUrl({ urlPath: `social/post/${this.id}/reject` }),
			'POST'
		);
		this.busy = false;

		if (!opResult.success) {
			throw opResult;
		}
		return opResult;
	};
}

export class SocialMediaPostReportViewModel extends ViewModel {
	@observable.ref protected mSocialMediaPostReport: Partial<Api.ISocialMediaPostReport>;

	constructor(userSession: UserSessionContext, postReport: Partial<Api.ISocialMediaPostReport>) {
		super(userSession);
		this.mSocialMediaPostReport = postReport;
	}

	@computed
	public get id() {
		return this.mSocialMediaPostReport?.id;
	}

	@computed
	public get anonymousAccessLink() {
		return this.mSocialMediaPostReport?.anonymousAccessLink;
	}

	@computed
	public get approvalRequests() {
		return this.mSocialMediaPostReport?.approvalRequests;
	}

	@computed
	public get name() {
		return this.mSocialMediaPostReport?.name;
	}

	@computed
	public get actor() {
		return this.mSocialMediaPostReport?.actor;
	}

	@computed
	public get targets() {
		return this.mSocialMediaPostReport?.targets;
	}

	@computed
	public get designatedTargets() {
		return this.mSocialMediaPostReport?.designatedTargets;
	}

	@computed
	public get status() {
		return this.mSocialMediaPostReport?.status;
	}

	@computed
	public get dueDate() {
		return this.mSocialMediaPostReport?.dueDate;
	}

	@computed
	public get creator() {
		return this.mSocialMediaPostReport?.creator;
	}

	@computed
	public get cancelledDate() {
		return this.mSocialMediaPostReport?.cancelledDate;
	}

	@computed
	public get completedDate() {
		return this.mSocialMediaPostReport?.completedDate;
	}

	@computed
	public get lastModifiedDate() {
		return this.mSocialMediaPostReport?.lastModifiedDate;
	}

	@computed
	public get hasEditableStatus() {
		return (
			this.mSocialMediaPostReport.status === Api.PostStatus.Scheduled ||
			this.mSocialMediaPostReport.status === Api.PostStatus.Draft ||
			this.mSocialMediaPostReport.status === Api.PostStatus.Pending
		);
	}

	@computed
	public get adminEditStatus() {
		return (
			this.mSocialMediaPostReport.status === Api.PostStatus.Pending &&
			(this.mUserSession?.user?.role === 'admin' || this.mUserSession?.user?.role === 'superAdmin')
		);
	}

	/** Like this.hasEditableStatus but factors in creator when not impersonating */
	@computed
	public get canEdit() {
		// @ts-ignore
		const userIsAdmin = isAdmin(this.mUserSession.user);
		return (
			this.hasEditableStatus &&
			!this.mImpersonationContext &&
			(userIsAdmin || this.mUserSession?.user?.id === this.mSocialMediaPostReport?.creator?.id)
		);
	}

	public toJs = () => {
		return this.mSocialMediaPostReport;
	};

	public setPostReport = (report: Api.ISocialMediaPostReport) => {
		this.mSocialMediaPostReport = report;
	};

	public loadPost = async () => {
		if (this.busy) {
			return;
		}

		this.busy = true;
		const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.ISocialMediaPost>(
			this.composeApiUrl({ urlPath: `social/post/${this.id}` }),
			'GET'
		);
		this.busy = false;

		if (!opResult.success) {
			throw opResult;
		}

		return opResult.value;
	};

	public load = async () => {
		if (this.busy) {
			return;
		}
		this.busy = true;

		const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.ISocialMediaPostReport>(
			this.composeApiUrl({ urlPath: `reports/post/${this.id}` }),
			'GET'
		);
		this.busy = false;

		if (!opResult.success) {
			throw opResult;
		}
		// @ts-ignore
		this.mSocialMediaPostReport = opResult.value;
		return opResult.value;
	};
}

export class SocialMediaPostsReportingViewModel extends PagedViewModel<
	Api.ISocialMediaPostReport,
	SocialMediaPostReportViewModel,
	Api.ISocialMediaReportRequest
> {
	// @ts-ignore
	@observable protected mSelectedUserId: string = undefined;
	@observable protected mSelectedPostStatus: Api.PostStatus[];
	@observable public pageSize: number;
	@observable.ref public dateRange: { startDate?: Date; endDate?: Date };
	// @ts-ignore
	@observable.ref public resourceSelectorId: string;
	@observable
	public mPagedCollectionController: FilteredPageCollectionController<
		Api.ISocialMediaPostReport,
		SocialMediaPostReportViewModel,
		Api.ISocialMediaReportRequest
	>;

	constructor(userSession: UserSessionContext, user?: Partial<Api.IUser>) {
		super(userSession);
		this.reset = this.reset.bind(this);

		// @ts-ignore
		// @ts-ignore
		this.mSelectedUserId = user?.id || (this.isAdmin ? undefined : userSession.user.id);
		// @ts-ignore
		this.mSelectedPostStatus = null;
		this.mSocialMediaPostTransformer = this.mSocialMediaPostTransformer.bind(this);
		this.pageSize = 25;

		this.mPagedCollectionController = new FilteredPageCollectionController<
			Api.ISocialMediaPostReport,
			SocialMediaPostReportViewModel,
			Api.ISocialMediaReportRequest
		>({
			apiPath: this.apiPathBase,
			client: this.mUserSession.webServiceHelper,
			itemUniqueIdentifierPropertyPath: 'id',
			transformer: this.mSocialMediaPostTransformer,
		});

		this.dateRange = {
			endDate: moment().add(180, 'days').endOf('day').toDate(),
			startDate: moment().subtract(180, 'days').startOf('day').toDate(),
		};
	}

	@computed
	public get isBusy() {
		return this.mPagedCollectionController.isFetching;
	}

	@computed
	public get request(): Api.ISocialMediaReportRequest {
		const request = {
			endDate: this.dateRange?.endDate,
			startDate: this.dateRange?.startDate,
			status: this.mSelectedPostStatus,
			userId: this.mSelectedUserId,
		};

		return request;
	}

	protected mSocialMediaPostTransformer(post: Api.ISocialMediaPostReport) {
		return new SocialMediaPostReportViewModel(this.userSession, post).impersonate(this.mImpersonationContext);
	}

	public readonly apiPathBase = 'reports/post';

	public setSelectedUserId = (userId: string) => {
		this.mSelectedUserId = userId;
		return this;
	};

	public setDateRange = (range: { startDate?: Date; endDate?: Date }) => {
		this.dateRange = range;
		return this;
	};

	public setSelectedPostStatus = (postStatus: Api.PostStatus[]) => {
		// @ts-ignore
		this.mSelectedPostStatus = postStatus?.length > 0 ? postStatus : null;
		return this;
	};

	public setPageSize = (size: number) => {
		this.pageSize = size;
		return this;
	};

	public impersonate(impersonationContext?: Api.IImpersonationContext) {
		super.impersonate(impersonationContext);
		this.mPagedCollectionController.impersonate(impersonationContext);
		return this;
	}

	public reset(): void {
		super.reset();
		// @ts-ignore
		this.mSelectedUserId = null;
		this.mSelectedPostStatus = [];
	}
}
