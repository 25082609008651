import { action, computed, observable } from 'mobx';
import * as Api from '../../sdk';
import { ObservablePageCollectionController } from '../Collections';
import { AttachmentsToBeUploadedViewModel } from '../Files';
import { AccountViewModel, UserSessionContext, UserViewModel, ViewModel } from '../index';

export class TemplatesViewModel extends Api.ImpersonationBroker {
	public static GetIndustryTemplates = <TTemplate extends Api.ITemplate = Api.ITemplate>(
		userSession: UserSessionContext,
		industry?: Api.Industry,
		types?: Api.TemplateType[],
		impersonationContext?: Api.IImpersonationContext
	) => {
		return new Promise<Api.IOperationResult<TTemplate[]>>((resolve, reject) => {
			const onFinish = (opResult: Api.IOperationResult<TTemplate[]>) => {
				if (opResult.success) {
					resolve(opResult);
				} else {
					reject(opResult);
				}
			};

			const params: Record<string, any> = {
				industry: industry || userSession.account?.toJs().additionalInfo?.industry || Api.Industry.Miscellaneous,
			};

			// @ts-ignore
			if (types?.length > 0) {
				params.types = types;
			}

			userSession.webServiceHelper.callWebServiceWithOperationResults<TTemplate[]>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					queryParams: params,
					urlPath: 'template/system',
				}),
				'GET',
				null,
				onFinish,
				onFinish
			);
		});
	};
	public static getKeyDateTemplate = (
		userSession: UserSessionContext,
		kind: Api.KeyDateKind,
		industry?: Api.Industry,
		impersonationContext?: Api.IImpersonationContext
	) => {
		return new Promise<Api.IOperationResult<Api.ITemplate>>((resolve, reject) => {
			const onFinish = (opResult: Api.IOperationResult<Api.ITemplate>) => {
				if (opResult.success) {
					resolve(opResult);
				} else {
					reject(opResult);
				}
			};
			userSession.webServiceHelper.callWebServiceWithOperationResults<Api.ITemplate>(
				Api.ImpersonationBroker.composeApiUrl({
					impersonationContext,
					queryParams: {
						industry: industry || userSession.account?.toJs().additionalInfo?.industry || Api.Industry.Miscellaneous,
						kind,
					},
					urlPath: 'template/byKeyDate',
				}),
				'GET',
				null,
				onFinish,
				onFinish
			);
		});
	};

	/** Fetch the main template for the user for this given resource selector */
	public static getResourceSelectorTemplate = (
		userSession: UserSessionContext,
		resourceSelector: Api.ResourceSelectorId,
		industry?: Api.Industry,
		excludeExpired = true
	) => {
		// Can't allow excludeExpired to be true when selecting the default template -- if the default template is expired the rest of the call crashes
		return new Promise<Api.IOperationResult<Api.ITemplate>>((resolve, reject) => {
			const onFinish = (opResult: Api.IOperationResult<Api.ITemplate>) => {
				if (opResult.success) {
					resolve(opResult);
				} else {
					reject(opResult);
				}
			};
			const selectedIndustry =
				industry || userSession.account?.toJs().additionalInfo?.industry || Api.Industry.Miscellaneous;
			userSession.webServiceHelper.callWebServiceWithOperationResults<Api.ITemplate>(
				Api.ImpersonationBroker.composeApiUrl({
					queryParams: {
						excludeExpired,
						industry: selectedIndustry,
					},
					urlPath: `template/resourceSelector/${resourceSelector}/default`,
				}),
				'GET',
				null,
				onFinish,
				onFinish
			);
		});
	};
	public static userHasTemplateForKeyDateKind = (userSession: UserSessionContext, kind: Api.KeyDateKind): boolean => {
		const templatesForKeyDate = userSession?.user?.userPreferences?.templatePreferences;
		return (
			!!kind &&
			((kind === Api.KeyDateKind.Birthday && !!templatesForKeyDate?.birthdayTemplateId) ||
				(kind === Api.KeyDateKind.Anniversary && !!templatesForKeyDate?.anniversaryTemplateId) ||
				(kind === Api.KeyDateKind.Renewal && !!templatesForKeyDate?.renewalTemplateId) ||
				(kind === Api.KeyDateKind.FinancialReview && !!templatesForKeyDate?.reviewTemplateId))
		);
	};
	@observable.ref
	private mSignatureTemplatesController: ObservablePageCollectionController<Api.ITemplate, Api.ITemplate>;
	@observable.ref
	private mEmailTemplatesController: ObservablePageCollectionController<Api.ITemplate, Api.ITemplate>;
	@observable.ref
	private mPersonalBusinessTemplatesController: ObservablePageCollectionController<Api.ITemplate, Api.ITemplate>;
	@observable.ref
	private mUnsubscribeInstructionsTemplatesController: ObservablePageCollectionController<Api.ITemplate, Api.ITemplate>;
	private mUserSession: UserSessionContext;

	constructor(userSession: UserSessionContext, viewModelParams?: { userId?: string; forUserId?: string }) {
		super();
		const { userId, forUserId } = viewModelParams || {};
		this.getById = this.getById.bind(this);
		this.mUserSession = userSession;
		this.mEmailTemplatesController = new ObservablePageCollectionController<Api.ITemplate, Api.ITemplate>({
			apiParams: { type: Api.TemplateType.Email },
			apiPath: 'template/byType',
			client: this.mUserSession.webServiceHelper,
		});
		this.mSignatureTemplatesController = new ObservablePageCollectionController<Api.ITemplate, Api.ITemplate>({
			apiParams: { forUserId, type: Api.TemplateType.Signature, userId },
			apiPath: 'template/byType',
			client: this.mUserSession.webServiceHelper,
		});
		this.mPersonalBusinessTemplatesController = new ObservablePageCollectionController<Api.ITemplate, Api.ITemplate>({
			apiParams: { type: Api.TemplateType.PersonalBusinessUpdate },
			apiPath: 'template/byType',
			client: this.mUserSession.webServiceHelper,
		});
		this.mUnsubscribeInstructionsTemplatesController = new ObservablePageCollectionController<
			Api.ITemplate,
			Api.ITemplate
		>({
			apiParams: { type: Api.TemplateType.UnsubscribeInstructions },
			apiPath: 'template/byType',
			client: this.mUserSession.webServiceHelper,
		});
	}

	@computed
	public get signatureTemplates() {
		return this.mSignatureTemplatesController;
	}

	@computed
	public get emailTemplates() {
		return this.mEmailTemplatesController;
	}

	@computed
	public get personalBusinessUpdateTemplates() {
		return this.mPersonalBusinessTemplatesController;
	}

	@computed
	public get unsubscribeInstructionsTemplates() {
		return this.mUnsubscribeInstructionsTemplatesController;
	}

	@action
	public reset() {
		this.mSignatureTemplatesController.reset();
		this.mEmailTemplatesController.reset();
		this.mPersonalBusinessTemplatesController.reset();
		this.mUnsubscribeInstructionsTemplatesController.reset();
	}

	public impersonate(impersonationContext?: Api.IImpersonationContext) {
		super.impersonate(impersonationContext);
		[
			this.mSignatureTemplatesController,
			this.mEmailTemplatesController,
			this.mPersonalBusinessTemplatesController,
			this.mUnsubscribeInstructionsTemplatesController,
		].forEach(x => {
			x?.impersonate(impersonationContext);
		});
		return this;
	}

	public getById(id: string) {
		return this.templateCrudRequest({ id }, 'GET');
	}

	public getByIdExpandedByLastUsedBy(id: string) {
		return this.templateCrudRequest({ id }, 'GET', { expand: 'LastUsedByDate' });
	}

	public create = (template: Api.ITemplate, forUserId?: string) => {
		return this.templateCrudRequest(template, 'POST', { forUserId });
	};

	public update = (template: Api.ITemplate, forUserId?: string) => {
		return this.templateCrudRequest(template, 'PATCH', { forUserId });
	};

	public delete = (template: Api.ITemplate) => {
		return this.templateCrudRequest(template, 'DELETE');
	};

	private templateCrudRequest = (
		template: Api.ITemplate,
		method: 'POST' | 'GET' | 'PATCH' | 'DELETE',
		queryParams?: Api.IDictionary<any>
	) => {
		return new Promise<Api.ITemplate>((resolve, reject) => {
			const onFinish = (result: Api.IOperationResult<Api.ITemplate>) => {
				if (result.success) {
					const templateType = template.templateType || (result.value ? result.value.templateType : null);
					if (templateType !== null && templateType !== undefined) {
						const controller =
							templateType === Api.TemplateType.Email
								? this.mEmailTemplatesController
								: this.mSignatureTemplatesController;
						switch (method) {
							case 'DELETE': {
								// remove the matching template
								controller.fetchResults.removeItems([template]);
								break;
							}
							case 'PATCH': {
								if (!!template.id && !!result.value && !!result.value.id) {
									const index = controller.fetchResults.indexOf(result.value);
									if (index >= 0) {
										// replace the matching template
										controller.fetchResults.splice(index, 1, result.value);
									} else {
										// add it at the top of the list
										controller.fetchResults.splice(0, 0, result.value);
									}
								}

								break;
							}
							case 'POST': {
								if (!!result.value && !!result.value.id && !controller.fetchResults.has(result.value)) {
									// add it at the top of the list
									controller.fetchResults.splice(0, 0, result.value);
								}
								break;
							}
							default: {
								break;
							}
						}
					}
					// @ts-ignore
					resolve(result.value);
				} else {
					reject(result);
				}
			};

			this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.ITemplate>(
				this.composeApiUrl({
					queryParams,
					urlPath: `template${method !== 'POST' ? `/${template.id}` : ''}`,
				}),
				method,
				method === 'GET' ? null : template,
				onFinish,
				onFinish
			);
		});
	};

	public addAttachment = (template: Api.ITemplate, attachments: AttachmentsToBeUploadedViewModel<File>) => {
		return new Promise<Api.ITemplate>((resolve, reject) => {
			const onFinish = (result: Api.IOperationResult<Api.ITemplate>) => {
				if (result.success) {
					// @ts-ignore
					resolve(result.value);
				} else {
					reject(result);
				}
			};

			const formData = new FormData();
			attachments.attachments.forEach(x => formData.append('files', x));
			this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.ITemplate>(
				this.composeApiUrl({ urlPath: `template/${template.id}/attachment` }),
				'POST',
				formData,
				onFinish,
				onFinish
			);
		});
	};

	public removeAttachments = (template: Api.ITemplate, attachmentIds: string[]) => {
		return new Promise<Api.ITemplate>((resolve, reject) => {
			const onFinish = (result: Api.IOperationResult<Api.ITemplate>) => {
				if (result.success) {
					// @ts-ignore
					resolve(result.value);
				} else {
					reject(result);
				}
			};

			this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.ITemplate>(
				this.composeApiUrl({ urlPath: `template/${template.id}/attachments` }),
				'DELETE',
				attachmentIds,
				onFinish,
				onFinish
			);
		});
	};
}

export class UnsubscribeTemplateViewModel extends ViewModel {
	// @ts-ignore
	@observable private mAccountTemplate: Api.ITemplate;
	// @ts-ignore
	@observable private mUserTemplate: Api.ITemplate;
	// @ts-ignore
	@observable private mHtmlNewsletterTemplate: Api.ITemplate;
	@observable private mTemplates: TemplatesViewModel;
	@observable private mAccount: AccountViewModel;
	@observable private mUser: UserViewModel;
	constructor(userSession: UserSessionContext, account?: Api.IAccount, user?: Api.IUser) {
		super(userSession);
		this.mTemplates = new TemplatesViewModel(userSession);
		this.mAccount = account ? new AccountViewModel(userSession, account) : userSession.account;
		// @ts-ignore
		this.mUser = new UserViewModel(userSession, user || userSession.user);
	}

	@computed
	public get accountTemplate() {
		return this.mAccountTemplate;
	}

	@computed
	public get userTemplate() {
		return this.mUserTemplate;
	}

	@computed
	public get htmlNewsletterTemplate() {
		return this.mHtmlNewsletterTemplate;
	}

	@action
	public loadAccountTemplate() {
		const accountTemplateId = this.mAccount?.preferences?.unsubscribeTemplateId;
		if (accountTemplateId) {
			return this.mTemplates.getById(accountTemplateId)?.then(template => {
				this.mAccountTemplate = template;
			});
		}
		return null;
	}

	@action
	public loadUserTemplate() {
		const userTemplateId = this.mUser?.preferences?.unsubscribeTemplateId;
		if (userTemplateId) {
			return this.mTemplates.getById(userTemplateId)?.then(template => {
				this.mUserTemplate = template;
			});
		}
		return null;
	}

	@action
	public loadHtmlNewsletterTemplate() {
		const htmlNewsletterTemplateId = this.mAccount?.features?.htmlNewsletter?.unsubscribeTemplateId;
		if (htmlNewsletterTemplateId) {
			return this.mTemplates.getById(htmlNewsletterTemplateId)?.then(template => {
				this.mHtmlNewsletterTemplate = template;
			});
		}
		return null;
	}

	@action
	public async saveTemplate(content: Api.IRawRichTextContentState, canUpdateAccountTemplate?: boolean) {
		try {
			if (canUpdateAccountTemplate) {
				if (!!this.accountTemplate && this.accountTemplate.scope === Api.TemplateScope.Account) {
					const template = await this.mTemplates.update({
						...this.accountTemplate,
						content,
						scope: Api.TemplateScope.Account,
					});
					this.mAccountTemplate = template;
				} else {
					const template = await this.mTemplates.create({
						content,
						scope: Api.TemplateScope.Account,
						templateType: Api.TemplateType.UnsubscribeInstructions,
					});
					this.mAccountTemplate = template;
					// @ts-ignore
					await this.mAccount.updateUnsubscribeTemplateId(template.id);
				}
			} else {
				if (!!this.userTemplate && this.userTemplate.scope === Api.TemplateScope.User) {
					const template = await this.mTemplates.update({
						...this.userTemplate,
						content,
						scope: Api.TemplateScope.User,
					});
					this.mUserTemplate = template;
					// @ts-ignore
					await this.mUser.updateUnsubscribeTemplateId(template.id);
				} else {
					const template = await this.mTemplates.create({
						content,
						scope: Api.TemplateScope.User,
						templateType: Api.TemplateType.UnsubscribeInstructions,
					});
					this.mUserTemplate = template;
					// @ts-ignore
					await this.mUser.updateUnsubscribeTemplateId(template.id);
				}
			}
		} catch (error) {
			return error;
		}
	}

	@action
	public async saveHtmlNewsletterTemplate(content: Api.IRawRichTextContentState) {
		try {
			const template = await this.mTemplates.create({
				content,
				scope: Api.TemplateScope.Account,
				templateType: Api.TemplateType.UnsubscribeFromNewsletter,
			});
			this.mHtmlNewsletterTemplate = template;
			return template;
		} catch (error) {
			return error;
		}
	}

	public impersonate(impersonationContext?: Api.IImpersonationContext) {
		super.impersonate(impersonationContext);
		this.mTemplates.impersonate(impersonationContext);
		this.mAccount = impersonationContext?.account
			? new AccountViewModel(this.mUserSession, impersonationContext.account)
			: this.mUserSession.account;
		// @ts-ignore
		this.mUser = new UserViewModel(this.mUserSession, impersonationContext?.user || this.mUserSession.user);
		return this;
	}
}
