import { action, computed, observable, runInAction } from 'mobx';
import { IPixabayImage } from '../../../models/Pixabay';
import * as Api from '../../sdk';
import { ObservableCollection } from '../Collections';
import { UserSessionContext, ViewModel } from '../index';

export type FileWithExtensionsType = File & { url?: string };

export class AttachmentsToBeUploadedViewModel<TAttachmentType extends Blob = Blob> {
	@observable.ref private mAttachments: TAttachmentType[];
	@observable private mMaxByteCount: number;

	constructor(attachments?: TAttachmentType[], maxByteCount = 6 * 1024 * 1024) {
		this.mAttachments = attachments || [];
		this.mMaxByteCount = maxByteCount;
	}

	@computed
	public get hasExceededMaxByteCount() {
		return this.byteCount > this.maxByteCount;
	}

	@computed
	public get byteCount() {
		return this.mAttachments.reduce((result, x) => result + x.size, 0);
	}

	@computed
	public get maxByteCount() {
		return this.mMaxByteCount;
	}

	@computed
	public get attachments() {
		return this.mAttachments;
	}

	@computed
	public get count() {
		return this.attachments?.length ?? 0;
	}

	@computed
	public get attachmentsWithMetadata() {
		let runningByteCount = 0;
		return this.mAttachments.map(x => {
			runningByteCount = runningByteCount + x.size;
			return {
				exceedsMaxByteCount: runningByteCount > this.mMaxByteCount,
				file: x,
			};
		});
	}

	@action
	public remove = (attachments: TAttachmentType[]) => {
		const nextFiles = [...this.mAttachments];

		let fileRemoved = false;
		(attachments || []).forEach(x => {
			const index = nextFiles.indexOf(x);
			if (index > -1) {
				fileRemoved = true;
				nextFiles.splice(index, 1);
			}
		});

		if (fileRemoved) {
			this.mAttachments = nextFiles;
		}
	};

	public add = (attachments: TAttachmentType[]) => {
		const nextAttachments = [...this.mAttachments];
		const duplicates: TAttachmentType[] = [];

		let attachmentAdded = false;
		(attachments || []).forEach(x => {
			if (x instanceof File) {
				const matchingFile = nextAttachments.find(y => {
					if (y instanceof File) {
						return y.name.toLowerCase() === x.name.toLowerCase();
					}
					return false;
				});
				if (!matchingFile) {
					attachmentAdded = true;
					nextAttachments.push(x);
				} else {
					duplicates.push(x);
				}
			} else {
				if (!nextAttachments.find(y => y === x)) {
					attachmentAdded = true;
					nextAttachments.push(x);
				} else {
					duplicates.push(x);
				}
			}
		});

		if (attachmentAdded) {
			this.mAttachments = nextAttachments;
		}

		return duplicates;
	};
}

export class FileAttachmentViewModel extends ViewModel {
	public static create(
		userSession: UserSessionContext,
		resourceBaseApiPath: string,
		attachments: Api.IFileAttachment[]
	): FileAttachmentViewModel[] {
		return !attachments ? [] : attachments.map(a => new FileAttachmentViewModel(userSession, resourceBaseApiPath, a));
	}

	private mModel: Api.IFileAttachment;
	private mResourceBaseApiPath: string;

	constructor(userSession: UserSessionContext, resourceBaseApiPath: string, model: Api.IFileAttachment) {
		super(userSession);
		this.mModel = model;
		this.mResourceBaseApiPath = resourceBaseApiPath;
	}

	public get id() {
		return this.mModel.id;
	}

	public get fileName() {
		return this.mModel.fileName;
	}

	public get fileSize() {
		return this.mModel.fileSize;
	}

	public get embedded() {
		return this.mModel.embedded;
	}

	public get contentType(): 'doc' | 'pdf' | 'image' | 'other' {
		const mimeType = this.mModel.mimeType;
		if (mimeType) {
			if (mimeType.startsWith('image')) {
				return 'image';
			}

			switch (mimeType) {
				case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
				case 'application/msword':
					return 'doc';

				case 'application/pdf':
					return 'pdf';

				default:
					break;
			}
		}

		return 'other';
	}

	public getDownloadInfo(): Promise<Api.IFileAttachment> {
		return new Promise((resolve, reject) => {
			runInAction(() => {
				this.loading = true;
			});

			const onFinish = (opResult: Api.IOperationResult<Api.IFileAttachment>) => {
				runInAction(() => {
					this.loading = false;
					if (opResult.success) {
						// @ts-ignore
						resolve(opResult.value);
					} else {
						reject(opResult);
					}
				});
			};

			return this.mUserSession.webServiceHelper.callWebServiceWithOperationResults<Api.IFileAttachment>(
				`${this.mResourceBaseApiPath}/attachment/${this.id}`,
				'GET',
				undefined,
				onFinish,
				onFinish
			);
		});
	}
}

export class AttachmentsViewModel<TFile extends Blob = Blob> extends ViewModel {
	public readonly images = new ObservableCollection<Api.IFileAttachment>([], 'id');
	constructor(userSession: UserSessionContext) {
		super(userSession);
	}

	public uploadImages = (files: TFile[], resizeForSocialMedia = false) => {
		if (!this.isBusy) {
			this.busy = true;
			const formData: FormData = new FormData();
			files.forEach(file => formData.append('files', file));

			return new Promise<Api.IBulkOperationResult<Api.IFileAttachment>>((resolve, reject) => {
				const onFinish = action((opResult: Api.IBulkOperationResult<Api.IFileAttachment>) => {
					this.busy = false;
					// @ts-ignore
					this.images.addAll(opResult.succeeded);
					if (opResult.success) {
						resolve(opResult);
					} else {
						reject(opResult);
					}
				});
				this.mUserSession.webServiceHelper.callWebServiceWithBulkOperationResult<Api.IFileAttachment>(
					this.composeApiUrl({ queryParams: { resizeForSocialMedia }, urlPath: `attachment/image` }),
					'POST',
					formData,
					onFinish,
					onFinish
				);
			});
		}
	};

	public getUpdateForSelectedImage = (image: IPixabayImage): string => {
		const name = image.previewURL.split('/').pop();
		const fileName = name.split('_').shift();
		return fileName;
	};

	public uploadImageByUrl = async (url: string, fileName?: string) => {
		if (!this.isBusy) {
			this.busy = true;
			const request: Api.IAttachmentFromExternalImageRequest = { fileName, url };

			const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<Api.IFileAttachment>(
				this.composeApiUrl({ urlPath: 'attachment/externalImage' }),
				'POST',
				request
			);

			this.busy = false;
			if (!opResult.success) {
				throw opResult;
			}

			// @ts-ignore
			this.images.add(opResult.value);
			return opResult;
		}
	};

	public delete = async (fileAttachment: Api.IFileAttachment) => {
		this.busy = true;
		const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync(
			this.composeApiUrl({ urlPath: `attachment/${fileAttachment.id}` }),
			'DELETE'
		);

		this.busy = false;
		if (!opResult.success) {
			throw opResult;
		}

		this.images.removeItems([fileAttachment]);
		return opResult;
	};
}
