import Bluebird from 'bluebird';
import equal from 'fast-deep-equal';
import { action, computed, observable, runInAction } from 'mobx';
import { removeEmptyKvpFromObject } from '../../Utils';
import * as Api from '../../sdk';
import { BaseViewModel, UserSessionContext, ViewModel } from '../index';

export interface IBaseObservablePageCollectionControllerOptions<
	TModel extends object = any,
	TItem extends object = TModel,
> {
	apiParams?: Api.IDictionary;
	apiPath: string | (() => string);
	client: Api.WebServiceHelper;
	httpMethod?: Api.HTTPMethod;
	/** Transformed item unique property path. Must point to a property path that evaluates to a string. (Optional) */
	itemUniqueIdentifierPropertyPath?: string;
	transformer?(model: TModel): TItem;
}

export class BaseObservablePageCollectionController<
	TModel extends object = any,
	TItem extends object = TModel,
	TContext = any,
> extends Api.ImpersonationBroker {
	// @ts-ignore
	@observable protected fetchedFirstPage: boolean;
	// @ts-ignore
	@observable protected fetching: boolean;
	// @ts-ignore
	@observable protected mFetchComplete: boolean;
	// @ts-ignore
	@observable protected mTotalCount: number;
	// @ts-ignore
	@observable.ref protected mFetchContext: TContext;
	@observable.ref protected mFetchResults: ObservableCollection<TItem>;
	// @ts-ignore
	@observable.ref protected mNextContext: TContext;
	protected mApiParams: Api.IDictionary;
	protected mApiPath: string | (() => string);
	protected mClient: Api.WebServiceHelper;
	// @ts-ignore
	protected mFetchParams: Api.IDictionary;
	// @ts-ignore
	protected mFetchPromise: Bluebird<Api.IPageCollectionControllerFetchResult<TModel[]>>;
	// @ts-ignore
	protected mPageSize: number;
	// @ts-ignore
	protected mPageToken: string;
	protected mHttpMethod: Api.HTTPMethod;
	protected mTransformer: (model: TModel) => TItem;

	constructor(options: IBaseObservablePageCollectionControllerOptions) {
		super();
		// @ts-ignore
		this.mApiParams = options.apiParams;
		this.mApiPath = options.apiPath;
		this.mClient = options.client;
		this.mHttpMethod = options.httpMethod || 'POST';

		const itemUniqueIdentifierPropertyPath = Object.prototype.hasOwnProperty.call(
			options,
			'itemUniqueIdentifierPropertyPath'
		)
			? options.itemUniqueIdentifierPropertyPath
			: 'id';

		// @ts-ignore
		this.mFetchResults = new ObservableCollection<TItem>(null, itemUniqueIdentifierPropertyPath);
		this.mTransformer =
			options.transformer ||
			(model => {
				return model as any;
			});

		this.getNext = this.getNext.bind(this);
		this.fetchIsEqualToPreviousFetch = this.fetchIsEqualToPreviousFetch.bind(this);
		this.hasAllPages = this.hasAllPages.bind(this);
		this.reset = this.reset.bind(this);
		this.executeFetch = this.executeFetch.bind(this);
		this.reset();
	}

	@computed
	public get hasContext() {
		return !!this.mFetchContext || !!this.mNextContext;
	}

	@computed
	public get hasFetchedFirstPage() {
		return this.fetchedFirstPage;
	}

	@computed
	public get hasFetchedAllPages() {
		return this.mFetchComplete;
	}

	@computed
	public get isFetching() {
		return this.fetching;
	}

	@computed
	public get totalCount() {
		return this.mTotalCount;
	}

	@computed
	public get fetchResults() {
		return this.mFetchResults;
	}

	@action
	public setApiPath = (path: string) => {
		this.mApiPath = path;
	};

	@action
	public setClient = (client: Api.WebServiceHelper) => {
		this.mClient = client;
	};

	public get transformer() {
		return this.mTransformer;
	}

	@action
	public setTransformer = (transformer: (model: TModel) => TItem) => {
		this.mTransformer = transformer;
	};

	public impersonate(impersonationContext?: Api.IImpersonationContext) {
		super.impersonate(impersonationContext);
		this.fetchResults?.forEach(x => {
			if (x instanceof Api.ImpersonationBroker) {
				x.impersonate(impersonationContext);
			}
		});
		return this;
	}

	@action
	public reset() {
		this.cancelCurrentFetch();
		this.fetchedFirstPage = false;
		this.fetching = false;
		this.mFetchComplete = false;
		// @ts-ignore
		this.mFetchContext = null;
		// @ts-ignore
		this.mFetchParams = null;
		this.mFetchResults.clear();
		// @ts-ignore
		this.mNextContext = null;
		this.mPageSize = 25;
		// @ts-ignore
		this.mPageToken = null;
		this.mTotalCount = 0;
	}

	@action
	public getNext(context?: TContext, pageSize?: number, params?: Api.IDictionary) {
		const nextPageSize = (pageSize !== null && pageSize !== undefined && pageSize) || this.mPageSize;
		const isFetchEqualToPreviousFetch = this.fetchIsEqualToPreviousFetch(context, nextPageSize, params);
		if (isFetchEqualToPreviousFetch) {
			if (this.mFetchPromise) {
				return this.mFetchPromise;
			}

			if (this.mFetchComplete) {
				return Bluebird.resolve({
					fetchedFirstPage: false,
					values: [] as TModel[],
				});
			}
		}
		// @ts-ignore
		this.mFetchContext = context;
		// @ts-ignore
		this.mFetchParams = params;
		this.mPageSize = pageSize || this.mPageSize;
		this.cancelCurrentFetch();

		// use/clear page token and fetch results state if needed
		let pageToken = this.mPageToken;
		if (!isFetchEqualToPreviousFetch) {
			// @ts-ignore
			pageToken = null;
			this.fetchedFirstPage = false;
			this.mFetchComplete = false;
			this.fetchResults.clear();
		}

		this.fetching = true;
		// @ts-ignore
		this.mNextContext = context;
		this.mFetchPromise = new Bluebird<Api.IPageCollectionControllerFetchResult<TModel[]>>(
			(resolve, reject, onCancel) => {
				let canceled = false;
				if (onCancel) {
					onCancel(() => {
						canceled = true;
					});
				}

				const onFinish = (opResult: Api.IOperationResult<Api.IPagedCollection<TModel>>) => {
					runInAction(() => {
						this.fetching = false;
						// @ts-ignore
						this.mFetchPromise = null;
						if (!canceled) {
							if (opResult.success) {
								// @ts-ignore
								this.mFetchComplete = !opResult.value.pageToken;
								// @ts-ignore
								// @ts-ignore
								this.mPageToken = opResult.value.pageToken;
								// @ts-ignore
								// @ts-ignore
								this.mTotalCount = opResult.value.totalCount;
								if (!isFetchEqualToPreviousFetch || !this.fetchedFirstPage) {
									this.mFetchResults.clear();
								}
								// @ts-ignore
								this.mFetchResults.addAll((opResult.value.values || []).map(this.mTransformer));
								const result: Api.IPageCollectionControllerFetchResult<TModel[]> = {
									fetchedFirstPage: !this.fetchedFirstPage || !!isFetchEqualToPreviousFetch,
									// @ts-ignore
									values: opResult.value.values,
								};
								// must be set after creating result
								this.fetchedFirstPage = true;

								resolve(result);
							} else {
								reject(opResult);
							}
						}
					});
				};

				this.executeFetch(context, nextPageSize, { ...(params || {}), pageToken })
					.then(onFinish)
					.catch(onFinish);
			}
		);
		return this.mFetchPromise;
	}

	@action
	public cancelCurrentFetch() {
		if (this.mFetchPromise) {
			this.mFetchPromise.cancel();
			this.mFetchPromise = null;
		}
		this.fetching = false;
	}

	public hasAllPages(context?: TContext, pageSize?: number, params?: Api.IDictionary) {
		const fetchParamsAreEqual = this.fetchIsEqualToPreviousFetch(context, pageSize, params);
		return this.mFetchComplete && !!fetchParamsAreEqual;
	}

	protected executeFetch(context?: TContext, pageSize?: number, params?: Api.IDictionary) {
		return new Promise<Api.IOperationResult<Api.IPagedCollection<TModel>>>((resolve, reject) => {
			// @ts-ignore
			let apiPath: string = null;
			if (typeof this.mApiPath === 'string') {
				apiPath = this.mApiPath as string;
			} else if (typeof this.mApiPath === 'function') {
				apiPath = (this.mApiPath as () => string)();
			}
			this.mClient.callWebServiceWithOperationResults<Api.IPagedCollection<TModel>>(
				this.composeApiUrl({ queryParams: this.composeQueryParams(context, pageSize, params), urlPath: apiPath }),
				'GET',
				null,
				opResult => {
					resolve(opResult);
				},
				error => {
					reject(error);
				}
			);
		});
	}

	protected composeQueryParams(context?: TContext, pageSize?: number, params?: Api.IDictionary) {
		const computedParams = {
			...(this.mApiParams || {}),
			...(params || {}),
			...(context || {}),
			pageSize: pageSize || this.mPageSize,
		};
		removeEmptyKvpFromObject(computedParams);
		return computedParams;
	}

	protected fetchIsEqualToPreviousFetch(context?: TContext, pageSize?: number, params: Api.IDictionary = {}) {
		return (
			((!context && !this.mFetchContext) || equal(context, this.mFetchContext)) &&
			pageSize === this.mPageSize &&
			((!params && !this.mFetchParams) || equal(params || {}, this.mFetchParams || {}))
		);
	}
}

export class ObservablePageCollectionController<
	TModel extends object = any,
	TItem extends object = TModel,
	TContext extends Api.IPagedResultFetchContext = Api.IPagedResultFetchContext,
> extends BaseObservablePageCollectionController<TModel, TItem, TContext> {
	protected composeQueryParams(context?: TContext, pageSize?: number, params?: Api.IDictionary) {
		// remove the typeOf filter
		const contextWithoutTypeOfFilter = Api.excludeKeysOf((context as Api.IPagedResultFetchContext) || {}, ['typeOf']);

		// convert typeOf filter array to "string,string,string..." param
		const types = !!context && !!context.typeOf ? { typeOf: context.typeOf.join(',') } : {};
		const computedParams = {
			...(this.mApiParams || {}),
			...(params || {}),
			...contextWithoutTypeOfFilter,
			...types,
			pageSize: pageSize || this.mPageSize,
		};
		removeEmptyKvpFromObject(computedParams);
		return computedParams;
	}
}

/** Deprecated. Please use ObservablePageCollectionController instead */

export class ObservableCollection<TItem extends object = any> {
	// @ts-ignore
	@observable.ref protected mIdToItem: Api.IDictionary<TItem>;
	// @ts-ignore
	@observable.ref protected mItems: TItem[];
	protected mIdWeakMap: WeakMap<TItem, string>;
	protected mUniqueIdentifierPropertyPath: string;

	/**
	 * @param items Initial items to add (optional)
	 * @param uniqueIdentifierPropertyPath Propert path of each item's uuid... e.g.
	 *   "myObject.someProperty.someOtherProperty". Note: Property path must lead to a string value. (optional)
	 */
	constructor(items?: TItem[], uniqueIdentifierPropertyPath?: string) {
		this.mIdWeakMap = new WeakMap<TItem, string>();
		// @ts-ignore
		this.mUniqueIdentifierPropertyPath = uniqueIdentifierPropertyPath;
		this.mAddAll = this.mAddAll.bind(this);
		this.mClear = this.mClear.bind(this);
		this.mRemoveItems = this.mRemoveItems.bind(this);
		this.clear();
		if (items) {
			this.mAddAll(items);
		}
	}

	@computed
	public get stringValue() {
		if (this.mUniqueIdentifierPropertyPath) {
			return JSON.stringify(this.mItems.map(x => this.getItemId(x)));
		}
		return '';
	}

	@computed
	public get length() {
		return this.mItems.length;
	}

	@computed
	get last() {
		if (this.mItems.length === 0) {
			return null;
		}

		return this.mItems[this.mItems.length - 1];
	}

	@action
	public setItems = (items: TItem[]) => {
		this.mClear();
		return this.mAddAll(items);
	};

	/**
	 * @param strict If true, item comparison will be strictly "===" and not "id" based (default = false)
	 * @returns Items removed (not necessarily the items passed to the receiver, if strict === false)
	 */
	@action
	public removeItems = (items: TItem[], strict = false) => {
		return this.mRemoveItems(items, strict);
	};

	@action
	public add = (item: TItem) => {
		if (item) {
			return this.addAll([item]);
		}

		return false;
	};

	@action
	public unshift = (item: TItem) => {
		if (item) {
			return this.addAll([item], 'unshift');
		}

		return false;
	};

	@action
	public addAll = (itemsToAdd: TItem[], method: 'push' | 'unshift' = 'push') => {
		this.mAddAll(itemsToAdd, method);
	};

	@action
	public clear = () => {
		this.mClear();
	};

	@action
	public pop = () => {
		if (this.mItems.length > 0) {
			const items = [...this.mItems];
			const item = items.pop();
			this.mItems = items;

			// remove it from the backing dictionary and weakmap
			// @ts-ignore
			const id = this.mUniqueIdentifierPropertyPath ? this.getItemId(item) : null;
			if (id) {
				const idToItem: Api.IDictionary<TItem> = { ...this.mIdToItem };
				delete idToItem[id];
				// @ts-ignore
				this.mIdWeakMap.delete(item);
				this.mIdToItem = idToItem;
			}

			return item;
		}

		return null;
	};

	@action
	public sort = (compareFn?: (a: TItem, b: TItem) => number) => {
		const items = [...this.mItems];
		items.sort(compareFn);
		this.mItems = items;
		return this;
	};

	@action
	public splice = (start: number, deleteCount: number, ...itemsToInsert: TItem[]): TItem[] => {
		const items = [...this.mItems];
		const idToItem: Api.IDictionary<TItem> = { ...this.mIdToItem };
		// we need to know what will be removed in order to remove these items from the id map before filtering itemsToInsert
		const itemsRemoved = [...this.mItems].splice(start, deleteCount);

		// remove them from the backing dictionary and weakmap
		if (this.mUniqueIdentifierPropertyPath) {
			itemsRemoved.forEach(x => {
				const id = this.getItemId(x);
				if (id) {
					delete idToItem[id];
					this.mIdWeakMap.delete(x);
				}
			});
		}

		const filteredItemsToInsert = [...(itemsToInsert || [])].filter(x => {
			const exists = !!x;
			if (!!exists && !!this.mUniqueIdentifierPropertyPath) {
				const id = this.getItemId(x);
				if (id) {
					const add = !idToItem[id];
					if (add) {
						idToItem[id] = x;
					}
					return add;
				}
			}

			return exists;
		});
		items.splice(start, deleteCount, ...filteredItemsToInsert);
		this.mItems = items;
		this.mIdToItem = idToItem;

		return itemsRemoved;
	};

	public getByIndex = (index: number) => {
		return this.mItems[index];
	};

	public getById = (id: string) => {
		return this.mIdToItem[id];
	};

	public has = (item: TItem, strict = false) => {
		if (item) {
			// note: we don't add the id to cache
			const id = this.mUniqueIdentifierPropertyPath ? this.getItemId(item, false) : null;
			if (id) {
				const matchingItem = this.mIdToItem[id];
				return strict ? item === matchingItem : !!matchingItem;
			} else {
				return !!this.find(x => x === item);
			}
		}

		return false;
	};

	public indexOf = (item: TItem, strict = false) => {
		if (item) {
			if (!!strict || !this.mUniqueIdentifierPropertyPath) {
				return this.mItems.findIndex(x => x === item);
			}

			// note: we don't add the id to cache
			const id = this.getItemId(item, false);
			return !!id && !!this.mIdToItem[id]
				? this.mItems.findIndex(x => this.getItemId(x) === id)
				: this.mItems.findIndex(x => x === item);
		}

		return -1;
	};

	public setItemAtIndex = (item: TItem, index: number) => {
		if (!!item && !!this.mItems[index]) {
			const items = [...this.mItems];
			items[index] = item;
			this.mItems = items;
			return item;
		}

		return false;
	};

	public reverse = () => {
		return this.mItems.reverse();
	};

	public find = (
		predicate: (value: TItem, index?: number, obj?: TItem[]) => boolean,
		thisArg?: any
	): TItem | undefined => {
		return this.mItems.find(predicate, thisArg);
	};

	public filter = (callbackfn: (value: TItem, index?: number, array?: TItem[]) => any, thisArg?: any): TItem[] => {
		return this.mItems.filter(callbackfn, thisArg);
	};

	public reduce = <T = any>(
		callbackfn: (previousValue: T, currentValue: TItem, currentIndex?: number, array?: TItem[]) => T,
		initialValue?: T
	): T => {
		// @ts-ignore
		// @ts-ignore
		return this.mItems.reduce(callbackfn, initialValue);
	};

	public forEach = (callbackfn: (value: TItem, index?: number, array?: TItem[]) => void, thisArg?: any) => {
		return this.mItems.forEach(callbackfn, thisArg);
	};

	public map = <U = any>(callbackfn: (value: TItem, index?: number, array?: TItem[]) => U, thisArg?: any): U[] => {
		return this.mItems.map(callbackfn, thisArg);
	};

	/** @returns New instance of array containing all items */
	public toArray = () => {
		return [...this.mItems];
	};

	/** @returns The actual array managed by the receiver. Do not modify the contents. Use only in specual cases. */
	@computed
	public get backingArray() {
		return this.mItems;
	}

	/** @returns The actual dictionary managed by the receiver. Do not modify the contents. Use only in specual cases. */
	@computed
	public get backingDictionary() {
		return this.mIdToItem;
	}

	protected mRemoveItems(itemsToRemove: TItem[], strict = false) {
		if (!!itemsToRemove && itemsToRemove.length > 0) {
			const removedItems: TItem[] = [];
			const items = [...this.mItems];
			const idToItem: Api.IDictionary<TItem> = { ...this.mIdToItem };

			itemsToRemove.forEach(x => {
				// note: we don't add the id to cache
				const id = this.mUniqueIdentifierPropertyPath ? this.getItemId(x, false) : null;
				const matchingItem = id ? idToItem[id] : null;
				let itemRemoved = false;
				if (strict) {
					const index = items.indexOf(x);
					if (index >= 0) {
						itemRemoved = true;
						items.splice(index, 1);
					}
				} else {
					if (matchingItem) {
						itemRemoved = true;
						items.splice(items.indexOf(matchingItem), 1);
					}
				}

				if (itemRemoved) {
					removedItems.push(x);
					if (id) {
						delete idToItem[id];
						if (matchingItem) {
							this.mIdWeakMap.delete(matchingItem);
						}
					}
				}
			});

			if (removedItems.length > 0) {
				this.mItems = items;
				this.mIdToItem = idToItem;
			}

			return removedItems;
		}

		return [];
	}

	protected mAddAll(itemsToAdd: TItem[], method: 'push' | 'unshift' = 'push') {
		if (!!itemsToAdd && itemsToAdd.length > 0) {
			const items = [...this.mItems];
			const idToItem: Api.IDictionary<TItem> = { ...this.mIdToItem };
			const filteredItemsToAdd = itemsToAdd.filter(x => {
				if (x) {
					if (this.mUniqueIdentifierPropertyPath) {
						// note: we don't add the id to cache yet
						const id = this.getItemId(x, false);

						if (!id || !!idToItem[id]) {
							return false;
						}

						items[method](x);
						idToItem[id] = x;
						// add the id to cache here
						this.mIdWeakMap.set(x, id);
					} else {
						items.push(x);
					}

					return true;
				}
				return false;
			});

			if (filteredItemsToAdd.length > 0) {
				this.mItems = items;
				this.mIdToItem = idToItem;
				return true;
			}
		}
		return false;
	}

	protected mClear() {
		if (this.mItems) {
			this.mItems.forEach(x => {
				this.mIdWeakMap.delete(x);
			});
		}
		this.mIdToItem = {};
		this.mItems = [];
	}

	/** Helper method for getting an item's id */
	protected getItemId = (item: TItem, saveToCache = true) => {
		if (this.mUniqueIdentifierPropertyPath) {
			let id = this.mIdWeakMap.get(item);
			let foundInCache = !!id;
			if (!id) {
				id = Api.getValueAtPropertyPath(item, this.mUniqueIdentifierPropertyPath);
				foundInCache = false;
			}

			if (!!id && typeof id === 'string') {
				if (!foundInCache && !!saveToCache) {
					this.mIdWeakMap.set(item, id);
				}
				return id;
			}
		}
		return null;
	};
}

/** Wrapper vm for ObservablePageCollectionController */
export class PageCollectionControllerViewModel<
	TModel extends Api.IBaseApiModel = any,
	TItem extends object = TModel,
	TContext extends Api.IPagedResultFetchContext = Api.IPagedResultFetchContext,
> extends ViewModel {
	protected mController: ObservablePageCollectionController<TModel, TItem, TContext>;
	protected mBaseApiRoute: string | (() => string);
	constructor(
		userSession: UserSessionContext,
		baseApiRoute: string | (() => string),
		options: IBaseObservablePageCollectionControllerOptions
	) {
		super(userSession);
		this.mBaseApiRoute = baseApiRoute;
		this.mController = new ObservablePageCollectionController<TModel, TItem, TContext>(options);
	}

	public reset() {
		this.mController.reset();
	}

	@computed
	public get items() {
		return this.mController.fetchResults;
	}

	@computed
	public get isFetching() {
		return this.mController.isFetching;
	}

	@computed
	public get totalCount() {
		return this.mController.totalCount;
	}

	public getNext(context?: TContext, pageSize?: number, params?: Api.IDictionary<any>) {
		return this.mController.getNext(context, pageSize, params);
	}

	public impersonate(impersonationContext?: Api.IImpersonationContext) {
		super.impersonate(impersonationContext);
		this.mController.impersonate(impersonationContext);
		return this;
	}

	public create = (model: TModel) => {
		return this.templateCrudRequest(model, 'POST');
	};

	public update = (model: TModel) => {
		return this.templateCrudRequest(model, 'PATCH');
	};

	public delete = (model: TModel) => {
		return this.templateCrudRequest(model, 'DELETE');
	};

	private templateCrudRequest = async (model: TModel, method: 'POST' | 'GET' | 'PATCH' | 'DELETE') => {
		if (!this.isBusy) {
			this.busy = true;
			const opResult = await this.mUserSession.webServiceHelper.callWebServiceAsync<TModel>(
				this.composeApiUrl({
					urlPath: `${typeof this.mBaseApiRoute === 'function' ? this.mBaseApiRoute() : this.mBaseApiRoute}${
						method !== 'POST' ? `/${model.id}` : ''
					}`,
				}),
				method,
				method === 'GET' ? null : model
			);
			this.busy = false;
			if (!opResult.success) {
				throw opResult;
			}
			const transformer = this.mController.transformer || ((m: TModel): TItem => m as any);
			switch (method) {
				case 'DELETE': {
					// remove the matching model
					this.mController.fetchResults.removeItems([model as any]);
					break;
				}
				case 'PATCH': {
					if (!!model.id && !!opResult.value && !!opResult.value.id) {
						const index = this.mController.fetchResults.indexOf(opResult.value as any);
						if (index >= 0) {
							// replace the matching model
							this.mController.fetchResults.splice(index, 1, transformer(opResult.value));
						} else {
							// add it at the top of the list
							this.mController.fetchResults.splice(0, 0, transformer(opResult.value));
						}
					}

					break;
				}
				case 'POST': {
					if (!!opResult.value && !!opResult.value.id && !this.mController.fetchResults.has(opResult.value as any)) {
						// add it at the top of the list
						this.mController.fetchResults.splice(0, 0, transformer(opResult.value));
					}
					break;
				}
				default: {
					break;
				}
			}
		}
	};
}

export abstract class PagedViewModel<
	TApi extends object = any,
	TModel extends object = any,
	TRequest extends object = any,
> extends BaseViewModel {
	// @ts-ignore
	protected mPagedCollectionController: BaseObservablePageCollectionController<TApi, TModel, TRequest>;

	abstract get request(): TRequest;
	abstract get pageSize(): number;

	constructor(userSession: UserSessionContext) {
		super(userSession);
		this.reset = this.reset.bind(this);
		this.fetch = this.fetch.bind(this);
	}

	@computed
	public get isLoaded() {
		return this.mPagedCollectionController?.hasFetchedFirstPage;
	}

	@computed
	public get isBusy() {
		return this.mPagedCollectionController?.isFetching;
	}

	@computed
	public get isLoading() {
		return !this.isLoaded && this.isBusy;
	}

	@computed
	public get items() {
		return this.mPagedCollectionController?.fetchResults;
	}

	@computed
	public get itemsCount() {
		return this.mPagedCollectionController?.totalCount;
	}

	@action
	load(params?: Api.IDictionary): Promise<Api.IPageCollectionControllerFetchResult<TApi[]>> {
		this.mPagedCollectionController?.reset();
		return this.fetch(params) as any;
	}

	@action
	loadNext(params?: Api.IDictionary): Promise<Api.IPageCollectionControllerFetchResult<TApi[]>> {
		return this.fetch(params) as any;
	}

	@action
	public reset() {
		this.mPagedCollectionController?.reset();
	}

	public impersonate(impersonationContext?: Api.IImpersonationContext) {
		super.impersonate(impersonationContext);
		this.mPagedCollectionController?.impersonate(impersonationContext);
		return this;
	}

	protected fetch(params?: Api.IDictionary) {
		return this.mPagedCollectionController?.getNext(this.request, this.pageSize, params);
	}
}

export class FilteredPageCollectionController<
	TModel extends object = any,
	TItem extends object = any,
	TRequest extends object = any,
> extends BaseObservablePageCollectionController<TModel, TItem, TRequest> {
	protected executeFetch(filterRequest?: TRequest, pageSize?: number, params?: Api.IDictionary) {
		return new Promise<Api.IOperationResult<Api.IPagedCollection<TModel>>>((resolve, reject) => {
			// @ts-ignore
			let apiPath: string = null;
			if (typeof this.mApiPath === 'string') {
				apiPath = this.mApiPath as string;
			} else if (typeof this.mApiPath === 'function') {
				apiPath = (this.mApiPath as () => string)();
			}
			this.mClient.callWebServiceWithOperationResults<Api.IPagedCollection<TModel>>(
				// @ts-ignore
				this.composeApiUrl({ queryParams: this.composeQueryParams(null, pageSize, params), urlPath: apiPath }),
				this.mHttpMethod,
				filterRequest || {},
				resolve,
				reject
			);
		});
	}
}

export class FilteredResourcePageCollectionController<
	TModel extends object = any,
	TItem extends object = any,
	TRequest extends Api.IResourceSelectorRequest<any> = any,
> extends FilteredPageCollectionController<TModel, TItem, TRequest> {
	/** Only compares the filter and qualifier to determine if new, ignoring include and exclude IDs */
	protected fetchIsEqualToPreviousFetch(context?: TRequest, pageSize?: number, params?: Api.IDictionary) {
		const request: Partial<Api.IResourceSelectorRequest<any>> = {
			filter: context?.filter,
			qualifier: context?.qualifier,
		};
		const mRequest: Partial<Api.IResourceSelectorRequest<any>> = {
			filter: this.mFetchContext?.filter,
			qualifier: this.mFetchContext?.qualifier,
		};
		return (
			((!request && !mRequest) || equal(request, mRequest)) &&
			pageSize === this.mPageSize &&
			((!params && !this.mFetchParams) || equal(params, this.mFetchParams))
		);
	}
}
