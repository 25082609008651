import { action, computed, observable, toJS } from 'mobx';
import { stringify as toQueryStringParams } from 'query-string';
import { removeEmptyKvpFromObject } from '../Utils';
import * as Models from './models';

class InternalInMemoryCredentialStore implements IWebServiceHelperCredentialStore {
	// @ts-ignore
	private credential: Models.ICredential;
	public clear(): void {
		// @ts-ignore
		this.credential = null;
	}

	public getCredential(): Promise<Models.ICredential> {
		return Promise.resolve<Models.ICredential>(this.credential);
	}

	public setCredential(credential: Models.ICredential) {
		const credentialWithoutPassword = { ...credential };
		delete credentialWithoutPassword.password;
		this.credential = credentialWithoutPassword;
		return Promise.resolve(true);
	}
}

export type HTTPMethod = 'DELETE' | 'GET' | 'PATCH' | 'POST' | 'PUT';

export const asApiError = (e: any) => {
	let error: Models.IOperationResultNoValue;
	if (e) {
		if (Object.prototype.hasOwnProperty.call(e, 'systemMessage')) {
			error = Object.prototype.hasOwnProperty.call(e, 'systemCode')
				? { ...(e as Models.IOperationResultNoValue) }
				: { systemMessage: e.systemMessage };
		} else if (e instanceof Error) {
			error = { systemMessage: e.message };
		} else if (typeof e === 'string') {
			error = { systemMessage: e };
		} else if (Object.prototype.hasOwnProperty.call(e, 'toString') && typeof e.toString === 'function') {
			error = { systemMessage: e.toString() };
		} else {
			error = { systemMessage: 'Unexpected error.' };
		}

		if (!error.systemCode && (error.systemMessage || '').toLocaleLowerCase() === 'failed to fetch') {
			error = { systemCode: 1, systemMessage: 'Error connecting to Levitate.' };
		}
	} else {
		error = { systemMessage: 'Unexpected error' };
	}
	error.success = false;

	return error;
};

export interface IWebServiceHelperCredentialStore {
	clear(): void;
	getCredential(): Promise<Models.ICredential>;
	setCredential(credential: Models.ICredential): Promise<boolean>;
}

export interface IWebServiceHelperConfig {
	baseUrl?: string;
	credentialStore?: IWebServiceHelperCredentialStore;
}

export interface IWebServiceHelperEvent<TTarget = any, TData = any> {
	data?: TData;
	name: string;
	target?: TTarget;
}

export type IWebServiceHelperEventHandler<TTarget = any, TData = any> = (
	event: IWebServiceHelperEvent<TTarget, TData>
) => void;

export const DefaultConfig: IWebServiceHelperConfig = {
	baseUrl: 'https://api.levitate.ai',
	credentialStore: new InternalInMemoryCredentialStore(),
};

export class WebServiceHelper {
	public static EventNames = {
		REAUTH_REQUEST_END: 'REAUTH_REQUEST_END',
		REAUTH_REQUEST_START: 'REAUTH_REQUEST_START',
		REQUEST_END: 'REQUEST_END',
		REQUEST_START: 'REQUEST_START',
	};

	private config: IWebServiceHelperConfig;
	private eventHandlers: Record<string, IWebServiceHelperEventHandler[]> = {};

	constructor(config?: IWebServiceHelperConfig) {
		this.config = { ...DefaultConfig, ...config };
	}

	public on = <TTarget = any, TData = any>(
		eventName: string,
		handler: IWebServiceHelperEventHandler<TTarget, TData>
	) => {
		const handlers = this.eventHandlers[eventName] || [];
		handlers.push(handler);
		this.eventHandlers[eventName] = handlers;
	};

	public removeEventHandler = (eventName: string, handler: IWebServiceHelperEventHandler) => {
		const handlers = this.eventHandlers[eventName] || [];
		const index = handlers.indexOf(handler);
		if (index >= 0) {
			handlers.slice(index, 1);
			this.eventHandlers[eventName] = handlers;
		}
	};

	public getCredentialStore = () => {
		return this.config.credentialStore;
	};

	public get baseUrl() {
		return this.config.baseUrl;
	}

	public callWebServiceWithOperationResults<T>(
		path: string,
		method: HTTPMethod,
		jsonObj: any,
		resultsOk: (responseObject: Models.IOperationResult<T>) => void,
		resultsError: (error: Models.IOperationResultNoValue) => void,
		credential?: Models.ICredential,
		canReauthIfNeeded = true
	) {
		return this.callWebServiceRawResponse(
			path,
			method,
			jsonObj,
			async rawResponse => {
				const apiResponse: Models.IOperationResult<T> = await rawResponse.json();
				resultsOk(apiResponse);
			},
			resultsError,
			credential,
			canReauthIfNeeded
		);
	}

	public callWebServiceWithBulkOperationResult = <T>(
		path: string,
		method: HTTPMethod,
		jsonObj: any,
		resultsOk: (responseObject: Models.IBulkOperationResult<T>) => void,
		resultsError: (error: Models.IOperationResultNoValue) => void,
		credential?: Models.ICredential,
		canReauthIfNeeded = true
	) => {
		return this.callWebServiceRawResponse(
			path,
			method,
			jsonObj,
			async rawResponse => {
				const apiResponse: Models.IBulkOperationResult<T> = await rawResponse.json();
				resultsOk(apiResponse);
			},
			resultsError,
			credential,
			canReauthIfNeeded
		);
	};
	/** @throws {IOperationResultNoValue} */
	public callRawAsync = async <T>(
		path: string,
		method: HTTPMethod,
		body?: any,
		credential?: Models.ICredential,
		canReauthIfNeeded = true
	) => {
		const result = await this.callWebServiceRawTextAsync<T>(path, method, body, credential, canReauthIfNeeded);
		return result;
	};
	/** Internally catches errors and always returns IOperationResult<T> | IOperationResultNoValue */
	public callWebServiceRawTextAsync = async <T>(
		path: string,
		method: HTTPMethod,
		body?: any,
		credential?: Models.ICredential,
		canReauthIfNeeded = true
	): Promise<Models.IOperationResult<T>> => {
		return new Promise((resolve, reject) => {
			this.callWebServiceRawResponse(
				path,
				method,
				body,
				async rawResponse => {
					const apiResponse: string = await rawResponse.text();
					// @ts-ignore
					resolve(apiResponse);
				},
				resolve,
				credential,
				canReauthIfNeeded
			).catch(reject);
		});
	};

	/** @throws {IOperationResultNoValue} */
	public callAsync = async <T>(
		path: string,
		method: HTTPMethod,
		body?: any,
		credential?: Models.ICredential,
		canReauthIfNeeded = true
	) => {
		const result = await this.callWebServiceAsync<T>(path, method, body, credential, canReauthIfNeeded);
		if (!result.success) {
			throw asApiError(result);
		}

		return result.value!;
	};

	/** Internally catches errors and always returns IOperationResult<T> | IOperationResultNoValue */
	public callWebServiceAsync = async <T>(
		path: string,
		method: HTTPMethod,
		body?: any,
		credential?: Models.ICredential,
		canReauthIfNeeded = true
	): Promise<Models.IOperationResult<T>> => {
		return new Promise((resolve, reject) => {
			this.callWebServiceRawResponse(
				path,
				method,
				body,
				async rawResponse => {
					const apiResponse: Models.IOperationResult<T> = await rawResponse.json();
					resolve(apiResponse);
				},
				resolve,
				credential,
				canReauthIfNeeded
			).catch(reject);
		});
	};

	public callWebServiceRawResponse = async (
		path: string,
		method: HTTPMethod,
		body: any,
		resultsOk: (response: Response) => void,
		resultsError: (error: Models.IOperationResultNoValue) => void,
		credential?: Models.ICredential,
		canReauthIfNeeded = true
	) => {
		if (!credential) {
			try {
				// @ts-ignore
				credential = await this.config.credentialStore.getCredential();
			} catch (e) {
				//
			}
		}

		let accessToken = (credential || {}).access_token;

		// @ts-ignore
		// @ts-ignore
		if (!!canReauthIfNeeded && !!accessToken && !!credential.refresh_token && this.isAccessTokenExpired(credential)) {
			// try to reauth if we detect an expired accessToken
			try {
				const newCredential = await this.refreshCredential(credential);
				if (newCredential) {
					accessToken = newCredential.access_token;
					canReauthIfNeeded = false;
				}
			} catch (e) {
				// if reauth fails, return early
				resultsError(asApiError(e));
				return;
			}
		}

		// Create request object
		// @ts-ignore
		const request = this.buildFetchRequest(path, method, body, accessToken);
		this.emit({
			data: { request },
			name: WebServiceHelper.EventNames.REQUEST_START,
			target: this,
		});

		let response: Response;
		try {
			response = await fetch(request);
			this.emit({
				data: { request, response },
				name: WebServiceHelper.EventNames.REQUEST_END,
				target: this,
			});
		} catch (err) {
			const error = asApiError(err);
			this.emit({
				data: { error, request },
				name: WebServiceHelper.EventNames.REQUEST_END,
				target: this,
			});
			resultsError(error);
			return;
		}

		try {
			if (response.status === 401 && !!credential && !!credential.refresh_token && canReauthIfNeeded) {
				const apiResponse: Models.IOperationResultNoValue = await response.json();
				// try to reauth
				let newCredential: Models.ICredential;
				try {
					newCredential = await this.refreshCredential(credential);
					if (newCredential) {
						await this.callWebServiceRawResponse(path, method, body, resultsOk, resultsError, newCredential, false);
						return;
					} else {
						resultsError(apiResponse);
					}
				} catch (reauthEx) {
					// could be api error, or parsing error, etc.
					resultsError(asApiError(reauthEx));
				}
			} else if (response.status > 299) {
				const apiResponse: Models.IOperationResultNoValue = await response.json();
				resultsError(apiResponse);
			} else {
				// success
				resultsOk(response);
			}
		} catch (err) {
			let error =
				// @ts-ignore
				err?.message === 'Unexpected end of JSON input' ? new Error('Api did not return an operation result.') : err;
			error = asApiError(error);
			// @ts-ignore
			resultsError(error);
		}
	};

	private refreshCredential = async (credential?: Models.ICredential) => {
		// @ts-ignore
		// @ts-ignore
		const request = this.buildFetchRequest('user/refresh', 'POST', { refresh_token: credential.refresh_token }, null);
		try {
			this.emit({
				data: { request },
				name: WebServiceHelper.EventNames.REAUTH_REQUEST_START,
				target: this,
			});

			const response = await fetch(request);

			this.emit({
				data: { request, response },
				name: WebServiceHelper.EventNames.REAUTH_REQUEST_END,
				target: this,
			});

			const newCredentialResult: Models.IOperationResult<Models.ICredential> = await response.json();
			const newCredential = {
				...(credential || {}),
				...newCredentialResult.value,
			};

			// @ts-ignore
			await this.getCredentialStore().setCredential(newCredential);
			return newCredential;
		} catch (e) {
			const error = asApiError(e);
			this.emit({
				data: { error },
				name: WebServiceHelper.EventNames.REAUTH_REQUEST_END,
				target: this,
			});
			throw error;
		}
	};

	private buildFetchRequest = (path: string, method: HTTPMethod, obj: any, accessToken: string) => {
		// Check for a special method called FILEPOST, which we will transform to POST but will not convert body to JSON
		const isFormDataBody = obj instanceof FormData;

		// Define headers
		const headers = new Headers();
		if (isFormDataBody) {
			// DO NOT INCLUDE A HEADER FOR FILE POSTS
		} else {
			headers.append('Content-Type', 'application/json; charset=utf-8');
		}
		headers.append('Accept', 'application/json');
		headers.append('Pragma', 'no-cache');
		headers.append('Cache-Control', 'no-cache');

		if (accessToken) {
			headers.append('Authorization', 'bearer ' + accessToken);
		}
		const url = `${this.config.baseUrl}/${path}`;
		const config: RequestInit = {
			headers,
			method,
			mode: 'cors',
			redirect: 'follow',
		};

		if (method.toLowerCase() !== 'get' && !!obj) {
			config.body = isFormDataBody ? obj : JSON.stringify(toJS(obj));
		}

		const request = new Request(url, config);
		return request;
	};

	private isAccessTokenExpired = (credential: Models.ICredential) => {
		if (!credential.access_token || !Object.prototype.hasOwnProperty.call(credential, 'expires_utc')) {
			return true;
		}

		const accessTokenExpiration = new Date((credential as any).expires_utc as number);
		/** Within 5 minutes of expiration */
		const expirationTokenWithThreshold = new Date(accessTokenExpiration.getTime() - 5 * 60000);

		const currentTimeUtc = new Date(Date.now());
		const accessTokenExpired = currentTimeUtc >= expirationTokenWithThreshold;
		return accessTokenExpired;
	};

	private emit = <TTarget = any, TData = any>(event: IWebServiceHelperEvent<TTarget, TData>) => {
		const handlers = this.eventHandlers[event.name] || [];
		setTimeout(() => {
			handlers.forEach(x => x(event));
		}, 1); // next event loop
	};
}

export enum ApiRequestStatus {
	Cancelled = 'Cancelled',
	Done = 'Done',
	Pending = 'Pending',
	Running = 'Running',
}

export interface IApiRequest<TModel = void, TResult = Models.IOperationResult<TModel>> {
	cancel(): void;
	execute(): void;
	onFinish(callback: (opResult: TResult) => void): IApiRequest<TModel, TResult>;
	readonly status: ApiRequestStatus;
}

export interface IApiRequestOptions<TBody = any> {
	body?: TBody;
	queryStringParams?: Models.IDictionary<any>;
}

export abstract class ImpersonationBroker {
	public static composeApiUrl({
		urlPath: url,
		queryParams,
		impersonationContext,
	}: {
		urlPath: string;
		queryParams?: Models.IDictionary<any>;
		impersonationContext?: Models.IImpersonationContext;
	}) {
		const params = { ...(queryParams || {}) };
		if (!!impersonationContext?.user?.id && !params.impUserId) {
			params.impUserId = impersonationContext.user.id;
		}
		removeEmptyKvpFromObject(params);

		// @ts-ignore
		let queryString: string = null;
		if (Object.keys(params).length > 0) {
			queryString = toQueryStringParams(params);
		}
		if (queryString && url.includes('?')) {
			throw new Error(`queryParam in the url and as a parameter not supported`);
		}
		return `${impersonationContext?.account?.id ? `impersonate/${impersonationContext.account.id}/` : ''}${url || ''}${
			queryString ? `?${queryString}` : ''
		}`;
	}

	// @ts-ignore
	protected mImpersonationContext: Models.IImpersonationContext;
	constructor() {
		this.composeApiUrl = this.composeApiUrl.bind(this);
		this.impersonate = this.impersonate.bind(this);
	}

	public get isImpersonating() {
		return !!this.mImpersonationContext;
	}

	public get impersonationContext() {
		return this.mImpersonationContext;
	}

	protected composeApiUrl({ urlPath, queryParams }: { urlPath: string; queryParams?: Models.IDictionary<any> }) {
		return ImpersonationBroker.composeApiUrl({
			impersonationContext: this.mImpersonationContext,
			queryParams,
			urlPath,
		});
	}

	public impersonate(impersonationContext?: Models.IImpersonationContext) {
		// @ts-ignore
		this.mImpersonationContext = impersonationContext;
		return this;
	}
}

export class ApiRequest<TModel = void, TResult = Models.IOperationResult<TModel>>
	extends ImpersonationBroker
	implements IApiRequest<TModel, TResult>
{
	@observable protected mStatus: ApiRequestStatus;
	protected mCallbacks: ((opResult: TResult) => void)[];
	protected mClient: WebServiceHelper;
	protected mMethod: HTTPMethod;
	protected mOptions: IApiRequestOptions;
	protected mPath: string;
	// @ts-ignore
	protected mPromise: Promise<TResult>;

	public static Get = <STModel = void, STResult = Models.IOperationResult<STModel>>(
		client: WebServiceHelper,
		path: string,
		options?: IApiRequestOptions
	) => {
		return new ApiRequest<STModel, STResult>(client, 'GET', path, options);
	};

	public static Post = <STModel = void, STResult = Models.IOperationResult<STModel>>(
		client: WebServiceHelper,
		path: string,
		options?: IApiRequestOptions
	) => {
		return new ApiRequest<STModel, STResult>(client, 'POST', path, options);
	};

	public static Put = <STModel = void, STResult = Models.IOperationResult<STModel>>(
		client: WebServiceHelper,
		path: string,
		options?: IApiRequestOptions
	) => {
		return new ApiRequest<STModel, STResult>(client, 'PUT', path, options);
	};

	public static Patch = <STModel = void, STResult = Models.IOperationResult<STModel>>(
		client: WebServiceHelper,
		path: string,
		options?: IApiRequestOptions
	) => {
		return new ApiRequest<STModel, STResult>(client, 'PATCH', path, options);
	};

	constructor(client: WebServiceHelper, method: HTTPMethod, path: string, options?: IApiRequestOptions) {
		super();
		this.mCallbacks = [];
		this.mClient = client;
		this.mMethod = method;
		// @ts-ignore
		this.mOptions = options;
		this.mPath = path;
		this.mStatus = ApiRequestStatus.Pending;
		this.cancel = this.cancel.bind(this);
		this.execute = this.execute.bind(this);
		this.executeRequest = this.executeRequest.bind(this);
		this.onFinish = this.onFinish.bind(this);
		this.onFinish = this.onFinish.bind(this);
	}

	@computed
	public get status() {
		return this.mStatus;
	}

	public cancel(): void {
		if (this.mStatus !== ApiRequestStatus.Cancelled) {
			this.mStatus = ApiRequestStatus.Cancelled;
			this.mCallbacks = [];
		}
	}

	public execute(): void {
		if (this.mStatus === ApiRequestStatus.Pending) {
			this.mStatus = ApiRequestStatus.Running;
			this.mPromise = this.executeRequest();
			this.mPromise?.then(this.mOnFinish).catch(this.mOnFinish);
		}
	}

	public onFinish(callback: (opResult: TResult) => void) {
		if (!!callback && this.mStatus !== ApiRequestStatus.Cancelled) {
			if (this.mPromise) {
				const onFinish = (opResult: TResult) => {
					if (this.mStatus !== ApiRequestStatus.Cancelled) {
						callback(opResult);
					}
				};
				this.mPromise.then(onFinish).catch(onFinish);
				return this;
			}
			this.mCallbacks.push(callback);
		}

		return this;
	}

	public impersonate(impersonationContext?: Models.IImpersonationContext) {
		if (this.status === ApiRequestStatus.Pending) {
			// @ts-ignore
			this.mImpersonationContext = impersonationContext;
		}
		return this;
	}

	@action
	protected mOnFinish(opResult: TResult) {
		if (this.mStatus === ApiRequestStatus.Running) {
			this.mStatus = ApiRequestStatus.Done;
			this.mCallbacks.forEach(x => x(opResult));
			this.mCallbacks = [];
		}
	}

	/** Note: This promise always resolves. It's up to the caller to check the "success" boolean on the response */
	protected executeRequest() {
		return this.mClient.callWebServiceAsync<TModel>(
			this.composeApiUrl({ queryParams: this.mOptions?.queryStringParams, urlPath: this.mPath }),
			this.mMethod,
			this.mOptions?.body
		) as Promise<TResult>;
	}
}
