import {
	HandwritingStyle,
	IAutomationStep,
	IAutomationStepReference,
	IFileAttachment,
	IRawRichTextContentState,
	ITemplateReference,
	ProcessStatus,
} from './';

import { FilterOperator } from '../../../extViewmodels';

export type AutomationStatus = {
	startedDate?: string;
	cancelledDate?: string;
	completedDate?: string;
	skippedDate?: string;
	errorMessage?: string;
	errorDate?: string;
	status: ProcessStatus;
	outcome?: string;
};

export type CommunicationStats = {
	emailsSent: number;
	emailReplies: number;
	emailsFailed: number;
	emailsBounced: number;
	textsSent: number;
	textReplies: number;
	textsFailed: number;
	textNoNumberCount: number;
};

// API Source: https://github.com/Real-Magic/Levitate-API/blob/9933f885bb93af489285125346f97fb2419102ce/src/Levitate.Api.Models/Automation/TextingAutomationStepReference.cs#L6
export interface TextingAutomationStepReference extends IAutomationStepReference {
	content?: IRawRichTextContentState;
}

// API Source: https://github.com/Real-Magic/Levitate-API/blob/2ad143e4f082f5f6885866c2aab602c768eb9ffb/src/Levitate.Api.Models/Automation/TextingAutomationStep.cs#L3
export interface TextingAutomationStep extends IAutomationStep {
	content?: IRawRichTextContentState;
	attachments?: IFileAttachment[];
}

export type AutomationTemplateFilterCriteria = {
	criteria?: AutomationTemplateFilterCriteria[];
	op?: FilterOperator;
	property?: AutomationTemplateFilterProperty;
	value?: string | boolean;
};

export type AutomationTemplateFilter = AutomationTemplateFilterCriteria;

export enum AutomationTemplateFilterProperty {
	SingleStep = 'SingleStep',
	TriggerType = 'TriggerType',
	Status = 'Status',
	TemplateScope = 'TemplateScope',
	ParentTemplateId = 'ParentTemplateId',
}

export type AutomationTemplateFilterRequest = {
	filter: AutomationTemplateFilter;
};
export interface HandwrittenCardAutomationStep extends IAutomationStep {
	templateReference?: ITemplateReference;
	content?: IRawRichTextContentState;
	excludeSignature?: boolean;
	signature?: string;
	style?: HandwritingStyle;
}
