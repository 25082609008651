import * as React from 'react';

export const useContextGuard = <TContext = any>(context: React.Context<TContext>, requiredContextName?: string) => {
	const ctx = React.useContext<TContext>(context);
	if (!ctx) {
		throw new Error(
			requiredContextName ? `This component requires the use of "${requiredContextName}".` : 'Missing context.'
		);
	}
	return ctx;
};
