export type EmailAddress = {
	label?: string;
	value: string;
};

export enum HolidayId {
	Unknown = 'Unknown',
	NewYearsDay = 'NewYearsDay',
	Easter = 'Easter',
	MemorialDay = 'MemorialDay',
	IndependenceDay = 'IndependenceDay',
	LaborDay = 'LaborDay',
	Thanksgiving = 'Thanksgiving',
	Christmas = 'Christmas',
}
