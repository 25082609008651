import { IBaseResourceModel } from '.';

export enum CustomPageType {
	/// <summary>
	/// BlogPreviewPage
	/// </summary>
	BlogPreviewPage = 'BlogPreviewPage',
	/// <summary>
	/// IndivualPage
	/// </summary>
	BlogIndividualPage = 'BlogIndividualPage',
}

export interface ICustomPageStyle extends IBaseResourceModel {
	type: CustomPageType;
	css: string;
}
