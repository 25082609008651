import { brandPrimary, brandSecondary } from '../../styles/colors';
import { CSSProperties, StyleSheet } from 'aphrodite';

export const SpinnerExtraTinySize = 6;
export const SpinnerTinySize = 10;
export const SpinnerSmallSize = 30;
export const SpinnerExtraSmallSize = 18;
export const SpinnerLargeSize = 60;

const spinAnimationKeyframes = {
	'0%': {
		transform: 'rotate(0deg)',
	},
	'100%': {
		transform: 'rotate(360deg)',
	},
};

const getSpinner = (size: number, spinnerColor: string, spinnerBackgroundColor: string, borderWidth = 4) => {
	const style: CSSProperties = {
		animationDuration: '1s',
		animationIterationCount: 'infinite',
		animationName: [spinAnimationKeyframes],
		borderBottom: `${borderWidth}px solid ${spinnerBackgroundColor}`,
		borderLeft: `${borderWidth}px solid ${spinnerBackgroundColor}`,
		borderRadius: '50%',
		borderRight: `${borderWidth}px solid ${spinnerBackgroundColor}`,
		borderTop: `${borderWidth}px solid ${spinnerColor}`,
		height: size,
		margin: 'auto',
		width: size,
	};

	return style;
};

export const styleSheet = StyleSheet.create({
	loadingExtraSmall: getSpinner(SpinnerExtraSmallSize, brandPrimary, '#f9f9f9'),
	loadingExtraTiny: getSpinner(SpinnerExtraTinySize, brandPrimary, '#f9f9f9'),
	loadingLarge: getSpinner(SpinnerLargeSize, brandPrimary, '#f9f9f9', 8),
	loadingLogin: getSpinner(SpinnerLargeSize, '#fff', brandPrimary, 8),
	loadingLoginSecondary: getSpinner(SpinnerLargeSize, '#fff', brandSecondary, 8),
	loadingSmall: getSpinner(SpinnerSmallSize, brandPrimary, '#f9f9f9'),
	loadingTiny: getSpinner(SpinnerTinySize, brandPrimary, '#f9f9f9'),
});
