import * as AppViewModels from '../viewmodels/AppViewModels';
import { BrowserPushNotificationsViewModel } from '../viewmodels/PushNotifications';
import { IEnvironment } from './Environment';
import { IAppTheme } from './Themes';
import { TextMessagingViewModel } from '@ViewModels';

export interface IAppState {
	actionItemComposer?: AppViewModels.ActionItemComposerViewModel;
	bulkEmailComposer?: AppViewModels.ComposeEmailViewModel;
	environment?: IEnvironment;
	errorMessages?: AppViewModels.ErrorMessageViewModel;
	fab?: AppViewModels.FabViewModel;
	fullscreenModal?: AppViewModels.FullScreenModalViewModel;
	noteComposer?: AppViewModels.NoteComposerViewModel;
	pushNotifications?: BrowserPushNotificationsViewModel;
	quickAddEntity?: AppViewModels.QuickAddEntityViewModel;
	singleEmailComposer?: AppViewModels.ComposeEmailToViewModel;
	theme?: IAppTheme;
	toaster?: AppViewModels.ToasterViewModel<AppViewModels.IAppToastMessage>;
	userSession?: AppViewModels.UserSessionContext;
	textMessaging?: TextMessagingViewModel;
}

export interface IPushNotificationsComponentProps {
	pushNotifications?: BrowserPushNotificationsViewModel;
}

export interface IFullscreenModalComponentProps {
	fullscreenModal?: AppViewModels.FullScreenModalViewModel;
}

export interface IThemedComponentProps {
	theme?: IAppTheme;
}

export interface INoteComposerComponentProps {
	noteComposer?: AppViewModels.NoteComposerViewModel;
}

export interface IToasterComponentProps {
	toaster?: AppViewModels.ToasterViewModel<AppViewModels.IAppToastMessage>;
}

export interface IErrorMessageComponentProps {
	errorMessages?: AppViewModels.ErrorMessageViewModel;
}

export interface IQuickAddEntityComponentProps {
	quickAddEntity?: AppViewModels.QuickAddEntityViewModel;
}

export interface IActionItemComposerComponentProps {
	actionItemComposer?: AppViewModels.ActionItemComposerViewModel;
}

export interface IEnvironmentComponentProps {
	environment?: IEnvironment;
}

export interface IUserSessionComponentProps {
	userSession?: AppViewModels.UserSessionContext;
}

export interface ITextMessagingComponentProps {
	textMessaging?: TextMessagingViewModel;
}

export interface ISingleEmailComposerComponentProps {
	singleEmailComposer?: AppViewModels.ComposeEmailToViewModel;
}
export interface IFabComponentProps {
	fab?: AppViewModels.FabViewModel;
}

export const ActionItemComposerViewModelKey = 'actionItemComposer';
export const EnvironmentKey = 'environment';
export const ErrorMessagesViewModelKey = 'errorMessages';
export const FabViewModelKey = 'fab';
export const FullScreenModalViewModelKey = 'fullscreenModal';
export const NoteComposerViewModelKey = 'noteComposer';
export const PushNotificationsViewModelKey = 'pushNotifications';
export const QuickAddEntityViewModelKey = 'quickAddEntity';
export const SingleEmailComposerKey = 'singleEmailComposer';
export const ThemeKey = 'theme';
export const ToasterViewModelKey = 'toaster';
export const UserSessionViewModelKey = 'userSession';
export const TextMessagingViewModelKey = 'textMessaging';

/**
 * App state pushed to child components via context and mobx-react's provider wrapper. Right now this only has the
 * global toaster referenced, but this will grow to include multiple view models. TODO: we'll probably need a
 * 'withAppState' wrapper HOC for good measure.
 */
export const AppState: IAppState = {};
AppState[EnvironmentKey] = { appType: 'web' };
AppState[ActionItemComposerViewModelKey] = new AppViewModels.ActionItemComposerViewModel();
AppState[ErrorMessagesViewModelKey] = new AppViewModels.ErrorMessageViewModel();
AppState[FabViewModelKey] = new AppViewModels.FabViewModel();
// @ts-ignore
AppState[FullScreenModalViewModelKey] = null;
AppState[NoteComposerViewModelKey] = new AppViewModels.NoteComposerViewModel();
// @ts-ignore
AppState[PushNotificationsViewModelKey] = null;
AppState[QuickAddEntityViewModelKey] = new AppViewModels.QuickAddEntityViewModel();
AppState[SingleEmailComposerKey] = new AppViewModels.ComposeEmailToViewModel();
AppState[ThemeKey] = new AppViewModels.AppTheme();
AppState[ToasterViewModelKey] = new AppViewModels.ToasterViewModel<AppViewModels.IAppToastMessage>();
