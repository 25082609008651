import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	dropdown: {
		display: 'inline-block',
		position: 'relative',
		width: '100%',
	},
	dropdownAnchorWrap: {
		width: '100%',
	},
	dropdownBackdrop: {
		height: '100%',
		left: 0,
		position: 'fixed',
		top: 0,
		width: '100%',
		zIndex: 3,
	},
	dropdownContent: {
		display: 'inline-block',
		position: 'absolute',
		width: 'auto',
		zIndex: 100,
	},
	dropdownContentInner: {
		background: '#fff',
		border: '1px solid #e3e7e9',
		boxShadow: '0 2px 4px 0 rgb(0 0 0 / 10%)',
		boxSizing: 'border-box',
		maxHeight: 300,
		overflowX: 'hidden',
		overflowY: 'auto',
		width: '100%',
	},
});
