import { parse as getQueryStringParams } from 'query-string';
import * as Models from './models';

export const DefaultBulkSendExcludedTags = new Set(['Email Bounced', 'Unsubscribe']);
export const DefaultTextingBulkSendExcludedTags = new Set(['Unsubscribe']);

export const DefaultBulkSendExcludedFilterCriteria: Models.IContactFilterCriteria[] = [
	{
		op: Models.FilterOperator.Not,
		property: Models.ContactFilterCriteriaProperty.WithoutEmailAddresses,
	},
	...Array.from(DefaultBulkSendExcludedTags).map(
		x =>
			({
				op: Models.FilterOperator.Not,
				property: Models.ContactFilterCriteriaProperty.Tag,
				value: x,
			}) as Models.IContactFilterCriteria
	),
];

export const ReservedTags = new Set([...Array.from(DefaultBulkSendExcludedTags), 'Not Classified']);

export const getTypeForEntity = (entity: Models.IEntity): Models.EntityType => {
	return Object.prototype.hasOwnProperty.call(entity, 'primaryEmail') ||
		Object.prototype.hasOwnProperty.call(entity, 'firstName') ||
		Object.prototype.hasOwnProperty.call(entity, 'lastName')
		? 'principal'
		: 'company';
};

export const createRichContentWithReferencedEntities = <T extends Models.IRichContent>(entities: Models.IEntity[]) => {
	const richContent: Models.IRichContent = {
		referencedEntities: {
			companies: [],
			contacts: [],
			users: [],
		},
	};

	for (const entity of entities) {
		const type = getTypeForEntity(entity);
		if (type === 'company') {
			const fullCompany = entity as Models.ICompany;
			const company: Models.ICompany = {
				companyName: fullCompany.companyName,
				id: fullCompany.id,
			};
			if (fullCompany.logoUrl) {
				company.logoUrl = fullCompany.logoUrl;
			}
			if (fullCompany.emailDomain) {
				company.emailDomain = fullCompany.emailDomain;
			}
			// @ts-ignore
			// @ts-ignore
			richContent.referencedEntities.companies.push({
				entity: company,
				method: Models.RichContentReferenceMethod.Explicit,
			});
		} else {
			const fullContact = entity as Models.IContact;
			const contact: Models.IContact = {
				firstName: fullContact.firstName,
				id: fullContact.id,
				lastName: fullContact.lastName,
				primaryEmail: fullContact.primaryEmail,
				profilePic: fullContact.profilePic,
			};
			// @ts-ignore
			// @ts-ignore
			richContent.referencedEntities.contacts.push({
				entity: contact,
				method: Models.RichContentReferenceMethod.Explicit,
			});
		}
	}

	return richContent as T;
};

export const tryParseBoolean = (input: string, defaultValue = false) => {
	if (input) {
		const lowerInput = input.toLowerCase();
		if (lowerInput === 'true' || lowerInput === '1') {
			return true;
		} else if (lowerInput === 'false' || lowerInput === '0') {
			return false;
		}
		return defaultValue;
	}
	return defaultValue;
};

export const googleBusinessReviewsCommandString = 'google-business-reviews';

export const QueryCommands = {
	Campaign: {
		parse: (query: string): Models.IQueryStringCommand => {
			if (query) {
				const params: Models.IDictionary<string> = getQueryStringParams(query) || {};
				const command: string = (params.cmd || '').toLowerCase();
				if (command) {
					switch (command) {
						case 'campaign-approval': {
							const createRichContentCommand: Models.IBulkEmailApprovalCommand = {
								campaignId: params.id,
								command,
							};
							return createRichContentCommand;
						}
						default: {
							break;
						}
					}
				}
			}
			// @ts-ignore
			return null;
		},
	},
	Contact: {
		parse: (query: string): Models.IQueryStringCommand => {
			if (query) {
				const params: Models.IDictionary<string> = getQueryStringParams(query) || {};
				const command: string = (params.cmd || '').toLowerCase();
				if (command) {
					switch (command) {
						case 'crc': {
							const createRichContentCommand: Models.ICreateEntityRichContentCommand = {
								command,
								refs: {
									companies: Array.from(new Set<string>((params.companies || '').split(',').filter(x => !!x))),
									contacts: Array.from(new Set<string>((params.contacts || '').split(',').filter(x => !!x))),
								},
								type: (params.type as 'note' | 'actionItem') || 'note',
							};
							return createRichContentCommand;
						}
						default: {
							break;
						}
					}
				}
			}
			// @ts-ignore
			return null;
		},
	},
	Dashboard: {
		parse: (query: string): Models.IQueryStringCommand => {
			const params: Models.IDictionary<string> = getQueryStringParams(query || '') || {};
			if (query) {
				const command: string = (params.cmd || '').toLowerCase();
				if (command) {
					switch (command) {
						case 'rm': {
							const recentMeetingCommand: Models.IRecentMeetingCommand = {
								command,
								id: params.id,
								itemSignature: params.itemSignature,
							};
							// for now, we copy over itemSignature to id (minus "noteReminder-" prefix) if id does not exist. Id will be the prop to check from now on
							recentMeetingCommand.id =
								recentMeetingCommand.id || (recentMeetingCommand.itemSignature || '').replace(/noteReminder-/i, '');
							return recentMeetingCommand;
						}
						case 'kit': {
							const contactId = params.contactId;
							if (contactId) {
								const keepInTouchCommand: Models.IKeepInTouchCommand = {
									command,
									contactId,
									isSuggestion: tryParseBoolean(params.suggestion),
								};

								const actionItemId = params.actionItemId;
								if (actionItemId) {
									keepInTouchCommand.actionItemId = actionItemId;
								}

								return keepInTouchCommand;
							}
							break;
						}
						case 'otr': {
							const ownerTagReportCommand: Models.IOwnerTagReportCommand = {
								command,
								reportId: params.id,
							};

							return ownerTagReportCommand;
						}
						case 'send-individual':
						case 'kit-reminders': {
							return { command };
						}
						case 'feedfilter': {
							const lowerFilterValues = Models.AllDashboardFeedFilterValues.map(x => x.toLocaleLowerCase());
							const filters = (params.typeOf || '')
								.split(',')
								.filter(
									x => !!x && lowerFilterValues.indexOf(x.toLocaleLowerCase()) >= 0
								) as Models.DashboardFeedFilterType[];
							if (filters.length > 0) {
								const feedFilterCommand: Models.IDashboardFeedFilterCommand = {
									activeFilters: filters,
									command,
								};
								return feedFilterCommand;
							}
							break;
						}
						case googleBusinessReviewsCommandString: {
							const reviewId = params.reviewId;
							const includeGeneratedReply = params.includeGeneratedReply?.toLowerCase() !== 'false';
							const googleBusinessReviewsCommand: Models.GoogleBusinessReviewsCommand = {
								command,
								includeGeneratedReply,
								reviewId,
							};
							return googleBusinessReviewsCommand;
						}
						default: {
							break;
						}
					}
				}
			}

			// TODO: legacy recent meeting support... remove when ready
			const recentMeeting: string = params.recentMeeting;
			if (recentMeeting) {
				const recentMeetingCommand: Models.IRecentMeetingCommand = {
					command: 'rm',
					id: (recentMeeting || '').replace(/noteReminder-/i, ''),
					itemSignature: recentMeeting,
				};
				return recentMeetingCommand;
			}

			// @ts-ignore
			return null;
		},
	},
	People: {
		// @ts-ignore
		parse: (query: string): Models.IQueryStringCommand => {
			const params: Models.IDictionary<string> = getQueryStringParams(query || '') || {};
			if (query) {
				const command: string = (params.cmd || '').toLowerCase();
				if (command) {
					switch (command) {
						case 'tagalert': {
							const id = params.id;
							if (id) {
								const tagAlertCommand: Models.IViewTagAlertContactsCommand = {
									command,
									id,
								};
								const op = params.op;
								if (op) {
									tagAlertCommand.op = op as any;
								}
								return tagAlertCommand;
							} else {
								return {
									command,
								};
							}
						}
						case 'tag': {
							const id = params.id;
							if (id) {
								const tagAlertCommand: Models.IViewTagContactsCommand = {
									command,
									id,
									op: 'view',
								};

								const op = params.op;
								if (op) {
									tagAlertCommand.op = op as any;
								}
								return tagAlertCommand;
							}

							return {
								command,
							};
						}
						default: {
							break;
						}
					}
				}
			}
		},
	},
};

export const excludeKeysOf = <T, K extends keyof T>(object: T, excludedKeys?: K[]): Pick<T, Exclude<keyof T, K>> => {
	if (object) {
		// create shallow copy
		const result: { [P in K]: T[P] } = { ...(object as any) };

		// remove keys
		const keys = excludedKeys || [];
		keys.forEach(x => delete result[x]);
		return result as any as Pick<T, Exclude<keyof T, K>>;
	}

	// @ts-ignore
	return null;
};

export const selectKeysOf = <T, K extends keyof T>(object: T, selectKeys?: K[]): { [P in K]: T[P] } => {
	if (object) {
		// create shallow copy
		const result: { [P in K]: T[P] } = {} as any;

		// remove keys
		const keys = selectKeys || [];
		keys.forEach(x => (result[x] = object[x]));
		return result;
	}

	// @ts-ignore
	return null;
};

export interface IPropertyPathSearchOptions {
	followFunctionValues?: boolean;
}

export const getValueAtPropertyPath = <T = any>(
	object: any,
	propertyPath: string,
	options?: IPropertyPathSearchOptions
): T => {
	if (!!object && !!propertyPath) {
		// walk the path
		const comps = propertyPath.split('.').filter(x => !!x);
		if (comps.length > 0) {
			const { followFunctionValues } = options || ({} as IPropertyPathSearchOptions);
			const headComp = comps[0];
			const headCompValue = object[headComp];
			if (comps.length === 1) {
				if (!!followFunctionValues && !!headCompValue && typeof headCompValue === 'function') {
					return (headCompValue as () => T)();
				}
				return headCompValue as T;
			}

			if (headCompValue !== null && headCompValue !== undefined) {
				// continue down the prop path
				// remove firstComp
				comps.splice(0, 1);
				// recurse
				if (!!followFunctionValues && !!headCompValue && typeof headCompValue === 'function') {
					const functionValue = (headCompValue as () => any)();
					return getValueAtPropertyPath<T>(functionValue, comps.join('.'), options);
				}
				return getValueAtPropertyPath<T>(headCompValue, comps.join('.'), options);
			}
		}
	}

	return null as T;
};

/** E.g. <lev-content><div>This is a test!</div></lev-content> */
export const RawRichTextContentDocumentRootElementName = 'lev-content';

export const DefaultRawRichTextContentBlockStyleAttribute =
	'margin:0;padding:0;font-size:14px;font-family:arial,sans-serif;';

export const createRawRichTextContentStateWithHtmlStringValue = (contentHtmlStringValue: string) => {
	const rawContentState: Models.IRawRichTextContentState = {
		document: `<${RawRichTextContentDocumentRootElementName}>${
			contentHtmlStringValue || ''
		}</${RawRichTextContentDocumentRootElementName}>`,
		documentVersion: 1,
	};
	return rawContentState;
};

export const rawRichTextContentStateHasContent = (contentState?: Models.IRawRichTextContentState) => {
	if (contentState?.document) {
		// test for empty RawRichTextContentDocumentRootElementName tags, ... e.g <lev-content></lev-content>
		return !new RegExp(
			`<\\s*${RawRichTextContentDocumentRootElementName}[^>]*?>\\s*</\\s*${RawRichTextContentDocumentRootElementName}[^>]*?>`,
			'igm'
		).test(contentState.document);
	}
	return false;
};

/**
 * Convert plain text to raw rich text content
 *
 * @param text
 * @param fontFamily (Optional) for example (no quotes): "arial,sans-serif"
 */
export const createRawRichTextContentStateWithText = (text?: string, fontFamily?: string) => {
	const styleAttribs = fontFamily
		? DefaultRawRichTextContentBlockStyleAttribute.replace(/font-family:[^;]+;/i, `font-family:${fontFamily};`)
		: DefaultRawRichTextContentBlockStyleAttribute;
	const htmlStringValue = (text || '').split(/[\n|\r|\n\r]/).reduce((prev, x) => {
		return `${prev}<p style="${styleAttribs}">${x}</p>`;
	}, '');
	return createRawRichTextContentStateWithHtmlStringValue(htmlStringValue);
};

export const getContentHtmlStringValueFromRawRichTextContentState = (
	rawContentState?: Models.IRawRichTextContentState
) => {
	if (!!rawContentState && !!rawContentState.document) {
		const LevContentRegExp = new RegExp(
			`<${RawRichTextContentDocumentRootElementName}>([\\s\\S]*)</${RawRichTextContentDocumentRootElementName}>`,
			'igm'
		);
		const matchs = LevContentRegExp.exec(rawContentState.document);
		if (!!matchs && matchs.length > 1) {
			return matchs[1] || '';
		}
	}
	return '';
};

export const apiModelTypeToAutomationStepType = (modelType: Models.ApiModelType) => {
	switch (modelType) {
		case 'AddTagAutomationStep': {
			return Models.AutomationStepType.AddTag;
		}
		case 'RemoveTagAutomationStep': {
			return Models.AutomationStepType.RemoveTag;
		}
		case 'ActionItemAutomationStep': {
			return Models.AutomationStepType.ActionItem;
		}
		case 'EmailAutomationStep': {
			return Models.AutomationStepType.Email;
		}
		case 'TextingAutomationStep': {
			return Models.AutomationStepType.Texting;
		}
		case 'SwitchAutomationStep': {
			return Models.AutomationStepType.Switch;
		}
		case 'NoActionAutomationStep': {
			return Models.AutomationStepType.NoAction;
		}
		default: {
			break;
		}
	}
};

export const isValidAutomationTrigger = (trigger: Models.IAutomationTrigger) => {
	switch (trigger?._type) {
		case 'TextingCampaignAutomationTrigger': {
			return true;
		}
		case 'TagAutomationTrigger': {
			const t = trigger as Models.ITagAutomationTrigger;
			return !!t.tag;
		}
		case 'NewLeadAutomationTrigger': {
			const t = trigger as Models.INewLeadAutomationTrigger;
			// @ts-ignore
			if (t.emailScannerIds?.length > 0) {
				// @ts-ignore
				if (t.emailScannerIds.find(x => x === Models.EmailScannerId.Custom)) {
					// @ts-ignore
					return t.customEmailScannerIds?.length > 0;
				}
				return true;
			}
			return false;
		}
		case 'NewClientAutomationTrigger': {
			const t = trigger as Models.INewClientAutomationTrigger;
			// @ts-ignore
			return t.clientType?.length > 0;
		}
		case 'ResourceSelectorAutomationTrigger': {
			const t = trigger as Models.IResourceSelectorAutomationTrigger;
			return !!t.resourceSelector;
		}
		case Models.AutomationTriggerType.NewDonor: {
			return true;
		}
		default: {
			return false;
		}
	}
};

export const isAdmin = (user?: Models.IUser) => {
	if (!user?.role) {
		return false;
	}
	return user.role.toLocaleLowerCase().indexOf('admin') >= 0;
};

export const isImpersonatingAccountAdmin = (impersonationContext: Models.IImpersonationContext) => {
	return impersonationContext.user ? isAdmin(impersonationContext.user) : !!impersonationContext.account;
};

export const copySlotMachineConfig = (from: Models.ISlotMachineConfig, to: Models.ISlotMachineConfig) => {
	if (from && to) {
		from.payTable?.forEach(fromPayLine => {
			const toPayLineIndex = to.payTable?.findIndex(
				x => x.symbol === fromPayLine.symbol && x.symbolCount === fromPayLine.symbolCount
			);
			if (toPayLineIndex >= 0) {
				to.payTable[toPayLineIndex] = {
					...to.payTable[toPayLineIndex],
					...fromPayLine,
				};
			}
		});
	}
};

export const getCustomCardDisplayName = (cardType: Models.ResourceSelectorId, account: Models.IAccount) => {
	return `Upcoming ${account?.preferences?.resourceSelectorSettings?.[cardType]?.displayName}`;
};

export const isDevOrTestEnvironment = process.env.BUGSNAG_ENV === 'test' || process.env.BUGSNAG_ENV === 'development';
export const isProdEnvironment = process.env.BUGSNAG_ENV === 'production';
