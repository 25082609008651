import { computed, observable } from 'mobx';
import { ContactViewModel } from '../../ViewModels';
import * as Api from '../../sdk';
import { UserSessionContext, ViewModel } from '../index';

export class KeyFactsViewModel extends ViewModel {
	public getAllLinesOfBusiness = async () => {
		const opResult = await this.userSession.webServiceHelper.callWebServiceAsync<string[]>(
			this.composeApiUrl({ urlPath: 'KeyFact/LineOfBusiness' }),
			'GET'
		);

		if (!opResult.success) {
			throw opResult;
		}

		return opResult.value;
	};
}

export class UpcomingKeyFactViewModel {
	@observable.ref private mUpcomingKeyFact: Api.IUpcomingKeyFact;
	private mContact: ContactViewModel;

	constructor(userSession: UserSessionContext, upcomingKeyFact: Api.IUpcomingKeyFact) {
		this.mUpcomingKeyFact = upcomingKeyFact;
		this.mContact = new ContactViewModel(userSession, upcomingKeyFact.contact);
	}

	@computed
	public get id() {
		return this.mUpcomingKeyFact.id;
	}

	@computed
	public get contact() {
		return this.mContact;
	}

	@computed
	public get keyFact() {
		return this.mUpcomingKeyFact.keyFact;
	}

	@computed
	public get isBirthday() {
		return this.mUpcomingKeyFact?.keyFact?.keyDate?.kind === Api.KeyDateKind.Birthday;
	}

	@computed
	public get isAnniversary() {
		return this.mUpcomingKeyFact?.keyFact?.keyDate?.kind === Api.KeyDateKind.Anniversary;
	}

	@computed
	public get isRenewal() {
		return this.mUpcomingKeyFact?.keyFact?.keyDate?.kind === Api.KeyDateKind.Renewal;
	}

	public toJs = () => {
		return this.mUpcomingKeyFact;
	};
}

export class UpcomingAcknowledgeableKeyFactsViewModel {
	@observable.ref private mModel: Api.IUpcomingBirthdays | Api.IUpcomingAnniversaries;
	private mKeyFacts: UpcomingKeyFactViewModel[];

	constructor(userSession: UserSessionContext, model: Api.IUpcomingBirthdays | Api.IUpcomingAnniversaries) {
		this.mModel = model;
		this.mKeyFacts = model.keyFacts.map(x => new UpcomingKeyFactViewModel(userSession, x));
	}

	@computed
	public get id() {
		return this.mModel.id;
	}

	@computed
	public get totalUpcoming() {
		return this.mModel.totalUpcoming;
	}

	@computed
	public get keyFacts() {
		return this.mKeyFacts;
	}

	public toJs = () => {
		return this.mModel;
	};
}
