import { ContactFilter, FilterOperator, FilterRequestBase, IFilterCriteria, IUserReference } from '.';
import { AutomationStatus, CommunicationStats } from './Automation';
import { ContactReference } from './Contact';

export enum TextCampaignFilterProperty {
	CreatorId = 'CreatorId',
	Status = 'Status',
	DueDate = 'DueDate',
}

export type TextCampaignFilter = {
	criteria: TextCampaignCriteria[];
	op: FilterOperator;
};

export type TextCampaignCriteria = {
	op?: FilterOperator;
	property?: TextCampaignFilterProperty;
	criteria?: TextCampaignCriteria[];
	value?: string;
	values?: Record<string, string>;
};

export type TextCampaignReportView = {
	bulkAutomationId: string;
	campaignName: string;
	canReschedule: boolean;
	contactFilter?: ContactFilter;
	contacts?: ContactReference[];
	creator: IUserReference;
	dueDate: string;
	remainingSends?: number;
	stats?: CommunicationStats;
	status?: AutomationStatus;
	totalContacts: number;
};

export enum TextMessageFilterProperty {
	All = 'All',
	Conversation = 'Conversation',
	Status = 'Status',
	Priority = 'Priority',
	AutomationTemplate = 'AutomationTemplate',
	BulkAutomation = 'BulkAutomation',
}

export type TextMessagesCriteria = IFilterCriteria<TextMessageFilterProperty>;

export type TextMessagesFilter = FilterRequestBase<TextMessageFilterProperty, TextMessagesCriteria>;

export type TextMessagesExportRequest = {
	endDate?: Date;
	filter: TextMessagesFilter;
	startDate?: Date;
};
