import { baseStyles } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {},
	hiddenInput: {
		display: 'none',
	},
	icon: {
		marginRight: 5,
		minWidth: 16,
	},
	selectMenu: {
		maxHeight: 200,
		maxWidth: 300,
		overflowY: 'auto',
	},
	selectTrigger: {
		height: 41,
		...baseStyles.textField,
	},
});
