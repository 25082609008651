import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { ChangeEvent, Component, MouseEvent, PureComponent, ReactNode } from 'react';
import shallowEqual from 'shallowequal';
import { baseStyleSheet } from '../../styles/styles';
import { Dropdown } from '../Dropdown';
import { DisclosureIcon } from '../svgs/icons/DisclosureIcon';
import { styleSheet } from './styles';

export type SelectBoxOptionTitleRenderFunc = (selected?: boolean) => JSX.Element | null;

export interface ISelectBoxOption<TRepresentedObject> {
	id?: string;
	isDefault?: boolean;
	title?: ReactNode | SelectBoxOptionTitleRenderFunc;
	value?: TRepresentedObject;
}

interface IProps<TRepresentedObject = any> {
	contentClassName?: string;
	className?: string;
	disabled?: boolean;
	isMomentary?: boolean;
	filterClassName?: string;
	filterOptions?: boolean;
	filterPlaceHolder?: string;
	leftAccessory?: ReactNode;
	menuClassName?: string;
	menuPopupPosition?: 'bottom' | 'top';
	menuStyles?: StyleDeclarationValue[] | StyleDeclarationValue;
	onOpenChanged?(isOpen: boolean): void;
	onRenderSelectedOptionTitle?(selectedOption: ISelectBoxOption<TRepresentedObject>): ReactNode;
	onSelectionChanged?(selectedOption: ISelectBoxOption<TRepresentedObject>): void;
	onTriggerClicked?(e: MouseEvent<HTMLElement>, willOpen: boolean): void;
	openOnHover?: boolean;
	optionClassName?: string;
	options?: ISelectBoxOption<TRepresentedObject>[];
	optionStyles?: StyleDeclarationValue[];
	placeholder?: ReactNode;
	rightAccessory?: ReactNode;
	searchValue?: string;
	selectedOption?: ISelectBoxOption<TRepresentedObject>;
	triggerCaretFillColor?: string;
	triggerClassName?: string;
	triggerStyles?: StyleDeclarationValue[];
	waypoint?: ReactNode;
}

interface IState<TRepresentedObject = any> {
	isOpen?: boolean;
	options?: ISelectBoxOption<TRepresentedObject>[];
	placeholder?: ReactNode;
	selectedOption?: ISelectBoxOption<TRepresentedObject>;
	value?: string;
}

export class SelectBox<TRepresentedObject = any> extends Component<
	IProps<TRepresentedObject>,
	IState<TRepresentedObject>
> {
	public state: IState<TRepresentedObject> = {};

	public UNSAFE_componentWillMount() {
		this.setState(this.getNextState(this.props));
	}

	public UNSAFE_componentWillReceiveProps(nextProps: IProps<TRepresentedObject>) {
		this.setState(this.getNextState(nextProps));
	}

	public shouldComponentUpdate(nextProps: IProps<TRepresentedObject>, nextState: IState<TRepresentedObject>) {
		return !shallowEqual(nextProps, this.props) || !shallowEqual(this.state, nextState);
	}

	private currentSelectedRef = React.createRef<HTMLButtonElement>();

	public render() {
		const {
			contentClassName,
			className,
			disabled,
			isMomentary,
			leftAccessory,
			menuClassName,
			menuPopupPosition,
			menuStyles,
			onRenderSelectedOptionTitle,
			openOnHover,
			optionClassName,
			optionStyles,
			rightAccessory,
			searchValue,
			triggerCaretFillColor,
			triggerClassName,
			triggerStyles,
			waypoint,
			filterOptions,
			filterPlaceHolder = '',
			filterClassName,
		} = this.props;
		const { placeholder, selectedOption, isOpen, value = '' } = this.state;
		const valueRegex = new RegExp(value, 'i');
		let anchorSelectedOptionTitle: ReactNode | SelectBoxOptionTitleRenderFunc;
		if (!isMomentary && !!selectedOption) {
			anchorSelectedOptionTitle =
				(onRenderSelectedOptionTitle ? onRenderSelectedOptionTitle(selectedOption) : null) || selectedOption.title;
		}

		if (!anchorSelectedOptionTitle) {
			anchorSelectedOptionTitle = placeholder;
		}
		const anchorSelectedOptionTitleElement =
			typeof anchorSelectedOptionTitle === 'function'
				? (anchorSelectedOptionTitle as SelectBoxOptionTitleRenderFunc)(true)
				: anchorSelectedOptionTitle;

		return (
			<div>
				<Dropdown
					anchor={
						<button
							className={`
							${css(
								styleSheet.selectBoxtriggerButton,
								disabled && styleSheet.selectBoxtriggerButtonDisabled,
								...(triggerStyles || [])
							)} ${triggerClassName || ''}
							`}
							onClick={this.onTriggerClicked}
						>
							{leftAccessory ?? null}
							{searchValue ? (
								<>
									<div className={css(styleSheet.searchValue)}>{searchValue}</div>
									<div style={{ flexGrow: 1 }} />
								</>
							) : (
								<div className='select-box-trigger-content'>{anchorSelectedOptionTitleElement}</div>
							)}

							<div className={css(styleSheet.selectBoxtriggerRightAccessory)}>
								{rightAccessory ?? null}
								<DisclosureIcon className={css(styleSheet.selectBoxtriggerCaret)} fillColor={triggerCaretFillColor} />
							</div>
						</button>
					}
					className={`${css(styleSheet.selectBox)} ${className || ''} ${
						disabled ? css(styleSheet.selectBoxDisabled) : ''
					}`}
					contentClassName={`${contentClassName ?? ''}`}
					contentInnerClassName={`${css(styleSheet.selectBoxContent)} ${menuClassName}`}
					contentInnerStyle={menuStyles}
					contentPositionY={menuPopupPosition || undefined}
					isOpen={isOpen}
					onOpenChanged={this.onOpenChanged}
					openOnHover={!!openOnHover}
				>
					<>
						{!!filterOptions && (
							<div className={`${css(styleSheet.selectBoxFilterWrap)} select-box-filter-wrap ${filterClassName || ''}`}>
								<input
									type='text'
									className={`${css(styleSheet.selectBoxFilterInput)} select-box-filter-input`}
									value={value}
									onChange={this.setValue}
									autoFocus={true}
									placeholder={filterPlaceHolder}
								/>
							</div>
						)}
						{!!this.state.options &&
							this.state.options
								.filter((item: ISelectBoxOption<TRepresentedObject>) => {
									if (!value || valueRegex.test(String(item.title))) {
										return true;
									}
								})
								.map((option, i) => {
									const isSelected = !!selectedOption && option === selectedOption;
									return (
										<button
											ref={isSelected ? this.currentSelectedRef : undefined}
											className={`${css(
												styleSheet.selectBoxMenuContentOption,
												baseStyleSheet.truncateText,
												...(optionStyles || [])
											)} select-box-menu-content-option ${
												isSelected ? 'select-box-menu-content-selected-option' : ''
											} ${optionClassName || ''}`}
											key={option.id || i}
											onClick={this.onItemClicked(option)}
										>
											{typeof option.title === 'function'
												? (option.title as SelectBoxOptionTitleRenderFunc)(isSelected)
												: option.title}
										</button>
									);
								})}
						{waypoint}
					</>
				</Dropdown>
			</div>
		);
	}

	private setValue = (e: ChangeEvent<HTMLInputElement>): void => {
		this.setState({ value: e?.target?.value });
	};

	private onOpenChanged = (isOpen: boolean) => {
		const { onOpenChanged } = this.props;
		this.setState({
			isOpen,
			value: '',
		});
		if (onOpenChanged) {
			onOpenChanged(isOpen);
		}
	};

	private onItemClicked = (selectedOption: ISelectBoxOption<TRepresentedObject>) => () => {
		const { onOpenChanged, onSelectionChanged } = this.props;
		if (onSelectionChanged) {
			this.setState({
				isOpen: false,
				value: '',
			});
			onSelectionChanged(selectedOption);
		} else {
			this.setState({
				isOpen: false,
				selectedOption,
				value: '',
			});
		}

		if (onOpenChanged) {
			onOpenChanged(false);
		}
	};

	private onTriggerClicked = (e: MouseEvent<HTMLElement>) => {
		if (!e.isDefaultPrevented() && !e.isPropagationStopped()) {
			const { onTriggerClicked, onOpenChanged } = this.props;
			const nextOpenState = !this.state.isOpen;
			if (onTriggerClicked) {
				onTriggerClicked(e, nextOpenState);
			}

			if (!e.isDefaultPrevented() && !e.isPropagationStopped()) {
				e.stopPropagation();
				e.preventDefault();

				this.setState(
					{
						isOpen: nextOpenState,
					},
					() => {
						if (this.state.isOpen) {
							requestAnimationFrame(() =>
								this.currentSelectedRef.current?.scrollIntoView?.({
									behavior: 'instant',
									block: 'nearest',
								})
							);
						}
					}
				);

				if (onOpenChanged) {
					onOpenChanged(nextOpenState);
				}
			}
		}
	};

	private getNextState = (nextProps: IProps<TRepresentedObject>) => {
		const nextState: IState<TRepresentedObject> = {};
		if (nextProps === this.props || !shallowEqual(nextProps, this.props)) {
			nextState.options = nextProps.options;
			nextState.selectedOption = nextProps.selectedOption || (nextProps.options || []).find(x => x.isDefault);
			nextState.placeholder = nextProps.placeholder;
		}
		return nextState;
	};
}

export class DefaultSelectBox<TRepresentedObject = any> extends PureComponent<IProps<TRepresentedObject>> {
	public render() {
		const { className, menuClassName, triggerClassName, triggerStyles, menuStyles, ...restProps } = this.props;
		return (
			<SelectBox
				{...restProps}
				className={`${css(styleSheet.selectBox)} ${className || ''}`}
				menuStyles={
					Array.isArray(menuStyles) ? [styleSheet.selectBoxMenu, ...menuStyles] : [styleSheet.selectBoxMenu, menuStyles]
				}
				menuClassName={menuClassName}
				triggerClassName={`${css(styleSheet.selectBoxTrigger, ...(triggerStyles || []))} ${triggerClassName || ''}`}
			/>
		);
	}
}
