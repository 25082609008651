import { baseStyles } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {},
	selectMenu: {
		maxHeight: 200,
		maxWidth: 300,
		overflowY: 'auto',
	},
	selectTrigger: {
		...baseStyles.textField,
	},
});
