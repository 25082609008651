export class OAuthSignIn {
	private oktaUri: string;
	private apiUri: string;
	constructor(apiUri: string) {
		this.oktaUri = 'https://dev-473009.oktapreview.com';
		this.apiUri = apiUri;
	}

	public async validateUsernameAndPassword(username: string, password: string): Promise<string> {
		const request = this.buildFetchRequest(this.oktaUri, 'api/v1/authn', 'POST', {
			options: {
				multiOptionalFactorEnroll: false,
				warnBeforePasswordExpired: false,
			},
			password,
			username,
		});
		const res = await fetch(request);
		const json = await res.json();

		if (res.status > 199 && res.status < 299) {
			return json.sessionToken;
		}

		throw new Error('Invalid username or password.');
	}

	public getAuthorizeUrl(requestQueryString: string, sessionToken: string) {
		if (!requestQueryString.startsWith('?')) {
			requestQueryString = '?' + requestQueryString;
		}

		const query = `${requestQueryString}&sessionToken=${sessionToken}`;
		return `${this.apiUri}/oauth/public/v1/authorize${query}`;
	}

	private buildFetchRequest = (baseUrl: string, path: string, method: string, jsonObj: any) => {
		// Define headers
		const headers = new Headers();
		headers.append('Content-Type', 'application/json; charset=utf-8');
		headers.append('Accept', 'application/json');
		const url = `${baseUrl}/${path}`;
		const config: RequestInit = {
			headers,
			method,
			mode: 'cors',
			redirect: 'follow',
		};

		if (method.toLowerCase() !== 'get' && method.toLowerCase() !== 'delete' && jsonObj !== null) {
			config.body = JSON.stringify(jsonObj);
		}

		const request = new Request(url, config);
		return request;
	};
}
