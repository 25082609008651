import { action, computed, observable } from 'mobx';
import moment from 'moment';
import { UserSessionContext, ViewModel } from '../';
import { VmUtils } from '../..';
import { ContactViewModel } from '../../ViewModels';
import * as Api from '../../sdk';
import { FilteredPageCollectionController, IBaseObservablePageCollectionControllerOptions } from '../Collections';

export class AutomationViewModel<
	TUserSession extends UserSessionContext = UserSessionContext,
	TAutomation extends Api.IAutomation = Api.IAutomation,
> extends ViewModel {
	@observable.ref protected mAutomation: TAutomation;
	@observable.ref protected mLoadingPromise: Promise<Api.IOperationResult<TAutomation>>;

	constructor(userSession: TUserSession, automation: Partial<TAutomation>) {
		super(userSession);
		this.setModel = this.setModel.bind(this);
		this.setModel(automation as TAutomation);
	}

	@computed
	public get id() {
		return this.mAutomation?.id;
	}

	@computed
	public get isBusy() {
		return this.busy || !!this.isLoading;
	}

	@computed
	public get isLoading() {
		return this.loading || !!this.mLoadingPromise;
	}

	@computed
	public get name() {
		return this.mAutomation?.name;
	}

	@computed
	public get status() {
		return this.mAutomation?.status;
	}

	@computed
	public get templateId() {
		return this.mAutomation?.templateId;
	}

	@computed
	public get anchorMoment() {
		return this.mAutomation?.anchorDate ? moment(this.mAutomation?.anchorDate) : null;
	}

	@computed
	public get bulkAutomationId() {
		return this.mAutomation?.bulkAutomationId;
	}

	@computed
	public get contactId() {
		return (this.mAutomation?.contactIds || [])[0];
	}

	@computed
	public get steps() {
		return this.mAutomation?.steps;
	}

	@computed
	public get lastCompletedStepIndex() {
		const step = [...(this.mAutomation?.steps || [])]?.reverse().find(x => x.isFinished);
		return step ? this.mAutomation.steps.indexOf(step) || -1 : -1;
	}

	@computed
	public get numberOfCompletedSteps() {
		return (this.mAutomation?.steps || []).reduce((result, x) => result + (x.isFinished ? 1 : 0), 0);
	}

	public toJs() {
		return this.mAutomation;
	}

	@action
	public load() {
		if (!this.isBusy && !this.mLoadingPromise) {
			this.mLoadingPromise = new Promise<Api.IOperationResult<TAutomation>>((resolve, reject) => {
				const onFinish = action((opResult: Api.IOperationResult<TAutomation>) => {
					this.mLoadingPromise = null;
					if (opResult.success) {
						this.mAutomation = opResult.value;
						resolve(opResult);
					} else {
						reject(opResult);
					}
				});
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults(
					this.composeApiUrl({ urlPath: `Automation/${this.mAutomation?.id}` }),
					'GET',
					null,
					onFinish,
					onFinish
				);
			});
		}

		return this.mLoadingPromise;
	}

	@action
	public cancel() {
		if (!this.isBusy) {
			this.busy = true;
			return new Promise<Api.IOperationResult<Api.IAutomation>>((resolve, reject) => {
				const onFinish = action((opResult: Api.IOperationResult<Api.IAutomation>) => {
					this.busy = false;
					if (opResult.success) {
						resolve(opResult);
					} else {
						reject(opResult);
					}
				});
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults(
					this.composeApiUrl({ urlPath: `Automation/${this.mAutomation?.id}` }),
					'DELETE',
					null,
					onFinish,
					onFinish
				);
			});
		}
	}

	@action
	public setDueDateForStepStatus(stepStatus: Api.IAutomationStepStatus, dueDateStringValue?: string) {
		if (!this.isBusy) {
			this.busy = true;
			return new Promise<Api.IOperationResult<Api.IAutomation>>((resolve, reject) => {
				const onFinish = action((opResult: Api.IOperationResult<Api.IAutomation>) => {
					this.busy = false;
					if (opResult.success) {
						const steps = [...(this.mAutomation?.steps || [])];
						const matchingStep = steps.find(x => x.id === stepStatus?.id);
						if (matchingStep) {
							matchingStep.dueDate = dueDateStringValue;
							this.mAutomation = {
								...this.mAutomation,
								steps,
							};
						}
						resolve(opResult);
					} else {
						reject(opResult);
					}
				});
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults(
					this.composeApiUrl({
						queryParams: dueDateStringValue ? { dueDate: dueDateStringValue } : undefined,
						urlPath: `Automation/Step/${stepStatus.id}/DueDate`,
					}),
					'PUT',
					null,
					onFinish,
					onFinish
				);
			});
		}
	}

	@action
	public cancelStepForStepStatus(stepStatus: Api.IAutomationStepStatus) {
		if (!this.isBusy) {
			this.busy = true;
			return new Promise<Api.IOperationResult<Api.IAutomationStepStatus>>((resolve, reject) => {
				const onFinish = action((opResult: Api.IOperationResult<Api.IAutomationStepStatus>) => {
					this.busy = false;
					if (opResult.success) {
						const steps = [...(this.mAutomation?.steps || [])];
						steps.splice(
							this.mAutomation?.steps?.findIndex(x => x.id === stepStatus.id),
							1,
							opResult.value
						);
						this.mAutomation = {
							...this.mAutomation,
							steps,
						};
						resolve(opResult);
					} else {
						reject(opResult);
					}
				});
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults(
					this.composeApiUrl({ urlPath: `Automation/Step/${stepStatus.id}` }),
					'DELETE',
					null,
					onFinish,
					onFinish
				);
			});
		}
	}

	@action
	public updateStepStatus = (stepStatus: Api.IAutomationStepStatus) => {
		const index = this.mAutomation?.steps?.findIndex(x => x.id === stepStatus.id);
		if (index >= 0) {
			const steps = [...this.mAutomation.steps];
			steps.splice(index, 1, stepStatus);
			this.mAutomation = {
				...this.mAutomation,
				steps,
			};
		}
	};

	@action
	public start() {
		if (!this.isBusy) {
			this.busy = true;
			return new Promise<Api.IOperationResult<TAutomation>>((resolve, reject) => {
				const onFinish = action((opResult: Api.IOperationResult<TAutomation>) => {
					this.busy = false;
					if (opResult.success) {
						this.mAutomation = opResult.value;
						resolve(opResult);
					} else {
						reject(opResult);
					}
				});
				this.mUserSession.webServiceHelper.callWebServiceWithOperationResults(
					this.composeApiUrl({ urlPath: `Automation/${this.id}/Start` }),
					'POST',
					null,
					onFinish,
					onFinish
				);
			});
		}
	}

	protected setModel(automation?: TAutomation) {
		this.mAutomation = automation;
	}
}

export class ResourceSelectorAutomationReportViewModel<
	TUserSession extends UserSessionContext = UserSessionContext,
> extends ViewModel<TUserSession> {
	@observable.ref protected mContact: ContactViewModel;
	protected mNowMoment = moment();
	@observable.ref protected mReport: Api.IAutomationReportView;

	constructor(userSession: TUserSession, automationReport: Partial<Api.IAutomationReportView>) {
		super(userSession);
		this.impersonate = this.impersonate.bind(this);
		this.setModel = this.setModel.bind(this);
		this.setModel(automationReport);
	}

	@computed
	public get id() {
		return this.mReport?.id;
	}

	@computed
	public get name() {
		return this.mReport?.name;
	}

	@computed
	public get status() {
		return this.mReport?.status;
	}

	@computed
	public get templateId() {
		return this.mReport?.templateId;
	}

	@computed
	public get anchorMoment() {
		return this.mReport?.anchorDate ? moment(this.mReport?.anchorDate) : null;
	}

	@computed
	public get bulkAutomationId() {
		return this.mReport?.bulkAutomationId;
	}

	@computed
	public get contact() {
		return this.mContact;
	}

	@computed
	public get steps() {
		return this.mReport?.steps;
	}

	@computed
	public get creator() {
		return this.mReport.creator;
	}

	@computed
	public get keyFactsCollection() {
		return this.mReport?.keyFactsCollection;
	}

	@computed
	public get upcomingEmailStep() {
		return this.getUpcomingStepForType('EmailAutomationStep') as Api.IAutomationStepStatus<Api.IEmailAutomationStep>;
	}

	@computed
	public get upcomingTextingStep() {
		return this.getUpcomingStepForType(
			'TextingAutomationStep'
		) as Api.IAutomationStepStatus<Api.ITextingAutomationStep>;
	}

	@computed
	public get upcomingSegment() {
		return this.getUpcomingStepForType('SwitchAutomationStep') as Api.IAutomationStepStatus<Api.ISwitchAutomationStep>;
	}

	@computed
	public get latestCommunicationStep() {
		const processStatus: Api.ProcessStatus | undefined = this.mReport?.status?.status;
		switch (processStatus) {
			case Api.ProcessStatus.Completed: {
				return this.orderedCommunicationSteps.find(
					x =>
						x.status.status === Api.ProcessStatus.Completed ||
						x.status.status === Api.ProcessStatus.Started ||
						x.status.status === Api.ProcessStatus.Failed ||
						x.status.status === Api.ProcessStatus.Skipped
				);
			}
			case Api.ProcessStatus.Cancelled:
			case Api.ProcessStatus.Failed:
			case Api.ProcessStatus.Skipped: {
				return this.orderedCommunicationSteps.find(
					x =>
						x.status.status === Api.ProcessStatus.Cancelled ||
						x.status.status === Api.ProcessStatus.Failed ||
						x.status.status === Api.ProcessStatus.Skipped
				);
			}
			case Api.ProcessStatus.Queued:
			case Api.ProcessStatus.Started: {
				return this.orderedCommunicationSteps.find(
					x =>
						x.status.status === Api.ProcessStatus.Completed ||
						x.status.status === Api.ProcessStatus.Started ||
						x.status.status === Api.ProcessStatus.Queued ||
						x.status.status === Api.ProcessStatus.Skipped ||
						x.status.status === Api.ProcessStatus.Cancelled
				);
			}
			default: {
				break;
			}
		}
	}

	public cancelStepForStepStatus = (stepStatus: Api.IAutomationStepStatus) => {
		const promise = this.toAutomationViewModel()
			.impersonate(this.impersonationContext)
			.cancelStepForStepStatus(stepStatus);
		promise
			?.then(opResult => {
				const index = this.steps?.findIndex(x => x.id === stepStatus.id);
				if (index >= 0) {
					const steps = this.mReport.steps.slice();
					steps.splice(index, 1, {
						...this.steps[index],
						...opResult.value,
					});
					this.setModel({
						...this.mReport,
						steps,
					});
				}
			})
			.catch(() => {
				// nothing
			});
		return promise;
	};

	public setDueDateForStepStatus = (stepStatus: Api.IAutomationStepStatus, dueDateStringValue?: string) => {
		const promise = this.toAutomationViewModel()
			.impersonate(this.impersonationContext)
			.setDueDateForStepStatus(stepStatus, dueDateStringValue);
		promise
			?.then(() => {
				const index = this.steps?.findIndex(x => x.id === stepStatus.id);
				if (index >= 0) {
					const steps = this.mReport.steps.slice();
					steps.splice(index, 1, {
						...this.steps[index],
						dueDate: dueDateStringValue,
					});
					this.setModel({
						...this.mReport,
						steps,
					});
				}
			})
			.catch(() => {
				// nothing
			});
		return promise;
	};

	public toAutomationViewModel = () => {
		return new AutomationViewModel(this.mUserSession, this.mReport).impersonate(this.impersonationContext);
	};

	@action
	public updateWithAutomation = (automationModel: Api.IAutomation) => {
		this.setModel({
			...this.mReport,
			...Api.selectKeysOf(automationModel, ['name', 'status', 'lastModifiedDate']),
		});
	};

	@action
	public updateSteps = (steps: Api.IAutomationStepStatusReportView<Api.IAutomationStep>[]) => {
		this.setModel({
			...this.mReport,
			steps,
		});
	};

	/** Ordered steps by @param dueDate */
	@computed
	protected get orderedCommunicationSteps() {
		const steps = this.steps?.filter(x => {
			const isCommStep = x.step._type === 'EmailAutomationStep' || x.step._type === 'TextingAutomationStep';
			if (!isCommStep) {
				// let the segment steps through
				return x.step._type === 'SwitchAutomationStep';
			}
			return isCommStep;
		});
		return steps.sort((x, y) => {
			const xMoment = moment(x.dueDate);
			const yMoment = moment(y.dueDate);
			if (xMoment.isSame(yMoment)) {
				return 0;
			}
			return xMoment.isBefore(yMoment) ? -1 : 1;
		});
	}

	protected getUpcomingStepForType = (type: Api.ApiModelType) => {
		const steps = this.orderedCommunicationSteps.filter(
			x => x.step._type === type && !moment(x.dueDate).isSameOrBefore(this.mNowMoment)
		);
		return steps[0];
	};

	protected setModel(report: Api.IAutomationReportView) {
		this.mReport = report;
		this.mContact =
			report?.contacts?.length > 0
				? new ContactViewModel(this.userSession, report.contacts[0]).impersonate(this.impersonationContext)
				: null;
	}

	public impersonate(impersonationContext?: Api.IImpersonationContext) {
		super.impersonate(impersonationContext);
		this.mContact?.impersonate(impersonationContext);
		return this;
	}
}

export class ResourceSelectorAutomationReportsViewModel<
	TViewModel extends ResourceSelectorAutomationReportViewModel = ResourceSelectorAutomationReportViewModel,
> extends FilteredPageCollectionController<Api.IAutomationReportView, TViewModel, Api.IAutomationReportRequest> {
	@observable protected mResourceSelectorId: Api.ResourceSelectorId;
	@observable public pageSize = 25;
	@observable public userId: string;
	@observable.ref public dateRange: { startDate?: Date; endDate?: Date };
	@observable.ref public policies: string[];
	protected mUserSession: UserSessionContext;

	constructor(
		userSession: UserSessionContext,
		resourceSelectorId: Api.ResourceSelectorId,
		options?: Partial<IBaseObservablePageCollectionControllerOptions<Api.IAutomationReportView, TViewModel>>
	) {
		super({
			apiPath: () => {
				switch (resourceSelectorId) {
					case Api.ResourceSelectorId.PolicyRenew: {
						return 'reports/automation/policyrenew';
					}
					case Api.ResourceSelectorId.NewClientAutomationsOnHold: {
						return 'reports/automation/newclient';
					}
					case Api.ResourceSelectorId.NewDonorAutomationsOnHold: {
						return 'reports/automation/newdonor';
					}
					case Api.ResourceSelectorId.NewLeadAutomationsOnHold: {
						return 'reports/automation/newlead';
					}
					default: {
						return 'reports/automation/ResourceSelector';
					}
				}
			},
			client: userSession.webServiceHelper,
			...options,
		});
		this.mAutomationTransformer = this.mAutomationTransformer.bind(this);
		this.setTransformer(this.mAutomationTransformer);
		this.executeFetch = this.executeFetch.bind(this);
		this.getNext = this.getNext.bind(this);
		this.mUserSession = userSession;
		this.mResourceSelectorId = resourceSelectorId;
	}

	@computed
	public get resourceSelectorId() {
		return this.mResourceSelectorId;
	}

	@computed
	public get request(): Api.IAutomationReportRequest {
		const request: Api.IAutomationReportRequest = {
			endDate: this.dateRange?.endDate?.toISOString(),
			resourceSelectorId: this.mResourceSelectorId,
			startDate: this.dateRange?.startDate?.toISOString(),
			userId: this.userId,
		};

		switch (this.mResourceSelectorId) {
			case Api.ResourceSelectorId.PolicyRenew: {
				(request as Partial<Api.IRenewalAutomationReportRequest>).linesOfBusiness = this.policies || [];
				delete request.resourceSelectorId;
				break;
			}
			case Api.ResourceSelectorId.NewClientAutomationsOnHold:
			case Api.ResourceSelectorId.NewDonorAutomationsOnHold:
			case Api.ResourceSelectorId.NewLeadAutomationsOnHold: {
				delete request.resourceSelectorId;
				break;
			}
			default: {
				break;
			}
		}

		VmUtils.removeEmptyKvpFromObject(request);
		return request;
	}

	public setDateRange = (range: { startDate?: Date; endDate?: Date }) => {
		this.dateRange = range;
		return this;
	};

	public setPolicies = (policies?: string[]) => {
		this.policies = policies || [];
		return this;
	};

	@action
	public reset() {
		super.reset();
		this.userId = null;
	}

	@action
	public getNext(request = this.request, pageSize?: number, params?: Api.IDictionary) {
		return super.getNext(request, pageSize, params);
	}

	public setUserId = (userId?: string) => {
		this.userId = userId;
		return this;
	};

	protected mAutomationTransformer(report: Api.IAutomationReportView) {
		return new ResourceSelectorAutomationReportViewModel(this.mUserSession, report) as TViewModel;
	}
}
