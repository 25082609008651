import { VmUtils, excludeKeysOf, selectKeysOf } from '@ViewModels';
import * as h from 'history';
import { action, autorun, computed, observable } from 'mobx';
import shallowEqual from 'shallowequal';
import { v4 as uuidgen } from 'uuid';

class BrowserCapabilities {
	// @ts-ignore
	private supportsCssPositionSticky: boolean = null;

	public get supportsStickyPositioning() {
		if (!!document && this.supportsCssPositionSticky === null) {
			const el = document.createElement('a');
			el.style.cssText = 'position:sticky;position:-webkit-sticky;position:-ms-sticky;';
			this.supportsCssPositionSticky = el?.style?.position?.indexOf('sticky') >= 0;
		}

		return this.supportsCssPositionSticky;
	}

	public isMSEdge = (version?: string) => {
		if (!!navigator && !!navigator.userAgent) {
			const regexFormat = `Edge/${!!version || ''}`;
			return !!navigator.userAgent.match(new RegExp(regexFormat));
		}
	};
}

export const Browser = new BrowserCapabilities();

interface IBrowserLocationStateHistoryStackItem extends h.LocationDescriptorObject<any> {
	popped: boolean;
}

export interface IBrowserLocationStateHistory extends h.History<any> {
	goBackToLocationBeforeTrackedHistory?(stopListeningToLocationStateChanges?: boolean): void;
}

const stripHash = (url: string) => {
	if (url) {
		const hashIndex = url.indexOf('#');
		return hashIndex === -1 ? url : url.slice(0, hashIndex);
	}
	return '';
};

export class BrowserLocationStateHistory implements IBrowserLocationStateHistory {
	@observable private mAction: h.Action;
	// @ts-ignore
	@observable private mListening: boolean;
	@observable.ref
	private mHistoryStack: IBrowserLocationStateHistoryStackItem[];

	constructor() {
		this.mAction = 'PUSH';
		this.mHistoryStack = [];
	}

	@computed
	public get isListeningToStateChanges() {
		return this.mListening;
	}

	@computed
	public get action() {
		return this.mAction;
	}

	@computed
	public get historyStack() {
		return this.mHistoryStack;
	}

	@computed
	public get location(): h.Location<any> {
		return ([...this.mHistoryStack].reverse().find(x => !x.popped) || {
			pathname: '',
		}) as h.Location<any>;
	}

	public get length() {
		return (window as any).history.length;
	}

	public go = (n: number) => {
		(window as any).history.go(n);
	};

	public goBack = () => {
		(window as any).history.back();
	};

	public goForward = () => {
		(window as any).history.forward();
	};

	@action
	public goBackToLocationBeforeTrackedHistory = (stopListeningToLocationStateChanges = false) => {
		const stack = this.mHistoryStack || [];
		const numberToPop = stack
			.slice(0, stack.indexOf(this.location as IBrowserLocationStateHistoryStackItem) + 1)
			.filter(x => x.popped === false).length;
		stack.forEach(x => (x.popped = true));
		this.mAction = 'POP';
		if (stopListeningToLocationStateChanges) {
			this.listenToBrowserLocationStateChanges(false);
		} else {
			this.mHistoryStack = [];
		}
		if (numberToPop > 0) {
			this.go(-numberToPop);
		}
	};

	public createHref = (location: h.LocationDescriptorObject<any>) => {
		return `${stripHash((window as any).location.href)}#${h.createPath(location)}`;
	};

	public listenToBrowserLocationStateChanges = (listen: boolean) => {
		if (listen !== this.mListening) {
			this.mHistoryStack = [];
			if (listen) {
				(window as any).addEventListener('popstate', this.onPopState, this);
				this.mListening = true;
			} else {
				(window as any).removeEventListener('popstate', this.onPopState, this);
				this.mListening = false;
			}
		}
	};

	public listen = (listener: h.LocationListener) => {
		return autorun(() => {
			listener(this.location, this.mAction);
		});
	};

	/** NOTE: if typeof pathOrLocation, search and hash are not parsed/supported */
	@action
	public push = (pathOrLocation: h.Path | h.LocationDescriptorObject<any>, state?: any) => {
		const currentLocation = this.location as IBrowserLocationStateHistoryStackItem;
		const stackItem: IBrowserLocationStateHistoryStackItem = this.mPathOrLocationToStackItem(pathOrLocation, state);
		if (currentLocation) {
			const urlComps = [currentLocation, stackItem].map(x => {
				const result = selectKeysOf(x, ['pathname', 'hash', 'search']);
				VmUtils.removeEmptyKvpFromObject(result);
				return result;
			});
			if (shallowEqual(urlComps[0], urlComps[1])) {
				return;
			}
		}
		if (!this.isListeningToStateChanges) {
			this.listenToBrowserLocationStateChanges(true);
		}
		let historyStack = [...this.mHistoryStack];
		if (currentLocation) {
			// look to see if there are any popped locations after the current... if so, purge.
			const index = historyStack.indexOf(currentLocation);
			if (index >= 1) {
				// this.mHistoryStack.slice(index + 1).forEach(x => x.popped = true);
				historyStack = historyStack.slice(0, index + 1);
			}
		}
		historyStack.push(stackItem);
		this.mHistoryStack = historyStack;
		// need to add window.location.href or IE11 will add "undefined" to url
		// don't pass stackItem.location.state to history because it might not be serializable
		(window as any).history.pushState(excludeKeysOf(stackItem, ['state']), '', (window as any).location.href);
		this.mAction = 'PUSH';
	};

	@action
	public replace = (pathOrLocation: h.Path | h.LocationDescriptorObject<any>, state?: any) => {
		if (!this.isListeningToStateChanges) {
			this.listenToBrowserLocationStateChanges(true);
		}
		const historyStack = [...this.mHistoryStack];
		const stackItem: IBrowserLocationStateHistoryStackItem = this.mPathOrLocationToStackItem(pathOrLocation, state);
		const currentLocation = this.location as IBrowserLocationStateHistoryStackItem;
		if (currentLocation) {
			// look to see if there are any popped locations after the current... if so, purge.
			historyStack[historyStack.indexOf(currentLocation)] = stackItem;
		} else {
			historyStack.push(stackItem);
		}
		this.mHistoryStack = historyStack;
		// need to add window.location.href or IE11 will add "undefined" to url
		// don't pass stackItem.location.state to history because it might not be serializable
		(window as any).history.replaceState(excludeKeysOf(stackItem, ['state']), '', (window as any).location.href);
		this.mAction = 'REPLACE';
	};

	/** Not supported */
	public block = () => {
		return VmUtils.Noop;
	};

	private mPathOrLocationToStackItem = (pathOrLocation: h.Path | h.LocationDescriptorObject<any>, state?: any) => {
		const loc: h.LocationDescriptorObject<any> =
			typeof pathOrLocation === 'string' ? { pathname: pathOrLocation, state } : pathOrLocation;
		const stackItem: IBrowserLocationStateHistoryStackItem = {
			...loc,
			key: uuidgen(),
			popped: false,
		};
		return stackItem;
	};

	@action
	protected onPopState = (e: PopStateEvent) => {
		if (!!e.state && !!e.state.key && !!e.state.pathname) {
			const indexOfToLocation = this.mHistoryStack.findIndex(x => x.key === e.state.key);
			if (indexOfToLocation >= 0) {
				// mark all in front of toLocation as popped
				const itemsToPop = this.mHistoryStack.slice(indexOfToLocation + 1);
				this.mAction = !!itemsToPop && itemsToPop.length > 0 ? 'POP' : 'PUSH';
				itemsToPop.forEach(x => (x.popped = true));
				this.mHistoryStack = [...this.mHistoryStack];
				return;
			}
		}

		// went back to state not tracked by this instance... mark all as popped
		(this.mHistoryStack || []).forEach(x => (x.popped = true));
		this.mHistoryStack = [];
		this.mAction = 'POP';
	};
}

export const isIE11 = () => {
	if (typeof window != 'undefined') {
		const windowObj = window as any;
		const list =
			windowObj &&
			windowObj?.matchMedia &&
			windowObj?.matchMedia('all and (-ms-high-contrast: none), (-ms-high-contrast: active)');
		return !!list?.matches || false;
	}
	return false;
};

export const detectBrowser = () => {
	if (window) {
		if (isIE11()) {
			document.body.classList.add('IE11');
		}
	}
};
