export enum LogMessageLevel {
	Error = 1,
	Warn = 2,
	Info = 3,
	Debug = 4,
	Verbose = 5,
}

export type ILogContext = Record<string, any>;

export interface ILogMessageContextProvider {
	getLogMessageContext?(logLevel: LogMessageLevel): ILogContext;
}

export type LogMessageContextType =
	| ILogContext
	| ILogContext[]
	| ILogMessageContextProvider
	| ILogMessageContextProvider[];

export interface ILogMessage {
	context?: ILogContext;
	date: Date;
	function?: string;
	line?: number;
	logLevel: LogMessageLevel;
	message: string;
}

export interface ILogFormatter<TLog = any> {
	formatLog(log: TLog): string;
}

export interface ILogger<TLog = any> {
	flush(): Promise<any>;
	getLogFormatter(): ILogFormatter<TLog>;
	getName(): string;
	log(data: TLog): Promise<any>;
	willAddLogger?(): void;
	willRemoveLogger?(): void;
}

export interface ILoggingService<TLog = any> {
	addLogger(logger: ILogger<TLog>): void;
	removeLogger(logger: ILogger<TLog>): void;
}

export interface IMessageLoggingService extends ILoggingService<ILogMessage> {
	logDebug(message: string, context?: LogMessageContextType): void;
	logError(message: string, context?: LogMessageContextType): void;
	logInfo(message: string, context?: LogMessageContextType): void;
	logVerbose(message: string, context?: LogMessageContextType): void;
	logWarning(message: string, context?: LogMessageContextType): void;
}

export interface ILogEventContextProvider {
	getLogEventContext?(): ILogContext;
}

export type LogEventContextType = ILogContext | ILogContext[] | ILogEventContextProvider | ILogEventContextProvider[];

export interface IEventArgs {
	category: string;
	action: string;
	label?: string;
}

export interface ILogEvent extends IEventArgs {
	context?: ILogContext;
	date: Date;
}

export interface IEventLoggingService extends ILoggingService {
	logEvent(args: IEventArgs, context?: LogEventContextType): void;
}

export class LoggingService<TLog = any> {
	protected loggers: Set<ILogger<TLog>>;
	constructor() {
		this.loggers = new Set<ILogger<TLog>>();
	}

	public addLogger = (logger: ILogger<TLog>) => {
		if (!this.loggers.has(logger)) {
			if (logger.willAddLogger) {
				logger.willAddLogger();
			}

			this.loggers.add(logger);
		}
	};

	public removeLogger(logger: ILogger<TLog>): void {
		if (this.loggers.has(logger)) {
			if (logger.willRemoveLogger) {
				// @ts-ignore
				logger.willAddLogger();
			}

			logger.flush();
			this.loggers.delete(logger);
		}
	}
}

export class EventLoggingService extends LoggingService<ILogEvent> implements IEventLoggingService {
	public logEvent = (args: IEventArgs, context?: LogEventContextType) => {
		// @ts-ignore
		const computedContext = this.getComputedEventContext(context);
		const logEvent: ILogEvent = {
			...args,
			date: new Date(Date.now()),
		};

		if (computedContext) {
			logEvent.context = computedContext;
		}

		this.loggers.forEach(x => x.log(logEvent));
	};

	private getComputedEventContext = (context: LogEventContextType) => {
		// @ts-ignore
		let computedContext: ILogContext = null;
		const type = typeof context;
		if (type === 'object') {
			if (Array.isArray(context) && (context as any[]).length > 0) {
				const contextArray: any[] = context;
				contextArray.forEach(c => {
					const cComputedContext = this.getComputedEventContext(c);
					if (cComputedContext) {
						computedContext = {
							...computedContext,
							...cComputedContext,
						};
					}
				});
			} else if (
				Object.prototype.hasOwnProperty.call(context, 'getLogEventContext') &&
				typeof (context as ILogEventContextProvider).getLogEventContext === 'function'
			) {
				// @ts-ignore
				// @ts-ignore
				const providerContext = (context as ILogEventContextProvider).getLogEventContext();
				computedContext = {
					...computedContext,
					...(providerContext || {}),
				};
			} else {
				computedContext = context;
			}
		}

		return computedContext;
	};
}

export class MessageLoggingService extends LoggingService<ILogMessage> implements IMessageLoggingService {
	public logDebug = (message: string, context?: LogMessageContextType) => {
		this.logMessage(message, LogMessageLevel.Debug, context);
	};

	public logError = (message: string, context?: LogMessageContextType) => {
		this.logMessage(message, LogMessageLevel.Error, context);
	};

	public logInfo = (message: string, context?: LogMessageContextType) => {
		this.logMessage(message, LogMessageLevel.Info, context);
	};

	public logVerbose = (message: string, context?: LogMessageContextType) => {
		this.logMessage(message, LogMessageLevel.Verbose, context);
	};

	public logWarning = (message: string, context?: LogMessageContextType) => {
		this.logMessage(message, LogMessageLevel.Warn, context);
	};

	private logMessage = (message: string, logLevel: LogMessageLevel, context?: LogMessageContextType) => {
		const loggers = Array.from(this.loggers);
		// @ts-ignore
		let computedContext: ILogContext = null;
		if (context) {
			computedContext = this.getComputedMessageContext(context, logLevel);
		}
		const m: ILogMessage = {
			date: new Date(Date.now()),
			logLevel,
			message,
		};
		if (computedContext) {
			m.context = computedContext;
		}
		setTimeout(() => {
			loggers.forEach(x => x.log(m));
		}, 1);
	};

	private getComputedMessageContext = (context: LogMessageContextType, logLevel: LogMessageLevel) => {
		// @ts-ignore
		let computedContext: ILogContext = null;
		const type = typeof context;
		if (type === 'object') {
			if (Array.isArray(context) && (context as any[]).length > 0) {
				const contextArray: any[] = context;
				contextArray.forEach(c => {
					const cComputedContext = this.getComputedMessageContext(c, logLevel);
					if (cComputedContext) {
						computedContext = {
							...computedContext,
							...cComputedContext,
						};
					}
				});
			} else if (
				Object.prototype.hasOwnProperty.call(context, 'getLogMessageContext') &&
				typeof (context as ILogMessageContextProvider).getLogMessageContext === 'function'
			) {
				// @ts-ignore
				// @ts-ignore
				const providerContext = (context as ILogMessageContextProvider).getLogMessageContext(logLevel);
				computedContext = {
					...computedContext,
					...(providerContext || {}),
				};
			} else {
				computedContext = context;
			}
		}

		return computedContext;
	};
}

export class ConsoleLogMessageFormatter implements ILogFormatter<ILogMessage> {
	public formatLog = (logMessage: ILogMessage) => {
		let stringMessage = `[message: ${logMessage.message}]`;
		if (logMessage.context) {
			Object.keys(logMessage.context).forEach(x => {
				// @ts-ignore
				const value = logMessage.context[x];
				const type = typeof value;
				let stringValue: string;
				if (type === 'string') {
					stringValue = value;
				} else if (type === 'object') {
					stringValue = JSON.stringify(value);
				}
				// @ts-ignore
				if (stringValue) {
					stringMessage = `${stringMessage}${`[${x}: ${stringValue}]`}`;
				}
			});
		}

		return stringMessage;
	};
}

export class ConsoleMessageLogger implements ILogger<ILogMessage> {
	private formatter: ILogFormatter<ILogMessage>;
	constructor(logMessageFormatter?: ILogFormatter<ILogMessage>) {
		this.formatter = logMessageFormatter || new ConsoleLogMessageFormatter();
	}

	public flush = () => {
		// @ts-ignore
		return Promise.resolve<void>(null);
	};

	public getLogFormatter = () => {
		return this.formatter;
	};

	public getName = () => {
		return 'Console Logger';
	};

	public log = (message: ILogMessage) => {
		if (message.logLevel > LogMessageLevel.Debug) {
			// @ts-ignore
			return Promise.resolve<void>(null);
		}

		return new Promise<void>(resolve => {
			const text = this.formatter.formatLog(message);
			console.log(text);
			resolve();
		});
	};
}

export class ConsoleLogEventFormatter implements ILogFormatter<ILogEvent> {
	public formatLog = (logMessage: ILogEvent) => {
		let stringMessage = `[category: ${logMessage.category}][action: ${logMessage.action}]`;
		if (logMessage.label) {
			stringMessage = `${stringMessage}[label: ${logMessage.label}]`;
		}
		if (logMessage.context) {
			Object.keys(logMessage.context).forEach(x => {
				// @ts-ignore
				const value = logMessage.context[x];
				const type = typeof value;
				let stringValue: string;
				if (type === 'string') {
					stringValue = value;
				} else if (type === 'object') {
					stringValue = JSON.stringify(value);
				}
				// @ts-ignore
				if (stringValue) {
					stringMessage = `${stringMessage}${`[${x}: ${stringValue}]`}`;
				}
			});
		}

		return stringMessage;
	};
}

export class ConsoleEventLogger implements ILogger<ILogEvent> {
	private formatter: ILogFormatter<ILogEvent>;
	constructor() {
		this.formatter = new ConsoleLogEventFormatter();
	}

	public flush = () => {
		// @ts-ignore
		return Promise.resolve<void>(null);
	};

	public getLogFormatter = () => {
		return this.formatter;
	};

	public getName = () => {
		return 'Console Logger';
	};

	public log = (message: ILogEvent) => {
		return new Promise<void>(resolve => {
			const text = this.formatter.formatLog(message);
			console.log(text);
			resolve();
		});
	};
}

export const Logger = new MessageLoggingService();

export const EventLogger = new EventLoggingService();
