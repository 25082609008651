import { CSSProperties, StyleSheet } from 'aphrodite';
import { navigation } from '../../styles/colors';
import { CssSelectors, baseStyles, truncateTextStyle } from '../../styles/styles';

export const styleSheet = StyleSheet.create({
	searchValue: {
		color: navigation,
		marginLeft: 4,
		...truncateTextStyle,
	},
	selectBox: {
		fontSize: 14,
		minWidth: 150,

		...CssSelectors.allDescendants(
			{
				width: '100%',
			},
			'.select-box-menu'
		),
		...CssSelectors.allDescendants(
			{
				maxHeight: 300,
				overflowY: 'auto',
			},
			'.select-box-menu-content'
		),
		':nth-child(1n) > .dropdown-content ': {
			boxSizing: 'border-box',
			width: '100%',
		},
	},
	selectBoxContent: {
		minWidth: 150,
	},
	selectBoxDisabled: {
		pointerEvents: 'none',
	},
	selectBoxFilterInput: {
		background: 'rgb(249, 249, 249)',
		border: '1px solid rgb(204, 204, 204)',
		borderRadius: 3,
		boxSizing: 'border-box',
		color: 'rgb(13, 15, 17)',
		fontSize: 12,
		outline: 'none',
		padding: 10,
		width: '100%',
	},
	selectBoxFilterWrap: {
		background: '#FFFFFF',
	},
	selectBoxMenu: {
		boxSizing: 'border-box',
		fontSize: 18,
		marginTop: -1,
		width: '100%',
	},
	selectBoxMenuContent: {
		background: '#FFFFFF',
		border: '1px solid #E3E7E9',
		boxShadow: '0 2px 4px 0 rgba(0,0,0,0.10)',
		padding: 0,
	},
	selectBoxMenuContentOption: {
		':focus': {
			backgroundColor: '#e3e7e9',
		},
		':hover': {
			backgroundColor: '#e3e7e9',
		},
		display: 'block',
		padding: '16px 20px',
		width: '100%',
	},
	selectBoxTrigger: {
		...(baseStyles.textField as CSSProperties),
		fontSize: 18,
		height: 44,
		width: '100%',
		...CssSelectors.allChildren(
			{
				...(truncateTextStyle as CSSProperties),
			},
			'.select-box-trigger-content'
		),
	},
	selectBoxtriggerButton: {
		':focus': {
			background: 'rgba(0, 82, 140, 0.09)',
		},
		':hover': {
			background: 'rgba(0, 82, 140, 0.09)',
		},
		alignItems: 'center',
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'space-between',
		padding: 10,
		position: 'relative',
		width: '100%',
	},
	selectBoxtriggerButtonDisabled: {
		pointerEvents: 'none',
		background: '#F9F9F9',
	},
	selectBoxtriggerCaret: {
		flexShrink: 0,
		marginLeft: 10,
		transform: 'rotateZ(90deg)',
	},
	selectBoxtriggerRightAccessory: {
		alignItems: 'center',
		display: 'flex',
	},
});
